import { ScheduleRange, ScheduleViewType } from 'App/pages/Schedules';
import { mobileCheck } from 'hooks/MobileCheck';
import { createStore } from 'hooks/BaseStore';
import { Assoc } from 'utils/Assoc';
import { PortalElement } from 'App/components/Portal';

// ---- REDUX ON TURHA ----

export type Logged = typeof logged;
export type FieldType = Exclude<Logged['types'], void>[string][string];

export const logged = {
	user: undefined as
		| void
		| (Assoc<any> & {
			id: string;
			super?: boolean;
			preferences?: {
				'schedule.show24h'?: boolean;
				'schedule.showReserved'?: boolean;
				'schedule.range'?: ScheduleRange;
				'schedule.view'?: ScheduleViewType;
			};
		}),

	privileges: undefined as void | Assoc<any>,
	types: undefined as void | Assoc<
		Assoc<{
			type: string;
			default?: unknown;
			join?: string;
			enum?: string[];
			flags: {
				type?: 'textarea';
				readonly?: boolean;
				allow_any?: boolean;
				super?: boolean;
				cascade?: boolean;
			};
		}>
	>,
	links: undefined as void | Assoc<any>,
	settings: undefined as void | {
		office?: Assoc<any>;
	},
	super: undefined as void | {
		company?: Assoc<any>;
		user?: Assoc<any>;
	},
};

export type StoreState = typeof storeState;

const storeState = {
	translations: {} as {
		count?: number;
		data?: Assoc<any>[];
	},
	logged,
	// cacheKeys: {} as { [path: string]: number; },
	sidebar: false as boolean,
	mobile: mobileCheck() as boolean,
	// portals: [] as (() => ReactElement | null)[],
	portals: [] as PortalElement[],

	// portals: [] as ReactElement[],
	// portals: new Set() as Set<ReactNode>,
};

const store = createStore('globalStore', storeState);
export const { useStore, getStore, setStore } = store;
