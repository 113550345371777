import _ from 'lodash';
import { useStore } from 'store';
import { Assoc } from 'utils/Assoc';
import { Col, Form, notification, Row } from 'App/MainComponents';
import { Lang, lang } from 'utils/Language';
import { SystemIcon } from 'App/components/SystemIcon';
import { Post, Put, PutResponse } from 'utils/Api';
import { Ikoni } from 'icons';
import { SqlDate, ToCurrency } from 'hooks/Dates';
import { confirmDelete } from 'utils/ApiFunctions';
import { FormItem } from './FormItem';
import { printContract } from './printContract';
import { hasPrivilege } from 'utils/UserUtils';
import { styled } from 'styled';
import { useMount, useStated } from 'hooks/Hooks';
import { Tip } from 'App/popup/Popup';
import { useCallback } from 'react';
import { Divider } from 'App/modern/Divider';
import { AutoFill } from 'App/components/AutoFill';
import { Loading, Spinning } from 'App/modern/Loading';
import { ModernButton } from 'App/modern/ModernButton';
import { ModalType } from 'App/modals/ModalPopup';
import dayjs from 'dayjs';
import { colors } from 'App/modern/Colors';
import { errorNotification } from 'utils/ErrorNotification';
import { showNotify } from './showNotify';

const BottomDiv = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	h4 {
		margin: 0;
	}

	button {
		margin-bottom: 0;
	}
`;

const TitleH4 = styled.h4`
	padding: 0 8px 4px 8px;
`;

type Props = {
	modal: ModalType;
};

export const ScheduleModal = (props: Props) =>
{
	// console.log('ScheduleModal rendered');

	const [state, set] = useStated({
		loading: true as boolean,
		modified: {} as Assoc<any>,
		priceData: undefined as undefined | any,
		// drag: false as boolean,
	});

	const [logged] = useStore(s => [s?.logged]);
	const { settings, user } = logged || {};
	const { loading, priceData, modified } = state;
	const { modal } = props;
	const { table, onOk, onUnsaved } = modal || {};

	const [form] = Form.useForm();
	const formData = { ...modal?.data, ...form?.getFieldsValue() };
	let controller = new AbortController();

	const setValues = (values: Assoc<any>) =>
	{
		form?.setFieldsValue({ ...form?.getFieldsValue(), ...values });
	};

	const validate = async () =>
	{
		try
		{
			return await form.validateFields();
		}
		catch (e)
		{
			console.error(e);
			const { errorFields } = (e || {}) as { errorFields: { name: string; errors: string[]; }[]; };

			showNotify({
				title: 'Tarkista tiedot',
				icon: (
					<Ikoni
						name='exclamation-triangle'
						fontColor={colors.orange.normal}
						style={{ fontSize: '2rem', marginRight: '1.25rem' }}
					/>
				),
				message: _.map(errorFields, (v, k) => <div key={k}>{v?.errors}</div>),
			});
		}
		return false;
	};

	const save = async (data: Assoc<any>, options = { print: false }): Promise<PutResponse | void> =>
	{
		if (!modal) return;
		if (!(await validate())) return;
		set({ loading: true });

		try
		{
			if (data?.start || data?.ending)
			{
				data.start = SqlDate(data?.start);
				data.ending = SqlDate(data?.ending);
			}

			const res = await Put({ path: modal.table, body: data });
			showNotify({
				title: <>{lang('saved')}</>,
				message: <>{_.upperFirst(`${ lang(modal.table) } ${ lang('saved') }`.toLowerCase())}</>,
				icon: (
					<Ikoni
						name='check-circle'
						fontColor={colors.green.normal}
						style={{ fontSize: '2rem', marginRight: '1.25rem' }}
					/>
				),
			});

			if (res?.id)
			{
				if (options?.print) printContract(res?.id);
				onOk?.();
			}

			return res;
		}
		catch (e)
		{
			errorNotification(e);
		}
		finally
		{
			return void set({ loading: false });
		}
	};

	const getPrice = async (): Promise<void> =>
	{
		try
		{
			set({ loading: true });
			controller?.abort();
			controller = new AbortController();

			const values = form?.getFieldsValue();
			if (!values?.resource) return;

			type PriceResponse = undefined | { fields: unknown; order_lines: unknown; price: number; total: number; };
			const price = await Post<PriceResponse>({ path: 'get_price_v2', body: values, extra: { signal: controller.signal } });
			setValues({ calcprice: price?.total });
			set({ priceData: price });
		}
		catch (e)
		{
			console.error(e);
		}
		finally
		{
			return void set({ loading: false });
		}
	};

	useMount(async () =>
	{
		if (!form) return;
		const data = modal?.data;
		if (Object.keys(data || {})?.length === 0) return;
		set({ loading: true });

		// If resource is not set, select first by default
		if (!data?.resource?.id)
		{
			const resources = await Post({
				path: 'resource',
				body: {
					active: 'active',
					parents: 'null',
					...(settings?.office?.id && { office: settings?.office?.id }),
				},
				header: {
					limit: 1,
					sort: 'name',
					dir: 'asc',
				},
			});

			if (resources?.data?.length)
			{
				data.resource = resources?.data?.[0];
			}
		}

		if (data?.customer?.id && !data?.customer?.name)
		{
			const customer = (
				await Post({
					path: 'customer',
					body: { id: data?.customer?.id },
					header: { limit: 1 },
				})
			)?.data?.[0];

			if (customer) data.customer = { ...data?.customer, ...customer };
		}

		if (!data?.office) data.office = data?.resource?.office || settings?.office || data?.office;
		if (!data?.company) data.company = data?.office?.company || data?.resource?.company || data?.company;
		if (!data?.customer?.company) data.customer = { ...data?.customer, company: data?.company };
		if (!data?.customer?.office) data.customer = { ...data?.customer, office: data?.office };
		if (!data?.start) data.start = new Date();
		if (!data?.ending) data.ending = new Date();
		setValues(data);

		await getPrice();

		(() =>
		{
			const { priceData } = state;
			const values = form?.getFieldsValue();

			if (data?.resource?.id !== formData?.resource?.id) return;
			if (!values?.resource?.pricefields?.length) return console.log('no pricefields');
			if (!priceData?.fields?.length) return console.log('no pricedata fields');

			const timeRange = _.find(values?.resource?.pricefields, pricefield => _.find(pricefield?.conditions, { cond: 'time_range' }));
			if (!timeRange) return console.log('no timerange');

			const setTime = {
				start: new Date((priceData?.fields || [])?.at?.(0)?.start),
				ending: new Date((priceData?.fields || [])?.at?.(-1)?.ending),
			};

			console.log('timeRange found, set time', setTime);
			setValues(setTime);
		})();

		set({ loading: false });
		return () => controller?.abort?.();
	}, [modal?.data]);

	const onValuesChange = useCallback(
		_.debounce(async () =>
		{
			set({ modified });
			onUnsaved?.();
			await getPrice();
		}, 100),
		[],
	);

	if (!table) return null;
	const types = logged?.types?.[table];

	const saveDisabled =
		(formData?.status === 'ready' ? true : undefined) ?? (!formData?.customer?.name?.length ? true : undefined) ?? false;

	return (
		<Form
			form={form}
			labelCol={{ span: 5 }}
			layout='horizontal'
			spellCheck={false}
			// autoComplete="off"
			// autoCorrect="off"
			// autoCapitalize="off"
			// autoSave="false"

			onValuesChange={(changed, allValues) =>
			{
				Object.assign(modified, { ...modified, ...changed });
				onValuesChange();
			}}
		>
			<div className='ant-tabs ant-tabs-top'>
				<div
					role='tablist'
					className='ant-tabs-nav'
				>
					<div className='ant-tabs-extra-content'>
						<span
							style={{
								fontSize: '16px',
								fontWeight: 500,
								marginRight: '30px',
								// cursor: 'move',
							}}
						// onPointerOver={() => { set({ drag: true }); }}
						// onPointerLeave={() => { set({ drag: false }); }}
						>
							<SystemIcon
								name={table}
								style={{ color: colors.blue.normal }}
							/>
							{'\u2000'}
							<Lang>{table}</Lang>
						</span>
					</div>
					<div className='ant-tabs-nav-wrap'>
						<div
							className='ant-tabs-nav-list'
							style={{ transform: 'translate(0px, 0px)' }}
						>
							<div className='ant-tabs-tab ant-tabs-tab-active'>
								<div
									role='tab'
									aria-selected='true'
									className='ant-tabs-tab-btn'
									tabIndex={0}
									id='rc-tabs-2-tab-1'
									aria-controls='rc-tabs-2-panel-1'
								>
									<Ikoni
										name='pen-line'
										twoTone
										style={{ fontSize: '12px' }}
									/>
									{'\u2000'}
									Tiedot
								</div>
							</div>
							<div
								className='ant-tabs-ink-bar ant-tabs-ink-bar-animated'
								style={{ left: 0, width: 61 }}
							/>
						</div>
					</div>
				</div>

				<Row style={{ marginTop: '8px' }}>
					<Col
						style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
						sm={24}
						lg={12}
					>
						<div
							className='ant-row ant-form-item'
							style={{ display: 'flex', marginBottom: 12, marginTop: 12, width: '100%', order: -100 }}
						>
							<TitleH4>
								<Lang>{table}</Lang>
							</TitleH4>
						</div>
						{_.map(types, (fieldType, fieldName: string) =>
						{
							if (fieldType?.flags?.cascade && fieldName !== 'order_lines') return null;

							return (
								<FormItem
									key={fieldName}
									table={table}
									fieldType={fieldType}
									name={fieldName}
									path={fieldName}
									data={formData}
									form={form}
								/>
							);
						})}
					</Col>
					{_.map(types, (v, k) =>
					{
						if (!v?.flags?.cascade || k === 'order_lines') return null;

						return (
							<Col
								key={k}
								style={{ display: 'flex', flexDirection: 'column', padding: 0, overflow: 'hidden' }}
								sm={24}
								lg={12}
							>
								<div
									className='ant-row ant-form-item'
									style={{ display: 'flex', marginBottom: 12, marginTop: 12, width: '100%', order: -100 }}
								>
									<TitleH4>
										<Lang>{k}</Lang>
									</TitleH4>
								</div>
								{_.map(logged?.types?.[v?.join!], (v2, k2) =>
								{
									return (
										<FormItem
											key={k2}
											table={v?.join!}
											fieldType={v2}
											name={k2}
											path={[k, k2]}
											data={formData?.[k]}
											form={form}
										>
											{k2 === 'name' && (
												<AutoFill
													key={`${ v?.join }.${ k2 }`}
													table={v?.join!}
													// value={formData?.[k]?.[k2]}
													selected={formData?.[k]}
													minLength={3}
													placeholder={lang('search or create customer')}
													noEmptyContent
													onType={e =>
													{
														const changed = {
															[k]: {
																...formData[k],
																id: undefined,
																name: e,
															},
														};
														
														Object.assign(modified, { ...modified, ...changed });
														setValues(changed);
														onValuesChange();

														// form.validateFields();
														// console.log("onType:", e, form.getFieldsValue());
													}}
													onChange={e =>
													{
														// console.log("onChange:", e);
														// if (e === undefined) return setValues({ [k]: undefined });
														// setValues({ [k]: { ...formData[k], ...(e ?? undefined) } });

														const changed =
															e === undefined
																? {
																	[k]: undefined,
																}
																: {
																	[k]: {
																		...formData[k],
																		...(e ?? undefined),
																	},
																};

														Object.assign(modified, { ...modified, ...changed });
														setValues(changed);
														onValuesChange();
													}}
												/>
											)}
										</FormItem>
									);
								})}
							</Col>
						);
					})}
				</Row>

				<Divider style={{ marginBottom: '16px', marginTop: '10px' }} />

				<BottomDiv>
					{loading && <Spinning />}

					<div style={{ flex: 1 }}>
						{Boolean(formData?.id && hasPrivilege(table, 'delete')) && (
							<ModernButton
								color='red'
								onClick={async () =>
								{
									if (await confirmDelete(table, formData, true))
									{
										onOk?.();
									}
								}}
							>
								<Ikoni
									name='trash'
									twoTone
									bgOpacity={0.3}
								/>
								{'\u2000'}
								<Lang>poista</Lang>
							</ModernButton>
						)}
					</div>
					<div style={{ margin: '0 15px' }}>
						<Tip
							color='#fff'
							style={{ padding: '6px 8px', color: '#555' }}
							visible={priceData?.fields && priceData?.total > 0 ? undefined : false}
							content={
								<div style={{ padding: '6px', color: '#555' }}>
									{_.map(priceData?.fields, (v, k) => (
										<div
											key={k}
											style={{ display: 'flex', flexWrap: 'nowrap' }}
										>
											<div style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
												{
													// new Date(+v?.[0] - (+v?.[1] * 60 * 1000))
													// .toLocaleString('fi', { day: '2-digit', month: '2-digit', year: '2-digit' })
													dayjs(v?.start).format('DD.MM.')
												}
											</div>
											<div
												style={{
													flex: 1,
													paddingRight: '12px',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
												}}
											>
												{v?.name || dayjs(v?.ending).format('DD.MM.')}
											</div>
											<div style={{ display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap', fontWeight: 500 }}>
												{ToCurrency(v?.price)}
											</div>
										</div>
									))}
									{priceData?.fields?.length > 0 && <Divider />}
									{_.map(priceData?.order_lines, (v, k) => (
										<div
											key={k}
											style={{ display: 'flex', flexWrap: 'nowrap' }}
										>
											<div
												style={{
													flex: 1,
													paddingRight: '12px',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
												}}
											>
												{_.trim(v?.resource?.name || '')}
											</div>
											<div style={{ display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap' }}>
												{v?.amount > 1 && (
													<>
														<span> {v?.amount} </span>
														<span style={{ padding: '0 6px' }}> x </span>
													</>
												)}
												<span style={{ fontWeight: 500 }}> {ToCurrency(v?.resource?.base_price)} </span>
											</div>
										</div>
									))}
									{Boolean(priceData?.order_lines?.length > 0) && <Divider />}
									<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
										<div style={{ flex: 1, paddingRight: '12px', whiteSpace: 'nowrap' }}>Yhteensä:</div>
										<div style={{ display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap', fontWeight: 500 }}>
											{ToCurrency(priceData?.total)}
										</div>
									</div>
									{formData?.payment_amount > 0 && (
										<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
											<div style={{ flex: 1, paddingRight: '12px', whiteSpace: 'nowrap' }}>
												{(['waiting', 'failed'].includes(formData?.payment_status) && <>Maksu kesken: </>) ||
													(formData?.payment_status === 'paid' && <>Maksettu: </>)}
											</div>
											<div style={{ display: 'flex', alignItems: 'flex-end', whiteSpace: 'nowrap' }}>
												{ToCurrency(formData?.payment_amount)}
											</div>
										</div>
									)}
								</div>
							}
						>
							<h4 style={{ position: 'relative', padding: '6px' }}>
								{(priceData?.total !== undefined && (
									<>
										<div>
											<Ikoni
												name='circle-check'
												twoTone
												fontColor={colors.green.normal}
												bgOpacity={0.25}
												fontSize={20}
											/>
											{'\u2000'}
											<>Yhteensä: </>
											{(formData?.customprice && formData?.customprice !== priceData?.total && (
												<>
													<del style={{ color: '#888' }}>{ToCurrency(priceData?.total, true)}</del>
													<> {ToCurrency(formData?.customprice, true)} </>
												</>
											)) || <> {ToCurrency(priceData?.total, true)} </>}
											<> € </>
										</div>
										{formData?.payment_amount > 0 && (
											<div style={{ marginTop: '4px' }}>
												{
													// (
													//     formValues?.payment_status === 'failed' &&
													//     <>
													//         Maksu peruuntunut: {ToCurrency(formValues?.payment_amount, true)}
													//     </>
													// ) ||
													(['waiting', 'failed'].includes(formData?.payment_status) && (
														<>
															<Loading />
															{'\u2000'}
															<>Maksu kesken: </>
															<span style={{ filter: 'contrast(0.8)' }}>{ToCurrency(formData?.payment_amount)}</span>
														</>
													)) ||
													(formData?.payment_status === 'paid' && (
														<span style={{ color: colors.green.normal }}>
															<Ikoni
																name='euro-sign'
																fontColor={colors.green.normal}
																bgOpacity={0.25}
																fontSize={20}
															/>
															{'\u2000'}
															<>Maksettu: </>
															<span style={{ filter: 'contrast(0.8)' }}>{ToCurrency(formData?.payment_amount)}</span>
														</span>
													))
												}
											</div>
										)}
									</>
								)) ||
									(priceData?.error !== undefined && (
										<>
											<Ikoni
												name='triangle-exclamation'
												twoTone
												fontColor={colors.orange.normal}
											/>
											{'\u2000'}
											<> {_.map(priceData?.error, v => `${ lang(v) } `)}</>
										</>
									))}
							</h4>
						</Tip>
					</div>

					<div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
						{(formData?.status === 'new' && (
							<ModernButton
								disabled={saveDisabled}
								onClick={async () =>
								{
									save({
										...form.getFieldsValue(),
										status: 'progress',
									});
								}}
							>
								<Ikoni
									name='calendar-clock'
									twoTone
								/>
								{'\u2000'}
								<Lang>varaus alkanut</Lang>
							</ModernButton>
						)) ||
							(formData?.status === 'progress' && (
								<ModernButton
									disabled={saveDisabled}
									onClick={async () =>
									{
										save({
											...form.getFieldsValue(),
											status: 'ready',
										});
									}}
								>
									<Ikoni
										name='calendar-check'
										twoTone
									/>
									{'\u2000'}
									<Lang>varaus päättynyt</Lang>
								</ModernButton>
							))}

						{Boolean(formData?.resource?.contract || formData?.office?.contract || user?.company?.contract) && (
							<Tip
								// visible={true}
								visible={formData?.status !== 'new' ? false : undefined}
								content={<Lang>tulosta sopimus ja merkitse varaus alkaneeksi</Lang>}
							>
								<ModernButton
									disabled={(!formData?.id || formData?.status === 'new') && saveDisabled}
									onClick={async () =>
									{
										if (!formData?.id || formData?.status === 'new')
										{
											return await save(
												{
													...form.getFieldsValue(),
													status: 'progress',
												},
												{print:true},
											);
										}

										printContract(formData?.id);
										return void onOk?.();
									}}
								>
									<Ikoni
										name='print'
										twoTone
									/>
									{'\u2000'}
									{((!formData?.id || formData?.status === 'new') && <Lang>print contract</Lang>) || (
										<Lang>print contract again</Lang>
									)}
								</ModernButton>
							</Tip>
						)}

						<Tip
							visible={formData?.status !== 'ready' ? false : undefined}
							content={<Lang>päättynyttä varausta ei voi muokata</Lang>}
						>
							<ModernButton
								color='blue'
								disabled={saveDisabled}
								onClick={() =>
								{
									save(form.getFieldsValue());
								}}
							>
								<Ikoni
									name='check'
									twoTone
								/>
								{'\u2000'}
								<Lang>save</Lang>
							</ModernButton>
						</Tip>
					</div>
				</BottomDiv>
			</div>
		</Form>
	);
};
