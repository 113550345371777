import _ from 'lodash';
import dayjs from 'dayjs';
import { Ikoni } from 'icons';
import { Badge } from 'App/components/Badge';
import { OitisCardHeader } from 'App/components/OitisCardHeader';
import { DatePicker, Radio } from 'App/MainComponents';
import { Tag } from 'App/modern/Tag';
import { openModal } from 'App/modals/ModalPopup';
import { ScheduleRange, SchedulesState } from 'App/pages/Schedules';
import { getStore, setStore, useStore } from 'store';
import { Assoc } from 'utils/Assoc';
import { PostResponse, Put } from 'utils/Api';
import { lang, Lang } from 'utils/Language';
import { hasPrivilege } from 'utils/UserUtils';
import { ModernButton } from 'App/modern/ModernButton';
import { Popup, Tip } from 'App/popup/Popup';
import { DropdownMenu } from 'App/components/OitisCard';
import { styled } from 'styled';
import { colors } from 'App/modern/Colors';

const ButtonGroup = styled.div`
	&:not(:last-child) {
		margin: 0px 8px 4px 0px;
	}
`;

const setPreferences = async (preferences: Assoc<any>) =>
{
	try
	{
		const { logged } = getStore();
		if (!logged?.user) return;
		if (!hasPrivilege('user', 'update')) return;

		const cloned = _.cloneDeep(logged);
		if (!cloned?.user) return;

		cloned.user.preferences = {
			...(cloned?.user?.preferences || {}),
			...preferences,
		};

		if (_.isEqual(cloned?.user?.preferences, logged?.user?.preferences)) return;

		setStore({ logged: cloned });

		Put({
			path: 'user',
			body: {
				id: logged?.user?.id,
				preferences: cloned?.user?.preferences,
			},
		});
	} catch (e)
	{
		console.error(e);
	}
};

type Props = {
	schedules: PostResponse;
	resources: PostResponse;
	offices: PostResponse;

	setRange: (range: ScheduleRange, add?: number) => void;
	setState: (state: Partial<SchedulesState>) => void;
	state: SchedulesState;
};

export const SchedulesHeader = (props: Props) =>
{
	const table = 'schedule';
	const [logged, mobile] = useStore(s => [s?.logged, s?.mobile]);
	const { settings, types } = logged || {};
	const { schedules, offices, resources } = props;
	const { state, setState, setRange } = props;
	const { view, range, start, ending, showDeleted, show24h, showReserved, holidayInfo } = state;
	const rangeLength = +ending - +start;

	return (
		<OitisCardHeader>
			<div>
				<h2>
					<Lang>schedules</Lang>
					{'\u2000'}
					<Badge count={schedules?.count} />
				</h2>
			</div>

			<div style={{ flex: 1, textAlign: 'center' }}>
				<h2
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					{(rangeLength <= 1000 * 60 * 60 * 24 &&
						_.upperFirst(dayjs(start).format('dddd')) +
						' ' +
						new Date(+start).toLocaleString('fi', { day: 'numeric', month: 'numeric', year: 'numeric' })) ||
						(rangeLength <= 1000 * 60 * 60 * 24 * 7 &&
							`${ dayjs(start).format('DD.MM.YYYY') } - ${ dayjs(ending).format('DD.MM.YYYY') }`) ||
						(rangeLength > 1000 * 60 * 60 * 24 &&
							_.upperFirst(dayjs(start).format('MMMM')) + ' ' + new Date(+start).toLocaleString('fi', { year: 'numeric' }))}
					{Boolean(holidayInfo) && (
						<Tag
							color='orange'
							style={{ marginLeft: '10px' }}
						>
							{holidayInfo}
						</Tag>
					)}
				</h2>
			</div>

			<div style={{ flex: '0 auto', display: 'flex', flexWrap: 'wrap' }}>
				<DatePicker
					key={range}
					style={{ margin: '0 8px 6px 0', height: '100%' }}
					picker={range === 'week' || range === 'month' ? range : undefined}
					format={v => v?.toDate()?.toLocaleDateString('fi')}
					value={dayjs(start)}
					onChange={value =>
					{
						const date = value?.toDate();
						setState({ start: date });
						setRange(range, 0);
					}}
				/>

				{Boolean(view === 'timeline' && resources?.data?.length > 1) && (
					<ButtonGroup>
						<Tip content={!showReserved ? <>Näytetään kaikki</> : <>Näytetään vain varaukset</>}>
							<ModernButton
								color={showReserved ? 'blue' : undefined}
								onClick={() =>
								{
									setState({ showReserved: !showReserved });
									setPreferences({
										'schedule.showReserved': !showReserved,
									});
								}}
							>
								{showReserved ? (
									<Ikoni
										name='square-check'
										twoTone
										fontSize={'1.3em'}
									/>
								) : (
									<Ikoni
										name='square'
										fontSize={'1.3em'}
										fontWeight={100}
									/>
								)}
								{'\u2000'}
								<Lang>Piilota tyhjät</Lang>
							</ModernButton>
						</Tip>
					</ButtonGroup>
				)}

				{Boolean(settings?.office || (offices?.data?.length === 1 && offices?.data?.[0])) && (
					<ButtonGroup>
						<Tip content={!show24h ? <>Näytetään 24 tunnin aikajana</> : <>Näytetään vain aukioloajat</>}>
							<ModernButton
								color={!show24h ? 'blue' : undefined}
								onClick={() =>
								{
									setState({ show24h: !show24h });
									setRange(range, 0);

									setPreferences({
										'schedule.show24h': !show24h,
									});
								}}
							>
								{!show24h ? (
									<Ikoni
										name='square-check'
										twoTone
										fontSize={'1.3em'}
									/>
								) : (
									<Ikoni
										name='square'
										fontSize={'1.3em'}
										fontWeight={100}
									/>
								)}
								{'\u2000'}
								<Lang>24 h</Lang>
							</ModernButton>
						</Tip>
					</ButtonGroup>
				)}

				<ButtonGroup>
					<Radio.Group
						buttonStyle='solid'
						value={+dayjs(start).startOf(range) === +dayjs().startOf(range) ? 0 : null}
						onChange={async e =>
						{
							setRange(range, e.target.value || undefined);
						}}
					>
						<Radio.Button value={-1}>
							<Ikoni name='angle-left' />
						</Radio.Button>
						<Radio.Button value={0}>{`${ lang('this') } ${ lang(range, true) }`}</Radio.Button>
						<Radio.Button value={1}>
							<Ikoni name='angle-right' />
						</Radio.Button>
					</Radio.Group>
				</ButtonGroup>

				<ButtonGroup>
					<Radio.Group
						buttonStyle='solid'
						// value={{ view, range }}
						onChange={async e =>
						{
							const v = e.target.value;
							setRange(v?.range);

							setState({
								view: v?.view,
							});

							setPreferences({
								'schedule.range': v?.range,
								'schedule.view': v?.view,
							});
						}}
					>
						{!mobile && (
							<Radio.Button
								type='primary'
								value={{ view: 'timeline', range: 'day' }}
								className={view === 'timeline' && range === 'day' ? 'ant-radio-button-wrapper-checked' : ''}
							>
								Päivä
							</Radio.Button>
						)}
						{!mobile && (
							<Radio.Button
								type='primary'
								value={{ view: 'timeline', range: 'week' }}
								className={view === 'timeline' && range === 'week' ? 'ant-radio-button-wrapper-checked' : ''}
							>
								Viikko
							</Radio.Button>
						)}
						<Radio.Button
							type='primary'
							value={{ view: 'calendar', range: 'month' }}
							className={view === 'calendar' && range === 'month' ? 'ant-radio-button-wrapper-checked' : ''}
						>
							Kalenteri
						</Radio.Button>
						<Radio.Button
							type='primary'
							value={{ view: 'list', range: 'week' }}
							className={view === 'list' && range === 'week' ? 'ant-radio-button-wrapper-checked' : ''}
						>
							Lista
						</Radio.Button>
					</Radio.Group>
				</ButtonGroup>

				{hasPrivilege(table, 'create') && (
					<ButtonGroup>
						<ModernButton
							color='blue'
							onClick={() =>
							{
								openModal({
									table,
									data: async () => _.mapValues(types?.[table], v => v?.default || undefined),
								});
							}}
						>
							<Ikoni name='pen-line' />
							{'\u2000'}
							<Lang>create new</Lang>
						</ModernButton>
					</ButtonGroup>
				)}

				<Popup
					trigger={'click'}
					placement='bottomRight'
					content={
						<DropdownMenu>
							<button
								className={showDeleted ? 'active' : ''}
								style={{ ...(showDeleted && { background: colors.blue.normal, color: '#fff' }), flexWrap: 'nowrap' }}
								onClick={() => setState({ showDeleted: !showDeleted })}
							>
								{showDeleted ? (
									<Ikoni
										name='eye'
										fontSize={'1.3em'}
									/>
								) : (
									<Ikoni
										name='eye-slash'
										fontSize={'1.3em'}
									/>
								)}
								{'\u2000'}
								Näytä poistetut
							</button>
						</DropdownMenu>
					}
				>
					<ModernButton>
						<Ikoni name='wrench' />
					</ModernButton>
				</Popup>
			</div>
		</OitisCardHeader>
	);
}
