import _ from 'lodash';
import { Ikoni } from 'icons';
import { Lang } from 'utils/Language';
import { Post, PostOptions } from 'utils/Api';
import { Avatar } from 'App/components/OitisCard';
import { Picture } from 'App/components/Picture';
import { openModal } from 'App/modals/ModalPopup';
import { usePost } from 'hooks/usePost';
import { useStore } from 'store';
import { useMount, useStated } from 'hooks/Hooks';
import { Badge } from 'App/components/Badge';
import { styled } from 'styled';
import { Popup } from 'App/popup/Popup';
import { Virtual } from 'App/modern/Virtual';
import { Assoc } from 'utils/Assoc';

const Container = styled.div`
	background: #fff;
	color: #545454;
	overflow: hidden;
	width: 350px;
	/* max-height: calc(100vh - 100px); */

	& h3 {
		border-bottom: 1px solid #e8e8e8;
		padding: 14px;
		margin: 0;
		box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);

		& .anticon,
		& i {
			color: #888;
		}

		& span {
			margin-right: 8px;
		}
	}

	& li {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		padding: 16px 20px;
		cursor: pointer;
		color: #545454;
		transition: background 150ms cubic-bezier(0.645, 0.045, 0.355, 1);

		&:hover {
			background-color: #f5f5f5;
		}

		border-bottom: 1px solid #eee;
		&:last-of-type {
			border-bottom: 0;
		}

		& > div {
			line-height: 1.6;
			padding-left: 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			& * {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			& small {
				display: block;
			}
		}

		& > small {
			display: flex;
			flex-direction: column;
			flex: 0 auto;
			padding-left: 6px;
		}
	}
`;

const Notify = ({ item }: { item: any; }) =>
{
	const created = new Date(item?.created);
	const onClick = async () =>
	{
		openModal({
			table: 'schedule',
			data: async () =>
				(
					await Post({
						path: 'schedule',
						body: {
							id: item?.schedule?.id,
						},
					})
				)?.data?.[0],
		});
	};

	const title = String(item?.title || '') || String(item?.message || '').split('\n')?.[0];
	const message = String(item?.message || '')
		.split('\n')
		.map((line, k) => <small key={k}>{line}</small>);
	if (!item?.title) message.shift();

	return (
		<li
			// style={{ position: 'relative', top: state.scroll }}
			onClick={onClick}
		>
			<Avatar>
				<Picture
					src={item?.office?.picture || item?.company?.picture}
					table={'user'}
				/>
			</Avatar>
			<div style={{ flex: 1 }}>
				<div>{title}</div>
				{message}
			</div>
			<small>
				<div>
					<Ikoni name='clock' />
					{'\u2000'}
					{created.toLocaleString('fi', {
						hour: '2-digit',
						minute: '2-digit',
					})}
				</div>
				<div>
					{created.toLocaleString('fi', {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
					})}
				</div>
			</small>
		</li>
	);
};

export const Notifications = () =>
{
	const settings = useStore(s => s?.logged?.settings);
	const [state, set] = useStated({
		data: [] as Assoc<any>[],
		offset: 0 as number,
		scroll: 0 as number,
	});
	const { data, offset } = state;
	const limit = 10;

	const notifyConds: PostOptions = {
		path: 'notify',
		body: {
			active: 'active',
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			offset,
			limit,
			fields: {
				id: true,
				created: true,
				modified: true,
				message: true,
				office: { id: true, name: true, picture: true },
				schedule: { id: true, status: true, start: true, ending: true },
				status: true,
				title: true,
			},
		},
	};

	const [notifys, status] = usePost(notifyConds);

	useMount(() =>
	{
		if (status !== 'ready') return;
		if (!notifys?.data?.length) return;

		_.map(notifys?.data, v =>
		{
			if (!_.find(data, f => f?.id === v?.id)) data.push(v);
		});

		set({ data });
	}, [offset, status]);

	return (
		<Container>
			<h3>
				<Ikoni name='bell' />
				{'\u2000'}
				<Lang>notifications</Lang>
				<Badge
					color='orange'
					count={notifys?.count}
					loading={notifys?.count === undefined}
				/>
			</h3>

			{/* <List>
        <VirtualList
          data={notifys?.data}
          height={ContainerHeight}
          itemHeight={47}
          itemKey="email"
          onScroll={onScroll}
        >
          {item => (
            <List.Item key={item?.id}>
              <List.Item.Meta
                avatar={<Avatar>Testi</Avatar>}
                title={<a href="https://ant.design">{item?.name}</a>}
                description={item?.email}
              />
              <div>Content</div>
            </List.Item>
          )}
        </VirtualList>
      </List> */}

			<Virtual
				total={notifys?.count}
				status={status}
				onLoad={off =>
				{
					set({ offset: off });
					return true;
				}}
				items={_.map(data, (item, k) => (
					<Notify
						key={item?.id || k}
						item={item}
					/>
				))}
			/>
		</Container>
	);
}

export const NotificationButton = () =>
{
	const settings = useStore(s => s?.logged?.settings);

	const notifyConds = {
		path: 'notify',
		body: {
			active: 'active',
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			limit: 0, // Haetaan tässä vain määrä
		},
	};

	const [notifys] = usePost(notifyConds);

	return (
		<Popup
			trigger={'click'}
			placement='bottom'
			color='#fff'
			content={<Notifications />}
		>
			<button style={{ position: 'relative' }}>
				<Avatar>
					<Ikoni name='bell' />
				</Avatar>
				<Badge
					type='notify'
					count={notifys?.count}
					loading={notifys?.count === undefined}
				/>
			</button>
		</Popup>
	);
}
