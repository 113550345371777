import _ from 'lodash';
import { NavLink, NavLinkProps } from 'router';
import { setStore, useStore } from 'store';
import { Lang } from 'utils/Language';
import { Ikoni } from 'icons';
import { styled } from 'styled';
import { useMount, useStated } from 'hooks/Hooks';
import { colors } from 'App/modern/Colors';
import { OitisLogo } from 'App/OitisLogo';
import { SystemIcon } from 'App/components/SystemIcon';

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 225px;
	max-width: 100%;
	height: 100%;
	z-index: 100;

	word-wrap: break-word;
	flex-grow: 1;
	overflow: auto;

	background: #fff;
	background-clip: padding-box;
	border: 0;
	overflow: hidden auto;
	box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
	transition: transform 0.4s cubic-bezier(0.7, 0.3, 0.1, 1);
`;

const Mask = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 99;
	/* pointer-events: auto; */

	background-color: rgba(0, 0, 0, 0.45);
	transition: opacity 0.4s cubic-bezier(0.7, 0.3, 0.1, 1);
	/* animation: antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1); */

	/* height: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transition: opacity .3s linear,height 0s ease .3s;
	width: 100%; */
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
	flex-direction: row;
	flex-wrap: nowrap;
	height: 72px;
	padding: 10px 30px;
	position: relative;
	z-index: 1;
`;

const Body = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 15px 0 30px 0;

	> a {
		display: block;
		padding: 8px 30px;
		color: #585858;

		&.active {
			background: ${ colors.blue.normal }!important;
			color: #fff !important;
		}

		&:hover {
			background: #f5f5f5;
		}

		& > .anticon,
		& > i {
			margin-right: 8px;
		}
	}
`;

export const Sidebar = () =>
{
	const [logged, sidebar] = useStore(s => [s?.logged, s?.sidebar]);
	const [{ show }, set] = useStated({ show: sidebar });
	const { user, links } = logged || {};

	const Linkki = ({ children, ...rest }: NavLinkProps) => (
		<NavLink
			{...rest}
			onClick={async () => setStore({ sidebar: false })}
		>
			{children}
		</NavLink>
	);

	useMount(() =>
	{
		if (!show && sidebar) set({ show: true });
	}, [sidebar, show]);

	if (!sidebar && !show) return null;

	return (
		<>
			<Mask
				onClick={() => setStore({ sidebar: false })}
				onTransitionEnd={e =>
				{
					// console.log('onTransitionEnd', sidebar);
					if (!sidebar) set({ show: false });
				}}
				style={{
					...((!show || !sidebar) && { opacity: 0, pointerEvents: 'none' }),
				}}
			/>
			<Container
				style={{
					...((!show || !sidebar) && { transform: 'translateX(-150%)' }),
				}}
			>
				<Header>
					<Linkki
						to='/'
						exact
					>
						<OitisLogo style={{ height: '30px' }} />
					</Linkki>

					<Ikoni
						name='bars'
						style={{ marginLeft: 'auto' }}
						onClick={() => setStore({ sidebar: false })}
					/>
				</Header>
				<Body>
					<Linkki
						to={`/`}
						exact
					>
						<SystemIcon name={'home'} />
						{'\u2000'}
						<Lang>frontpage</Lang>
					</Linkki>
					{_.map(
						links || {},
						(v, k) =>
							v?.level !== 'super' && (
								<Linkki
									key={k}
									to={`/${ k }`}
								>
									<SystemIcon name={k} />
									{'\u2000'}
									<Lang>{v?.title}</Lang>
								</Linkki>
							),
					)}
					{user?.super === true && (
						<>
							<hr />
							{_.map(
								links || {},
								(v, k) =>
									v?.level === 'super' && (
										<Linkki
											key={k}
											to={`/${ k }`}
										>
											<SystemIcon name={k} />
											{'\u2000'}
											<Lang>{v?.title}</Lang>
										</Linkki>
									),
							)}
						</>
					)}
				</Body>
			</Container>
		</>
	);
}
