import { styled } from 'styled';

export const Wrap = styled.div`
	position: relative;
	width: 100%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 1600px) {
		.ant-page-header {
			border-radius: 0;
		}
	}
`;
