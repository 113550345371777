import { Ikoni } from 'icons';
import { HTMLAttributes } from 'react';
import { styled, keyframes } from 'styled';
import { colors } from './Colors';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

export const Loader = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	cursor: wait;
	background: #fffa;
	color: ${ colors.blue.normal };
	opacity: 0;
	animation: forwards ${ fadeIn } 300ms;

	& > svg {
		width: auto;
		height: 60%;
		max-height: 8vh;
		z-index: 10;
	}
`;

const animation = keyframes`
	to {
		transform: rotate(360deg);
	}
`;

const Icon = styled.svg`
	display: inline-flex;
	vertical-align: middle;
	color: inherit;
	fill: currentColor;
	pointer-events: none;
	animation: ${ animation } 1s infinite linear;
	/* animation: ${ animation } 0.01s infinite linear; */
	width: 1em;
	height: 1em;

	margin: 1px 4px;
	&:first-child {
		margin-left: 0px;
	}
	&:last-child {
		margin-right: 0px;
	}
`;

// export const Loading = (props: HTMLAttributes<SVGSVGElement>) => (
// 	<Icon {...props} viewBox="0 0 1024 1024">
// 		<path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
// 	</Icon>
// );

export const Loading = (props: HTMLAttributes<HTMLElement>) => (
	// <Icon {...props} viewBox="0 0 1024 1024">
	// 	<path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
	// </Icon>
	<Ikoni
		{...props}
		name='spinner-third'
		twoTone
		spin
	/>
);

export const Spinning = ({ active }: { active?: boolean; }) =>
{
	if (!active) return null;
	return (
		<Loader>
			<Loading />
		</Loader>
	);
};
