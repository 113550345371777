import _ from 'lodash';
import { Post, PostResponse } from 'utils/Api';
import { Lang } from 'utils/Language';
import { LocaleDate } from 'hooks/Dates';
import { hasPrivilege } from 'utils/UserUtils';
import { Col, Row, Skeleton } from 'App/MainComponents';
import { Timelane } from './Timelane';
import { openModal } from 'App/modals/ModalPopup';
import { Divider } from 'App/modern/Divider';
import { useStore } from 'store';
import { SchedulesCalendarOld } from 'App/pages/SchedulesCalendarOld';
import { Item } from 'App/pages/Item';
import { ScheduleViewType } from 'App/pages/Schedules';
import { EmptyContent } from 'App/components/EmptyIcon';
import { ModernButton } from 'App/modern/ModernButton';
// import TimelaneNew from "App/calendar/TimelaneNew";
// import SchedulesCalendar from "./SchedulesCalendar";

type Props = {
	schedules: PostResponse;
	resources: PostResponse;
	offices: PostResponse;
	view: ScheduleViewType;
	start: Date;
	ending: Date;
};

export const SchedulesViews = (props: Props) =>
{
	const table = 'schedule';
	const { types } = useStore(s => s?.logged) || {};
	const { schedules, offices, resources } = props;
	const { view, start, ending } = props;

	return (
		<>
			{(view === 'timeline' && (
				<Timelane
					offices={offices}
					resources={resources}
					schedules={schedules}
					start={start}
					ending={ending}
					onSelect={async schedule =>
					{
						if (schedule?.id)
						{
							return openModal({
								table,
								data: async () =>
									(
										await Post({
											path: table,
											body: {
												id: schedule?.id,
											},
											header: { limit: 1 },
										})
									)?.data?.[0],
							});
						}

						return openModal({
							table,
							data: async () => ({
								..._.mapValues(types?.[table], v => v?.default || undefined),
								..._.cloneDeep(schedule),
							}),
						});
					}}
				/>
			)) ||
				(view === 'calendar' && (
					<SchedulesCalendarOld
						table={table}
						start={start}
						ending={ending}
						schedules={schedules}
						onShowMore={date =>
						{
							// setState({
							// 	view: 'timeline',
							// 	start: dayjs(date).startOf('day').toDate(),
							// 	ending: dayjs(date).endOf('day').toDate(),
							// });
							// setRange('day', 0, offices);
						}}
					/>
				)) ||
				(view === 'list' && (
					<>
						{(schedules?.count === undefined && <Skeleton active />) ||
							(schedules?.count === 0 && (
								<EmptyContent>
									{hasPrivilege(table, 'create') && (
										<ModernButton
											color='blue'
											onClick={() =>
											{
												openModal({
													table,
													data: async () => _.mapValues(types?.[table], v => v?.default || undefined),
												});
											}}
										>
											<Lang>create new</Lang>
										</ModernButton>
									)}
								</EmptyContent>
							)) || (
								<Row>
									{_.map(schedules?.data, (v, k) => (
										<Col
											key={k}
											style={{ minWidth: '20%' }}
											xs={24}
											sm={12}
											md={12}
											lg={6}
											xl={4}
										>
											<Item
												table={table}
												data={{
													...v,
													description: (
														<>
															Alkaa: {LocaleDate(v?.start)}
															<br />
															Päättyy: {LocaleDate(v?.ending)}
														</>
													),
													name: (
														<>
															{v?.resource?.name}
															<Divider />
															<p>{v?.customer?.name}</p>
														</>
													),
													picture: v?.resource?.picture || v?.office?.picture,
												}}
												onClick={async () =>
												{
													openModal({
														table,
														data: async () =>
															(
																await Post({
																	path: table,
																	body: { id: v?.id },
																	header: { limit: 1 },
																})
															)?.data?.[0],
													});
												}}
											/>
										</Col>
									))}
								</Row>
							)}
					</>
				))}
		</>
	);
}
