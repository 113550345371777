import React, { ReactNode } from 'react';
import { styled } from 'styled';
import { Header } from './Header';
import { Navbar } from 'App/components/Navbar';
import { DesktopOnly } from 'App/modern/Visibility';
import { Wrap } from './Wrap';
import ContentBackground from 'assets/images/bg.png';
import { Ikoni } from 'icons';

const Layout = styled.div`
	position: relative;
	display: flex;
	flex: auto;
	flex-direction: column;
	overflow: hidden;

	min-height: 100%;
	user-select: none;

	background: linear-gradient(#f5f5f5, transparent 100%), url('${ ContentBackground }');

	background-size: cover;
	background-position: center;
`;

const Content = styled.div`
	flex: auto;
	min-height: 0;
`;

const Footer = styled.div`
	border-top: 1px solid #e8e8e8;
	background: #fffffe;
	color: #545454;
	flex: 0 0 auto;

	padding: 12px 24px;
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
`;

const OitisFooter = () => (
	<Footer>
		<Wrap style={{ display: 'flex', flexWrap: 'nowrap' }}>
			<div style={{ flex: 1 }}>
				<a
					href='https://oitis.fi/ohjeet'
					target='_blank'
					rel='noreferrer'
				>
					<Ikoni name='question-circle' />
					{'\u2000'}
					Ohjeet
				</a>
			</div>
			<div>
				Oitis.fi Hallinta | Runtime Oy © 2019 -<> {new Date().toLocaleDateString('fi', { year: 'numeric' })}</>
			</div>
		</Wrap>
	</Footer>
);

type Props = {
	children?: ReactNode;
};

export const Page = (props: Props) =>
{
	const { children } = props;

	return (
		<Layout>
			<Header />

			<DesktopOnly>
				<Navbar />
			</DesktopOnly>

			<Content>
				<Wrap>{children}</Wrap>
			</Content>

			<OitisFooter />
		</Layout>
	);
}
