import { Assoc } from 'utils/Assoc';

import _ from 'lodash';
import dayjs from 'dayjs';
import { styled } from 'styled';
import { Ikoni } from 'icons';
import { PostResponse, Put } from 'utils/Api';
import { lang } from 'utils/Language';
import { SqlDate } from 'hooks/Dates';
import { confirmDelete, confirmModal } from 'utils/ApiFunctions';
import { useStated } from 'hooks/Hooks';
import { Popup, Tip } from 'App/popup/Popup';
import { SchedulePopup } from 'App/calendar/SchedulePopup';
import { DropdownMenu } from 'App/components/OitisCard';
import { PointerEvent, RefObject } from 'react';
import { colors } from 'App/modern/Colors';
import { DeleteIconButton } from 'App/components/DeleteIconButton';
import { errorNotification } from 'utils/ErrorNotification';
import { showNotify } from 'App/modals/showNotify';

const stripeBackground = `repeating-linear-gradient(-45deg, #00000008 0px, #00000008 10px, transparent 10px, transparent 20px)`;

export const TimeblockDiv = styled.div`
	cursor: pointer;
	user-select: none;
	position: relative;
	height: 100%;
	width: 20px;
	line-height: normal;
	color: #555;
	display: flex;
	align-items: center;
	border: 1px solid #f0f3f4;
	box-shadow: 0px 3px 5px -1px #00000020;
	border-radius: 4px;
	display: flex;
	overflow: hidden;
	filter: opacity(0.9);

	background: #fff;
	/* background: orange repeating-linear-gradient(-45deg, #00000020 0px, #00000020 10px, transparent 10px, transparent 20px); */
`;

export const TimeBlockContent = styled.div`
	position: absolute;
	font-size: smaller;
	padding-left: 5px;
	display: flex;
	align-items: center;
	flex: 1;
	max-width: calc(100% - 5px);

	> .content {
		display: flex;
		flex-direction: column;
		white-space: nowrap;
		/* overflow: hidden; */

		> span {
			margin-right: 5px;
			min-width: 32px;
		}
	}
`;

const TimeBlockResizer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0px;
	font-size: 16px;
	width: 20px;
	height: 100%;
	border: 1px solid #fff;
	border-radius: 8px;
	background: #ddddddaa;
	/* margin: 0 4px; */
	color: #999;
	cursor: e-resize;
`;

const TimeBlockContainer = styled.div`
	position: absolute;
	height: 41px;

	&:hover {
		z-index: 1;
	}

	${ TimeBlockResizer } {
		transition: opacity 200ms ease-in-out;
		opacity: 0;
		pointer-events: none;
	}

	&:hover ${ TimeBlockResizer } {
		opacity: 1;
		pointer-events: initial;
	}
`;

type Props = {
	schedule: Assoc<any>;
	schedules: PostResponse;
	resources: PostResponse;
	rangeStart: Date;
	rangeEnd: Date;
	checker: RefObject<HTMLDivElement>;
	onSelect: (schedule: Assoc<any>) => Promise<boolean>;
};

export const Timeblock = (props: Props) =>
{
	const [state, set] = useStated({
		drag: undefined as void | Assoc<any>,
		pointerOver: false as boolean,
	});

	const { onSelect } = props;
	const { schedule, resources, rangeStart, rangeEnd, checker, schedules } = props;
	const { drag, pointerOver } = state;

	const resourceY = _.findIndex(resources?.data, f => f?.id === schedule?.resource?.id);
	if (resourceY < 0) return null;

	const disabled = schedule?.status === 'ready';
	const scheduleStart = new Date(schedule?.start);
	const scheduleEnding = new Date(schedule?.ending);
	const rect = checker.current?.getBoundingClientRect();
	const timeRange = +rangeEnd - +rangeStart;
	const minimum = 1000 * 60 * 15;

	const background =
		(schedule?.active === 'deleted' && '#777777') ||
		(schedule?.status === 'new' && colors.blue.normal) ||
		(schedule?.status === 'progress' && colors.green.normal) ||
		(schedule?.status === 'ready' && colors.green.normal) ||
		(schedule?.status === 'cancelled' && colors.orange.normal) ||
		(schedule?.active === 'inactive' && '#AAAAAA') ||
		colors.blue.normal;

	const overlapping = _.find(
		schedules?.data,
		other =>
			schedule?.id !== other?.id &&
			schedule?.resource?.id === other?.resource?.id &&
			+new Date(schedule?.start) < +new Date(other?.ending) &&
			+new Date(schedule?.ending) > +new Date(other?.start),
	);

	const onPointerDown = async (e: PointerEvent) =>
	{
		if (e.buttons !== 1) return;
		if (drag || disabled) return;

		e.preventDefault();
		e.stopPropagation();
		e.currentTarget.setPointerCapture(e.pointerId);

		set({
			drag: {
				schedule,
				prev: _.cloneDeep(schedule),
				x: e.clientX,
				y: e.clientY,
			},
		});
	};

	const onPointerUp = async (e: PointerEvent) =>
	{
		if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
		e.currentTarget.releasePointerCapture(e.pointerId);
		// if (!drag) return;

		if (
			!drag ||
			(Math.floor(Math.abs(+new Date(drag?.schedule?.start) - +new Date(drag?.prev?.start)) / minimum) === 0 &&
				Math.floor(Math.abs(+new Date(drag?.schedule?.ending) - +new Date(drag?.prev?.ending)) / minimum) === 0 &&
				drag?.schedule?.resource?.id === drag?.prev?.resource?.id)
		)
		{
			console.log('onSelect...');
			await onSelect(drag?.schedule);
			console.log('onSelect ready');
			return set({ drag: undefined });
		}

		if (overlapping)
		{
			// _.map(drag?.prev, (v, k) => { drag.schedule[k] = v; });
			// _.assign(drag.prev, drag.schedule);
			Object.assign(drag.schedule, { ...(drag.prev || {}) });
			return set({ drag: undefined });
		}

		const confirmed = await confirmModal({
			title: 'Vahvistus',
			content: 'Muutetaanko varausta?',
		});

		if (confirmed)
		{
			if (drag?.schedule?.start || drag?.schedule?.ending)
			{
				drag.schedule.start = SqlDate(drag?.schedule?.start);
				drag.schedule.ending = SqlDate(drag?.schedule?.ending);
			}

			try
			{
				await Put({ path: 'schedule', body: drag?.schedule });

				showNotify({
					title: <>{lang('saved')}</>,
					message: <>{_.upperFirst(`${ lang('schedule') } ${ lang('saved') }`.toLowerCase())}</>,
					icon: (
						<Ikoni
							name='check-circle'
							fontColor={colors.green.normal}
							style={{ fontSize: '2rem', marginRight: '1.25rem' }}
						/>
					),
				});
			}
			catch (e)
			{
				errorNotification(e);
			}
		} else
		{
			Object.assign(drag.schedule, { ...(drag.prev || {}) });
		}

		set({ drag: undefined });
	};

	const onPointerMove = (e: PointerEvent) =>
	{
		if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
		if (e.buttons !== 1) return e.currentTarget.releasePointerCapture(e.pointerId);
		if (!drag?.prev) return;

		const diffX = (e.clientX - drag.x) / (rect?.width || 0);
		drag.schedule.start = +new Date(drag.prev.start) + timeRange * diffX;
		drag.schedule.ending = +new Date(drag.prev.ending) + timeRange * diffX;

		drag.schedule.start = Math.round(drag.schedule.start / minimum) * minimum;
		drag.schedule.ending = Math.round(drag.schedule.ending / minimum) * minimum;

		const diffY = Math.round((e.clientY - drag.y) / 44);
		const y = _.findIndex(resources?.data, f => f?.id === drag?.prev?.resource?.id);
		if (resources?.data?.[y + diffY]) drag.schedule.resource = resources.data[y + diffY];

		// const overlap = _.find(schedules?.data, (v) => (
		//   drag?.schedule?.id !== v?.id &&
		//   drag?.schedule?.resource?.id === v?.resource?.id &&
		//   +new Date(drag?.schedule?.start) < +new Date(v?.ending) &&
		//   +new Date(drag?.schedule?.ending) > +new Date(v?.start)
		// ));

		set({ drag });
	};

	return (
		<TimeBlockContainer
			style={{
				...(drag && { zIndex: 2 }),
				top: (resourceY || 0) * 44,
				left: ((+scheduleStart - +rangeStart) / timeRange) * (rect?.width || 0),
			}}
			onPointerOver={e =>
			{
				if (drag && pointerOver) return set({ pointerOver: false });
				if (!pointerOver) set({ pointerOver: true });
			}}
			onPointerLeave={e =>
			{
				if (drag && pointerOver) return set({ pointerOver: false });
				if (pointerOver) set({ pointerOver: false });
			}}
		>
			<Popup
				trigger={'contextMenu'}
				placement='bottom'
				visible={schedule?.active === 'deleted' ? false : undefined}
				content={
					<DropdownMenu>
						{(schedule?.status === 'new' && (
							<button
								onClick={async e =>
								{
									e.stopPropagation();
									e.preventDefault();
									if (!(await confirmModal({ content: 'Merkitäänkö varaus alkaneeksi?' }))) return;
									await Put({ path: 'schedule', body: { ...schedule, status: 'progress' } });
								}}
							>
								<Ikoni
									name='calendar-clock'
									twoTone
								/>
								{'\u2000'}
								Varaus alkanut
							</button>
						)) ||
							(schedule?.status === 'progress' && (
								<button
									onClick={async e =>
									{
										e.stopPropagation();
										e.preventDefault();
										if (!(await confirmModal({ content: 'Merkitäänkö varaus päättyneeksi?' }))) return;
										await Put({ path: 'schedule', body: { ...schedule, status: 'ready' } });
									}}
								>
									<Ikoni
										name='calendar-check'
										twoTone
									/>
									{'\u2000'}
									Varaus päättynyt
								</button>
							))}
						{schedule?.status !== 'ready' && <hr />}
						{schedule?.active !== 'deleted' && (
							<button
								onClick={async e =>
								{
									e.stopPropagation();
									e.preventDefault();
									// console.log(schedule);
									await confirmDelete('schedule', schedule, true);
								}}
							>
								<DeleteIconButton
									twoTone
									fontColor={colors.red.normal}
								/>
								{'\u2000'}
								Poista varaus
							</button>
						)}
					</DropdownMenu>
				}
			>
				<TimeblockDiv
					onClick={async () =>
					{
						if (!disabled) return;
						await onSelect(schedule);
					}}
					onPointerDown={onPointerDown}
					onPointerUp={onPointerUp}
					onPointerMove={onPointerMove}
					style={{
						width: `${ ((+scheduleEnding - +scheduleStart) / timeRange) * (rect?.width || 0) }px`,
						background,
						...(schedule?.status === 'ready' && {
							// cursor: 'not-allowed',
							cursor: 'default',
						}),
						color: '#fff',
						...(schedule?.status === 'ready' && { background: `${ background } ${ stripeBackground }`, color: '#fff', opacity: 0.7 }),
						...(drag && {
							background: `${ background } ${ stripeBackground }`,
							color: '#fff',
						}),
						...(overlapping && {
							background: `${ colors.red.normal } ${ stripeBackground }`,
							color: '#fff',
							opacity: 0.5,
						}),
					}}
				>
					<Tip
						color={background}
						content={<SchedulePopup schedule={schedule} />}
					>
						<TimeBlockContent
							style={{
								left: `${ Math.max(((+rangeStart - +scheduleStart) / Math.abs(+scheduleEnding - +scheduleStart)) * 100, 0) }%`,
								width: `${ 100 -
									Math.max(((+scheduleEnding - +rangeEnd) / Math.abs(+scheduleEnding - +scheduleStart)) * 100, 0) -
									Math.max(((+rangeStart - +scheduleStart) / Math.abs(+scheduleEnding - +scheduleStart)) * 100, 0)
									}%`,
							}}
						>
							{/* <Avatar style={{ background: '#fff' }} src={<Picture src={schedule?.resource?.picture} table={'resource'} />} /> */}
							{/* <span className="ant-avatar ant-avatar-circle ant-avatar-image">
                    <Picture src={schedule?.resource?.picture} table={'resource'} />
                  </span> */}
							{(schedule?.status === 'ready' && (
								<div style={{ minWidth: '28px' }}>
									<Ikoni
										name='circle-check'
										filled
										fontSize={24}
									/>
								</div>
							)) ||
								(schedule?.status !== 'ready' && dayjs(schedule?.ending).isBefore(new Date()) && (
									<div style={{ minWidth: '28px' }}>
										<Ikoni
											name='circle-exclamation'
											filled
											fontSize={24}
										/>
									</div>
								))}

							<div className='content'>
								<div>
									{(['waiting', 'failed'].includes(schedule?.payment_status) && (
										<>
											<Ikoni
												name='spinner-third'
												twoTone
												bgOpacity={0.6}
												spin
												fontSize={12}
											/>
											{'\u2000'}
										</>
									)) ||
										(Boolean(schedule?.payment_status === 'paid') && (
											<>
												<Ikoni
													name='euro-sign'
													twoTone
													bgOpacity={0.6}
													fontSize={12}
												/>
												{'\u2000'}
											</>
										)) ||
										(Boolean(schedule?.source === 'nettivaraus' || schedule?.online_booking === true) && (
											<>
												<Ikoni
													name='cart-shopping'
													twoTone
													bgOpacity={0.6}
													fontSize={12}
												/>
												{'\u2000'}
											</>
										))}
									{new Date(scheduleStart).toLocaleString('fi', {
										hour: '2-digit',
										minute: '2-digit',
										day: 'numeric',
										month: 'numeric',
									})}
									<> - </>
									{new Date(scheduleStart).toLocaleString('fi', { day: 'numeric', month: 'numeric' }) ===
										new Date(scheduleEnding).toLocaleString('fi', { day: 'numeric', month: 'numeric' })
										? new Date(scheduleEnding).toLocaleString('fi', { hour: '2-digit', minute: '2-digit' })
										: new Date(scheduleEnding).toLocaleString('fi', {
											hour: '2-digit',
											minute: '2-digit',
											day: 'numeric',
											month: 'numeric',
										})}
								</div>
								<div style={{ fontWeight: 300 }}>{schedule?.resource?.name}</div>
							</div>
						</TimeBlockContent>
					</Tip>

					{/* </Tooltip> */}
				</TimeblockDiv>
			</Popup>

			{!disabled && (
				<>
					<TimeBlockResizer
						// className='resizer'
						style={{ right: '100%', ...(drag && { opacity: 1 }) }}
						onPointerDown={onPointerDown}
						onPointerUp={onPointerUp}
						onPointerMove={async e =>
						{
							if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
							if (e.buttons !== 1) return e.currentTarget.releasePointerCapture(e.pointerId);
							if (!drag?.prev || !drag?.schedule)
							{
								e.currentTarget.releasePointerCapture(e.pointerId);
								return;
							}

							const diffX = (e.clientX - drag.x) / (rect?.width || 0);
							drag.schedule.start = +new Date(drag.prev.start) + timeRange * diffX;
							drag.schedule.start = Math.round(drag.schedule.start / minimum) * minimum;
							if (drag.schedule.start > +scheduleEnding - minimum)
							{
								drag.schedule.start = +scheduleEnding - minimum;
							}
							set({ drag });
						}}
					>
						<Ikoni name='angle-left' />
					</TimeBlockResizer>

					<TimeBlockResizer
						// className='resizer'
						style={{ ...(drag && { opacity: 1 }), left: '100%' }}
						onPointerDown={onPointerDown}
						onPointerUp={onPointerUp}
						onPointerMove={async e =>
						{
							if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
							if (e.buttons !== 1) return e.currentTarget.releasePointerCapture(e.pointerId);
							if (!drag?.prev || !drag?.schedule)
							{
								e.currentTarget.releasePointerCapture(e.pointerId);
								return;
							}

							const diffX = (e.clientX - drag.x) / (rect?.width || 0);
							drag.schedule.ending = +new Date(drag.prev.ending) + timeRange * diffX;
							drag.schedule.ending = Math.round(drag.schedule.ending / minimum) * minimum;
							if (drag.schedule.ending < +scheduleStart + minimum)
							{
								drag.schedule.ending = +scheduleStart + minimum;
							}
							set({ drag });
						}}
					>
						<Ikoni name='angle-right' />
					</TimeBlockResizer>
				</>
			)}
		</TimeBlockContainer>
	);
}
