import _ from 'lodash';
import { AutoComplete } from 'App/MainComponents';
import { Lang } from 'utils/Language';
import { Post } from 'utils/Api';
import { useMount, useStated } from 'hooks/Hooks';
import { openModal } from 'App/modals/ModalPopup';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { Assoc } from 'utils/Assoc';

export const SearchBox = () =>
{
	const [state, set] = useStated({
		search: '' as string,
		data: [] as Assoc<any>[],
	});

	const { search, data } = state;

	useMount(async () =>
	{
		if (search.length < 3) return;

		let destroyed = false;
		let controller: AbortController;
		let timeout: NodeJS.Timeout;

		(async () =>
		{
			try
			{
				await new Promise(r => (timeout = setTimeout(r, 100)));

				const tables = ['office', 'resource', 'customer', 'user', 'category'];
				const data: Assoc<any>[] = [];

				for (const table of tables)
				{
					if (destroyed) return;

					const res = await Post({
						path: table,
						body: {
							active: 'active',
							name: `%${ search || '' }%`,
						},
						header: {
							limit: 3,
						},
						extra: {
							signal: (controller = new AbortController()).signal,
						},
					});

					data.push(..._.map(res?.data, v => ({ ...v, table })));
				}
				if (destroyed) return;
				set({ data });
			} catch (e)
			{
				console.error(e);
			}
		})();

		return () =>
		{
			destroyed = true;
			controller?.abort?.();
			if (timeout) clearTimeout(timeout);
		};
	}, [search]);

	return (
		<AutoComplete
			value={search}
			onSearch={search => set({ search })}
			onBlur={() => set({ data: [], search: '' })}
			getPopupContainer={getPopupContainer}
			style={{ width: '300px', maxWidth: '100%' }}
			placeholder='Hae...'
			showSearch
			virtual
		>
			{_.map(data, (v, k) => (
				<AutoComplete.Option
					key={k}
					value={v?.name}
					data={v}
				>
					<div
						style={{
							display: 'flex',
							flexWrap: 'nowrap',
							padding: '5px 12px',
						}}
						onClick={async () =>
						{
							openModal({
								table: v?.table,
								data: async () =>
									(
										await Post({
											path: v?.table,
											body: { id: v?.id },
											header: { limit: 1 },
										})
									)?.data?.[0],
							});
						}}
					>
						<span style={{ flex: 1 }}>{v?.name}</span>
						<small style={{ flex: 0, textAlign: 'end' }}>
							<Lang>{v?.table}</Lang>
						</small>
					</div>
				</AutoComplete.Option>
			))}
		</AutoComplete>
	);
}
