import { Pie } from '@antv/g2plot';
import _ from 'lodash';
import { useRef } from 'react';
import { useMount } from 'hooks/Hooks';
import { ToCurrency } from 'hooks/Dates';
import { PostResponse } from 'utils/Api';

type Props = {
	schedules: PostResponse;
	offices: PostResponse;
	rangeStart: Date;
	rangeEnd: Date;
};

export const ToimipisteetMyynti = (props: Props) =>
{
	const { schedules, offices, rangeStart, rangeEnd } = props;

	const ref = useRef<HTMLDivElement>(null);
	useMount(() =>
	{
		const container = ref?.current;
		if (!container) return;

		const chart = new Pie(container, {
			height: 200,
			autoFit: true,
			renderer: 'svg',

			appendPadding: 10,
			data: _.sortBy(
				_.map(offices?.data, o => ({
					name: o.name,
					value: _.reduce(
						schedules?.data,
						(r, v) =>
						{
							if (+new Date(v?.created) < +rangeStart) return r;
							if (v?.office?.id === o?.id) r += Number(v?.customprice) || Number(v?.calcprice) || 0;
							return r;
						},
						0,
					),
				})),
				v => v?.value,
			),
			angleField: 'value',
			colorField: 'name',
			radius: 1,
			innerRadius: 0.7,
			label: {
				type: 'inner',
				offset: '-50%',
				// content: '{value} €',
				content: '',
				style: {
					textAlign: 'center',
					fontSize: 14,
				},
			},
			xAxis: {
				label: {
					formatter: v => v,
					autoEllipsis: true,
				},
			},
			tooltip: {
				customItems: items =>
				{
					return _.map(
						_.filter(items, v => v.value > 0),
						v => ({
							...v,
							value: ToCurrency(Number(v?.value || 0)),
							name: v.name.substring(0, 25) + (v.name.length > 25 ? '...' : ''),
						}),
					);
				},
			},
			// interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
			statistic: {
				title: false,
				content: {
					style: {
						fontSize: '14px',
						fontWeight: '400',
						whiteSpace: 'pre-wrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					formatter: () => 'Myynti',
				},
			},
		});

		chart.render();
		return () => chart.destroy();
	}, props);

	return <div ref={ref} />;
};
