import _ from 'lodash';
import dayjs from 'dayjs';
import { Table } from 'App/MainComponents';
import { Avatar } from 'App/components/OitisCard';
import { Ikoni } from 'icons';
import { Lang } from 'utils/Language';
import { Picture } from './Picture';
import { LocaleDate } from 'hooks/Dates';
import { useStated } from 'hooks/Hooks';
import { useStore } from 'store';
import { Tip } from 'App/popup/Popup';
import { colors } from 'App/modern/Colors';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

type Props = {
	table: string;
	onSelect: (record: Assoc<any>) => void;
	onChange: (params: Assoc<any>) => void;
	data: PostResponse;
};

export const ListView = (props: Props) =>
{
	const [state, set] = useStated({
		params: {
			sort: 'modified' as string,
			dir: 'desc' as string,
		},
	});

	const { params } = state;
	const { data, table, onChange, onSelect } = props;
	const types = useStore(s => s?.logged?.types?.[table]);

	return (
		<Table
			sticky
			components={{
				body: {
					row: ({ ...restProps }) => (
						<tr
							{...restProps}
							style={{
								cursor: 'pointer',
								...restProps?.style,
							}}
						/>
					),
				},
				// header: {
				//     cell: ({ ...restProps }) => {
				//         // console.log({...restProps});
				//         return (
				//             <th
				//                 {...restProps}
				//                 style={{ cursor: 'pointer', ...restProps?.style }}
				//             >
				//                 <div className="ant-table-column-sorters-with-tooltip">
				//                     <div className="ant-table-column-sorters">
				//                         <span>{ restProps?.title }</span>
				//                         <span className="ant-table-column-sorter ant-table-column-sorter-full">
				//                             <span className="ant-table-column-sorter-inner">
				//                                 <span role="img" aria-label="caret-up" className="anticon anticon-caret-up ant-table-column-sorter-up">
				//                                     <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
				//                                         <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
				//                                     </svg>
				//                                 </span>
				//                                 <span role="img" aria-label="caret-down" className="anticon anticon-caret-down ant-table-column-sorter-down">
				//                                     <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
				//                                         <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
				//                                     </svg>
				//                                 </span>
				//                             </span>
				//                         </span>
				//                     </div>
				//                 </div>
				//             </th>
				//         );
				//     },
				// }
			}}
			onRow={(record: Assoc<any>, rowIndex: unknown) =>
			{
				return {
					onClick: () => onSelect(_.cloneDeep(record)),
				};
			}}
			showSorterTooltip={false}
			onChange={async (pagination: unknown, filter: unknown, sorter: Assoc<any>) =>
			{
				params.sort = sorter?.field || 'modified';
				params.dir = sorter?.order === 'ascend' ? 'asc' : 'desc';
				set({ params });
				onChange(params);
			}}
			pagination={false}
			dataSource={data?.data || []}
			columns={_.map(
				_.pickBy(types, (v, k: string) =>
				{
					if (table === 'user')
					{
						if (k === 'password') return false;
						if (k === 'access_token') return false;
						if (k === 'address') return false;
						if (k === 'picture') return false;
					}

					if (table === 'office')
					{
						if (k === 'terms') return false;
						if (k === 'contract') return false;
						if (k === 'website') return false;
						if (k === 'maincolor') return false;
						if (k === 'send_reservation_email') return false;
						if (k === 'return_whenever') return false;
					}

					if (table === 'resource')
					{
						if (k === 'contract') return false;
						if (k === 'allow_customer_message') return false;
						if (k === 'price_by_distance') return false;
						if (k === 'option_group') return false;
						if (k === 'reservation_mode') return false;
						if (k === 'min_amount') return false;
						if (k === 'max_amount') return false;
						if (k === 'base_price') return false;
					}

					if (table === 'customer')
					{
						if (k === 'delivery_different') return false;
						if (k === 'delivery_address') return false;
						if (k === 'delivery_postal') return false;
						if (k === 'delivery_city') return false;
						if (k === 'personid') return false;
					}

					if (k === 'id') return false;
					if (v?.type === 'json') return false;
					if (k !== table && v?.type !== 'join') return true;
					// if (v?.flags?.readonly) return false;
					return false;
				}),
				(v, key: string) => ({
					key,
					title: <Lang>{key}</Lang>,
					dataIndex: key,
					sorter: true,
					ellipsis: {
						showTitle: true,
					},
					render: (text: string, row: Assoc<any>) =>
					{
						return (
							<Tip
								key={key}
								placement='top'
								disabled={String(row?.[key] ?? '')?.length < 8}
								style={{
									maxWidth: '250px',
									...(key === 'picture' && {
										position: 'relative',
										display: 'flex',
										flexDirection: 'column',
										background: '#fff',
										width: '200px',
										height: '200px',
									}),
								}}
								content={
									(key === 'picture' && <Picture src={row?.[key]} table={table} />) ||
									(v?.type === 'datetime' && LocaleDate(text)) ||
									String(row?.[key]?.name || text || '')
								}
							>
								<div
									style={{
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									{(() =>
									{
										const value = row?.[key];
										if (v?.type === 'row') return <>{value?.name || String(value || '')}</>;
										if (v?.type === 'enum') return <Lang>{value}</Lang>;
										if (v?.type === 'boolean')
										{
											if (value)
												return (
													<>
														<Ikoni name='circle-check' twoTone fontColor={colors.green.normal} />{' '}
														<Lang>{`${ value }`}</Lang>
													</>
												);
											return (
												<>
													<Ikoni name='circle-xmark' twoTone fontColor={colors.orange.normal} />{' '}
													<Lang>{`${ value }`}</Lang>
												</>
											);
										}
										if (v?.type === 'datetime')
										{
											return value !== undefined ? (
												LocaleDate(value)
											) : (
												<span style={{ color: '#999' }}>Ei asetettu</span>
											);
										}
										if (v?.type === 'date')
										{
											return value !== undefined ? (
												dayjs(value).format('DD.MM.YYYY')
											) : (
												<span style={{ color: '#999' }}>Ei asetettu</span>
											);
										}
										if (key === 'picture')
										{
											return (
												<Avatar>
													<Picture src={value} size={64} table={table} />
												</Avatar>
											);
										}
										return String(value || '');
									})()}
								</div>
							</Tip>
						);
					},

					// filterDropdown: (args: { setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any }) => (
					//     <div style={{ padding: '10px' }}>
					//         {/* <Input
					//             ref={node => {
					//                 // searchInput = node;
					//             }}
					//             placeholder={`Etsi ${lang(k)}`}
					//             value={args?.selectedKeys[0]}
					//             // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					//             // onPressEnter={() => handleSearch(selectedKeys, confirm, k)}
					//             style={{ width: 188, marginBottom: 8, display: 'block' }}
					//         /> */}
					//         <div>
					//             <TypedInput name={k} def={v} value={undefined} table={table}
					//                 onChange={(value) => {
					//                     // setData(k, value);
					//                 }}
					//             />
					//         </div>
					//         <Space>
					//             <Button
					//                 type="primary"
					//                 // onClick={() => handleSearch(selectedKeys, confirm, k)}
					//                 icon={<Ikoni name="Search" />}
					//                 size="small"
					//                 style={{ width: 90 }}
					//             >
					//                 Search
					//             </Button>
					//             <Button size="small" style={{ width: 90 }}
					//             // onClick={() => handleReset(clearFilters)}
					//             >
					//                 Reset
					//             </Button>
					//             <Button
					//                 type="link"
					//                 size="small"
					//                 onClick={() => {
					//                     // confirm({ closeDropdown: false });
					//                     // set({
					//                     //   searchText: selectedKeys[0],
					//                     //   searchedColumn: dataIndex,
					//                     // });
					//                 }}
					//             >
					//                 Filter
					//             </Button>
					//         </Space>
					//     </div>
					// ),
					// filterIcon: (filtered: any) => <Ikoni name="Search" style={{ color: filtered ? '#1890ff' : undefined }} />,
					// onFilter: (value: any, record: any) =>
					//     record[k]
					//         ? record[k].toString().toLowerCase().includes(value.toLowerCase())
					//         : '',
					// onFilterDropdownVisibleChange: (visible: any) => {
					//     if (visible) {
					//         // setTimeout(() => searchInput.select(), 100);
					//     }
					// },
				}),
			)}
		/>
	);
};
