import { Ikoni } from 'icons';
import { Loading } from 'App/modern/Loading';
import { styled, css, keyframes } from 'styled';
import { colors } from 'App/modern/Colors';

const zoomIn = keyframes`
	0% {
		transform: scale(0) translate(50%, -50%);
		opacity: 0;
	}
	to {
		transform: scale(1) translate(50%, -50%)
	}
`;

const animation = keyframes`
	0% {
		box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
	}
	to {
		box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
	}
`;

// declare global
// {
// 	namespace JSX
// 	{
// 		interface IntrinsicElements
// 		{
// 			// elementti: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
// 			elementti: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
// 		}
// 	}
// }

const BadgeContainer = styled.sup<{ type?: 'notify'; color?: 'orange'; }>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: ${ colors.blue.normal };
	overflow: hidden;
	padding: 3px 5px;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
	color: #fff;
	font-size: 13px;
	line-height: 14px;
	font-weight: 400;
	min-width: 20px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	left: -0.75em;

	/* transform: translateX(-40%); */
	/* transform: translateX(0.75em); */
	transform-origin: 100% 0%;
	animation: ${ zoomIn } 0.3s forwards;

	${ p =>
		p?.color === 'orange' &&
		css`
			background: ${ colors.orange.normal };
		`}

	${ p =>
		p?.type === 'notify' &&
		css`
			cursor: pointer;
			position: absolute;
			font-size: 12px;
			background: ${ colors.orange.normal };
			animation: ${ animation } 2s infinite;
			box-shadow: 0 0 0 ${ colors.orange.normal }66;
			vertical-align: super;
			bottom: 50%;
			left: 50%;
		`}
`;

type Props = {
	count?: number;
	text?: string;
	loading?: boolean;
	type?: 'notify';
	color?: 'orange';
};

export const Badge = (props: Props) =>
{
	const { count, text, type, color, loading } = props;
	if (!text && !count && !loading) return null;

	return (
		<BadgeContainer key={count} type={type} color={color}>
			{loading || (!text && count === undefined) ? <Loading /> : text || count}
		</BadgeContainer>
	);
};