import _ from 'lodash';
import dayjs from 'dayjs';
import { Lang, lang } from 'utils/Language';
import { TypedInput } from '../components/TypedInput';
import { Button, Form, Popconfirm, Table } from 'App/MainComponents';
import { LocaleDate } from 'hooks/Dates';
import { styled } from 'styled';
import { PriceConditionPicker, priceConditionTypes } from 'App/components/resource/PriceConditionPicker';
import { useStore } from 'store';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { Ikoni } from 'icons';
import { DeleteIconButton } from 'App/components/DeleteIconButton';
import { colors } from 'App/modern/Colors';
import { Assoc } from 'utils/Assoc';
import { useStated } from 'hooks/Hooks';
import { ModernButton } from '../modern/ModernButton';
import { Tip } from '../popup/Popup';
import { FormTableIconButton } from './FormTableIconButton';

const MinuteFormatter = (value: number) =>
{
	if (value === 60) return 'tunnin';
	if (value === 60 * 24) return 'vuorokauden';
	if (value === 60 * 24 * 7) return 'viikon';
	return [
		...(Math.floor(value / 60 / 24) > 0 ? [`${ Math.floor(value / 60 / 24) } vrk `] : []),
		...(Math.floor((value / 60) % 24) > 0 ? [`${ Math.floor((value / 60) % 24) } h`] : []),
		...(Math.floor(value % 60) > 0 ? [`${ Math.floor(value % 60) } min`] : []),
	].join(' ');
};

const TableContainer = styled.div`
	/* tbody .ant-table-cell {
        padding: 0!important;
    } */
	/* tbody .ant-table-cell:has(input) {
        padding: 0!important;
    } */
	/* tbody .ant-table-cell * {
		border: 0;
		border-radius: 0!important;
	} */
`;



const PriceDescriptor = (props: { record: Assoc<any>; }) =>
{
	const { record } = props;
	const findCond = (cond = '') => _.find(record?.conditions, { cond });

	return (
		<>
			{Boolean(_.find(findCond('weekdays')?.value, f => Boolean(f))) && (
				<span className='ant-tag ant-tag-green'>
					{(() =>
					{
						const days = _.map(
							_.pickBy(findCond('weekdays')?.value, (v, k) => Boolean(v)),
							(v, k: number) => _.upperFirst(dayjs().weekday(k).format('ddd')),
						);
						const lastDay = days.pop();
						if (days?.length === 0) return lastDay + '.';
						return _.join(days, ' / ') + ' / ' + lastDay + '.';
					})()}
				</span>
			)}

			{Boolean(findCond('date_range')?.value?.start && findCond('date_range')?.value?.ending) && (
				<span className='ant-tag ant-tag-geekblue'>
					<> {LocaleDate(findCond('date_range')?.value?.start, false)} </>
					<>
						{' '}
						<Ikoni name='angle-right' />{' '}
					</>
					<> {LocaleDate(findCond('date_range')?.value?.ending, false)} </>
				</span>
			)}

			{Boolean(findCond('time_range')?.value?.start && findCond('time_range')?.value?.ending) && (
				<span className='ant-tag ant-tag-blue'>
					<> klo </>
					<> {dayjs(findCond('time_range')?.value?.start, 'HH:mm:ss').format('H:mm')} </>
					<>
						{' '}
						<Ikoni name='angle-right' />{' '}
					</>
					<> {dayjs(findCond('time_range')?.value?.ending, 'HH:mm:ss').format('H:mm')} </>
				</span>
			)}

			{Boolean(findCond('repeat')?.value) && <> {MinuteFormatter(findCond('repeat')?.value?.time)} välein </>}

			{Boolean(findCond('min_duration')?.value?.time > 0 || findCond('max_duration')?.value?.time > 0) && (
				<> kun varaus kestää </>
			)}
			{(Boolean(
				findCond('min_duration')?.value?.time > 0 &&
				findCond('min_duration')?.value?.time === findCond('max_duration')?.value?.time,
			) && <> tasan {MinuteFormatter(findCond('min_duration')?.value?.time)} </>) || (
					<>
						{Boolean(findCond('min_duration')?.value?.time > 0) && (
							<> vähintään {MinuteFormatter(findCond('min_duration')?.value?.time)} </>
						)}
						{Boolean(findCond('min_duration')?.value?.time > 0) &&
							Boolean(findCond('max_duration')?.value?.time > 0) && <> ja </>}
						{Boolean(findCond('max_duration')?.value?.time > 0) && (
							<> enintään {MinuteFormatter(findCond('max_duration')?.value?.time)} </>
						)}
					</>
				)}
		</>
	);
};

type Props = {
	fieldName: string;
	parent: string;
	table: string;

	onChange?: (e: any) => void;
	value?: any;
};

export const FormTable = (props: Props) =>
{
	const types = useStore(s => s?.logged?.types);
	const [{ editField }, set] = useStated({ editField: 0 });
	const { value, parent, table, fieldName, onChange } = props;

	// console.log({ value });
	// if (value instanceof Array) value.sort((a,b)=>+new Date(a.modified)-+new Date(b.modified));
	// if (value instanceof Array) value.sort((a, b) => a.name.localeCompare(b.name));
	// const formValues = formRef?.current?.getFieldsValue();
	// console.log(formValues);

	// console.log('FormTable props', props);

	const header = _.pickBy(types?.[table], (v, k) =>
	{
		if (v?.flags?.readonly || k === parent) return false;
		return true;
	});

	return (
		<TableContainer>
			<Table
				size='middle'
				pagination={false}
				onRow={(record: unknown, index: any) => ({
					onClick: () =>
					{
						// console.log("Click record", record);
						set({ editField: index });
					},
				})}
				columns={[
					..._.map(header, (v, key) =>
					{
						return {
							title: lang(key),
							dataIndex: key,
							key,
							render: (_value: unknown, record: any, index: number) =>
							{
								if (table === 'opening' && key === 'weekday')
								{
									return _.upperFirst(dayjs().weekday(record.weekday).format('dddd'));
								}

								return (
									<div
										style={{
											...(table === 'price_field' &&
												_.find(record?.conditions, { cond: 'date_range' })?.value?.ending &&
												dayjs(_.find(record?.conditions, { cond: 'date_range' })?.value?.ending).isBefore(
													dayjs(),
												) && {
												opacity: 0.4,
											}),
										}}
									>
										<Form.Item
											noStyle
											name={[fieldName, index, key]}
											style={{ margin: '0 8px 8px 0' }}
											hidden={v?.flags?.readonly || (table === 'opening' && key === 'weekday')}
											rules={[
												{
													required: Boolean(
														!v?.flags?.readonly && !v?.flags?.allow_any && v?.default === undefined,
													),
													message: (
														<>
															<Lang>required field</Lang>: <Lang>{key}</Lang>
														</>
													),
												},
											]}
										>
											{(key === 'conditions' && (
												<div>
													<PriceDescriptor record={record} />
												</div>
											)) || (
													<TypedInput
														readOnly={v?.flags?.readonly}
														def={types?.[table]?.[key]!}
														name={key}
														table={table}
													/>
												)}
										</Form.Item>
									</div>
								);
							},
						};
					}),
					{
						title: '',
						dataIndex: 'operation',
						render: (val: any, record: any, index: number) =>
							table !== 'opening' && (
								<>
									<div style={{ whiteSpace: 'nowrap' }}>
										<Tip content={<Lang>kopioi</Lang>}>
											<ModernButton
												onClick={async e =>
												{
													e.stopPropagation();

													const walk = (obj: any) =>
													{
														if (!(obj instanceof Object)) return;
														Object.keys(obj).forEach(k =>
														{
															if (k === 'id') obj[k] = undefined;
															walk(obj[k]);
														});
													};

													const item = structuredClone({
														...value[index],
													});

													walk(item);
													value.splice(index, 0, item);
													console.log(value);
													onChange?.([...(value || [])]);
												}}
											>
												<Ikoni name='copy' bgOpacity={0.33} twoTone />
											</ModernButton>
										</Tip>

										<Popconfirm
											icon={<Ikoni name='exclamation-circle' fontColor={colors.orange.normal} />}
											cancelText='Peruuta'
											getPopupContainer={getPopupContainer}
											title='Poistetaanko?'
											onConfirm={() =>
											{
												console.log('val', 'record', val, record);
												value.splice(index, 1);
												onChange?.([...(value || [])]);
											}}
										>
											<span onClick={e => e.stopPropagation()}>
												<DeleteIconButton />
											</span>
										</Popconfirm>
									</div>
								</>
							),
					},
				]}
				expandable={
					table !== 'price_field'
						? undefined
						: {
							expandIcon: ({ expanded, onExpand, record }) =>
								expanded ? (
									<FormTableIconButton name='circle-caret-up' onClick={e => onExpand(record, e)} />
								) : (
									<FormTableIconButton name='circle-caret-down' onClick={e => onExpand(record, e)} />
								),
							expandedRowRender: record =>
							{
								return (
									<div>
										<div>
											{!Boolean(record?.conditions?.length) && <>Ei ehtoja</>}
											{_.map(record?.conditions, (v, k) => (
												<div key={k}>
													<PriceConditionPicker
														used={record?.conditions}
														cond={v?.cond}
														value={v?.value}
														onDelete={() =>
														{
															record?.conditions?.splice(k, 1);
															onChange?.(value);
															set({ editField: 0 });
														}}
														onChange={e =>
														{
															v.cond = e?.cond;
															v.value = e?.value;
															console.log('cond changed', e);
															onChange?.(value);
															set({ editField: 0 });
														}}
													/>
												</div>
											))}
										</div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ flex: 1 }}>{/* <PriceDescriptor record={record} /> */}</div>
											<div>
												{Boolean(
													_.find(
														priceConditionTypes,
														v => !_.find(record?.conditions, f => f?.cond === v),
													),
												) && (
														<Button
															style={{ margin: 0 }}
															type='link'
															onClick={() =>
															{
																value[record.key].conditions = [
																	...(record?.conditions || []),
																	{
																		// cond: 'min_duration',
																		cond: _.find(
																			priceConditionTypes,
																			v => !_.find(record?.conditions, f => f?.cond === v),
																		),
																		value: '',
																		type: '',
																	},
																];
																onChange?.(value);
																set({ editField: 0 });
															}}
														>
															<Ikoni name='plus' />
															{'\u2000'}
															Lisää ehto
														</Button>
													)}
											</div>
										</div>
									</div>
								);
							},
							rowExpandable: (record: unknown) => table === 'price_field',
						}
				}
				dataSource={[..._.map(value, (v, k) => ({ key: k, ...v }))]}
			/>
			{table !== 'opening' && (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={() =>
						{
							onChange?.([...(value || []), {}]);
							set({ editField: (value || [])?.length });
						}}
						type='link'
						style={{
							marginBottom: 0,
							marginTop: 12,
						}}
					>
						<Ikoni name='plus' />
						{'\u2000'}
						{lang('add new')}
					</Button>
				</div>
			)}
		</TableContainer>
	);
};
