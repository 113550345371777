import { ReactNode } from 'react';
import { Breadcrumbs } from 'App/components/Breadcrumbs';

export const PageLayout = (props: { children: ReactNode; }) =>
{
	const { children } = props;

	return (
		<>
			<Breadcrumbs />
			{children}
		</>
	);
}
