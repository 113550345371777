import React, { PointerEvent, RefObject } from 'react';
import _ from 'lodash';
import { Timeblock } from './Timeblock';
import { SqlDate } from 'hooks/Dates';
import { useStated } from 'hooks/Hooks';
import { styled } from 'styled';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

type Props = {
	resources: PostResponse;
	schedules: PostResponse;
	start: Date;
	ending: Date;
	checker: RefObject<HTMLDivElement>;
	onSelect: (schedule: Assoc<any>) => Promise<boolean>;
};

export const CreateTimeblock = (props: Props) =>
{
	const [state, set] = useStated({
		schedule: undefined as void | Assoc<any>,
		dragStart: undefined as void | number,
	});

	const { checker, resources, start, ending, onSelect, schedules } = props;
	let { schedule } = state;
	const { dragStart } = state;
	const rangeLength = +ending - +start;

	const onPointerUp = async (e: PointerEvent): Promise<void> =>
	{
		if (!schedule) return;
		if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
		e.currentTarget.releasePointerCapture(e.pointerId);
		e.preventDefault();
		e.stopPropagation();

		schedule.start = SqlDate(schedule?.start);
		schedule.ending = SqlDate(schedule?.ending);
		schedule.office = schedule?.resource?.office;

		const overlap =
			_.find(
				schedules?.data,
				v =>
					schedule?.id !== v?.id &&
					schedule?.resource?.id === v?.resource?.id &&
					+new Date(schedule?.start) < +new Date(v?.ending) &&
					+new Date(schedule?.ending) > +new Date(v?.start),
			);


		if (overlap) return set({ schedule: undefined });
		await onSelect(schedule);
		set({ schedule: undefined });
	};

	return (
		<>
			<Container
				onPointerDown={async e =>
				{
					if (e.buttons !== 1) return;
					e.currentTarget.setPointerCapture(e.pointerId);
					e.preventDefault();
					e.stopPropagation();

					const rect = checker.current?.getBoundingClientRect();
					if (!rect) return;

					schedule = {};

					const diffX = (e.clientX - (rect.x)) / (rect.width);
					schedule.start = +new Date(start) + rangeLength * diffX;
					schedule.ending = +new Date(start) + rangeLength * diffX + 1000 * 60 * 30;

					schedule.start = Math.round(schedule.start / (1000 * 60 * 15)) * (1000 * 60 * 15);
					schedule.ending = Math.round(schedule.ending / (1000 * 60 * 15)) * (1000 * 60 * 15);

					const diffY = Math.floor((e.clientY - (rect.y)) / 44);
					if (resources?.data?.[diffY]) schedule.resource = resources.data[diffY];

					set({ schedule, dragStart: diffX });
				}}
				onPointerMove={async e =>
				{
					if (!schedule || dragStart === undefined) return;
					if (!e.currentTarget.hasPointerCapture(e.pointerId)) return;
					if (e.buttons !== 1) return e.currentTarget.releasePointerCapture(e.pointerId);
					e.preventDefault();
					e.stopPropagation();

					const rect = checker.current?.getBoundingClientRect();
					if (!rect) return;

					const diffX = (e.clientX - (rect.x)) / (rect.width);

					if (diffX > dragStart)
					{
						schedule.ending = +new Date(start) + rangeLength * diffX;
						schedule.start = +new Date(start) + rangeLength * dragStart;
					} else
					{
						schedule.start = +new Date(start) + rangeLength * diffX;
						schedule.ending = +new Date(start) + rangeLength * dragStart;
					}

					// if (diffX < 0) {
					//   const start = schedule.start;
					//   schedule.start = schedule.ending;
					//   schedule.ending = start;
					// }

					schedule.start = Math.round(schedule.start / (1000 * 60 * 15)) * (1000 * 60 * 15);
					schedule.ending = Math.round(schedule.ending / (1000 * 60 * 15)) * (1000 * 60 * 15);

					set({ schedule });
				}}
				onPointerUp={onPointerUp}
			/>
			{schedule && (
				<Timeblock
					checker={checker}
					schedule={schedule}
					schedules={schedules}
					resources={resources}
					rangeStart={start}
					rangeEnd={ending}
					onSelect={onSelect}
				/>
			)}
		</>
	);
};
