import { Pie } from '@antv/g2plot';
import _ from 'lodash';
import { useRef } from 'react';
import { useMount } from 'hooks/Hooks';
import { styled } from 'styled';
import { ToCurrency } from 'hooks/Dates';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const Container = styled.div`
	position: relative;
`;

type Props = {
	schedules: PostResponse;
	rangeStart: Date;
	rangeEnd: Date;
};

export const Suosituimmat = (props: Props) =>
{
	const { schedules, rangeStart } = props;

	const total = _.reduce(
		schedules?.data,
		(r, v) =>
		{
			if (+new Date(v?.created) < +rangeStart) return r;
			r += v?.customprice ?? v?.calcprice ?? 0;
			return r;
		},
		0,
	);

	const popular = _.chain(schedules?.data)
		.reduce((r, v) =>
		{
			if (+new Date(v?.created) < +rangeStart) return r;

			const f = _.find(r, { name: v?.resource?.name });
			if (!f)
				r.push({
					name: v?.resource?.name,
					sales: 0,
					total,
				});
			else f.sales += v?.customprice ?? v?.calcprice;

			return r;
		}, [] as Assoc<any>[])
		.sortBy(v => -Number(v?.sales) || 0)
		.value();

	const ref = useRef<HTMLDivElement>(null);
	useMount(() =>
	{
		const container = ref?.current;
		if (!container) return;

		const chart = new Pie(container, {
			height: 200,
			autoFit: true,
			renderer: 'svg',

			appendPadding: 10,
			data: _.sortBy(
				_.map(popular.slice(0, 10), o => ({
					name: o.name,
					// value: Math.round(o?.sales / total * 100)
					value: Number(o?.sales) || 0,
				})),
				v => v?.value,
			),
			angleField: 'value',
			colorField: 'name',
			radius: 1,
			innerRadius: 0.7,
			label: {
				type: 'inner',
				offset: '-50%',
				// content: '{value} €',
				content: '',
				style: {
					textAlign: 'center',
					fontSize: 14,
				},
			},
			xAxis: {
				label: {
					formatter: v => v,
					autoEllipsis: true,
				},
			},
			// yAxis: {
			//     label: {
			//         formatter: (v) => 'asd',
			//         autoEllipsis: true,
			//     }
			// },
			tooltip: {
				customItems: items =>
				{
					return _.map(
						_.filter(items, v => v.value > 0),
						v => ({
							...v,
							value: ToCurrency(Number(v?.value || 0)),
							name: v.name.substring(0, 25) + (v.name.length > 25 ? '...' : ''),
						}),
					);
				},
			},
			// interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
			statistic: {
				title: false,
				content: {
					style: {
						fontSize: '14px',
						fontWeight: '400',
						whiteSpace: 'pre-wrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					formatter: () => 'Myynti',
				},
			},
		});

		chart.render();
		return () => chart.destroy();
	}, props);

	return <Container ref={ref} />;
};
