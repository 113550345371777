import { colors } from 'App/modern/Colors';
import { styled } from 'styled';

export const OitisCard = styled.div`
	color: #545454;
	font-size: 14px;
	position: relative;
	padding: 24px;
	background: #fff;
	margin-bottom: 24px;
	overflow: hidden;

	box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
	border-radius: 10px;

	@media screen and (max-width: 991px) {
		border-radius: 0;
	}
`;

export const DropdownMenu = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 4px 0;
	text-align: left;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

	& > hr {
		padding: 0;
		margin: 3px 0;
	}

	& > a,
	& > button {
		display: inline-flex;
		align-items: center;

		margin: 0;
		padding: 5px 12px;
		color: #545454;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		white-space: nowrap;
		cursor: pointer;
		transition: all 0.3s;

		& > i {
			font-size: 14px;
			min-width: 20px;
			transition: color 100ms ease;
		}

		&:hover {
			background-color: #f5f5f5;
			color: ${ colors.blue.normal };
		}

		&.active {
			& > i {
				color: unset !important;
			}
		}

		&:active:not(:disabled) {
			background-color: #e6faff;
		}

		& > svg {
			min-width: 12px;
			margin-right: 8px;
			font-size: 12px;
			vertical-align: -0.1em;
		}
	}
`;

// export const Divider = styled.hr`
//     border-color: hsla(0, 0%, 98%, 0.2);
//     margin: 4px 0;
// `;

export const Avatar = styled.span`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	color: #545454;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	min-width: 24px;
	min-height: 24px;
	margin: 0;
	padding: 0;

	overflow: hidden;
	border: 1px solid #e8e8e8;
	border-radius: 50%;

	cursor: pointer;
	background: transparent;
`;
