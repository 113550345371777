import './errors';

// import * as serviceWorker from './serviceWorker';
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App/App';

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/fi';

dayjs.locale('fi');
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// if (DEVELOPMENT)
// {
// 	const { HashBenchmark } = (await import('dev/HashBenchmark'));
// 	await HashBenchmark();
// }

// // console.log("DEV", DEVELOPMENT);
// (async () =>
// {
// 	// Kokeillaan krässätä backend
// 	const testi = await Post({
// 		path: 'office',
// 		body: {
// 			// active: 123,
// 			created: 123,
// 		}
// 	});
// 	console.log("tulos:", testi);
// })();

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

// {
// 	const el = document.getElementById('root') as HTMLElement;
// 	ReactDOM.render(
// 		// <StrictMode>
// 		<ConfigProvider locale={fiFI}>
// 			<App />
// 		</ConfigProvider>
// 		// </StrictMode>
// 		, el
// 	);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
