import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
export { BrowserRouter, Redirect, Route, Switch, useParams } from 'react-router-dom';
export type { NavLinkProps } from 'react-router-dom';

export const NavLink = (props: NavLinkProps) =>
{
	return (
		<RouterNavLink
			{...props}
			draggable={false}
		/>
	);
};