import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

export const SqlDate = (date: number | Date | string | Dayjs | null | undefined) =>
{
	if (!date) return undefined;
	// return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
	// return new Date(+date).toJSON().slice(0, 19).replace('T', ' ');

	// const d = new Date(isdayjs(date) ? date.toDate() : date);
	const d = dayjs(date);
	return new Date(+d - new Date(+d).getTimezoneOffset() * 1000 * 60).toJSON().slice(0, 19).replace('T', ' ');
};

export const LocaleTime = (date: number | Date | string) =>
{
	return new Date(date).toLocaleString('fi', {
		hour: '2-digit',
		minute: '2-digit',
	});
};

export const LocaleDate = (date: number | Date | string, time = true) =>
{
	return new Date(date).toLocaleString('fi', {
		...(time && {
			hour: '2-digit',
			minute: '2-digit',
		}),
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	});
};

export const ToDuration = (ms: number) =>
{
	const msAbs = Math.abs(ms / 1000 / 60);
	const times: [number, string][] = [
		[Math.floor(msAbs / 60 / 24), 'vrk'],
		[Math.floor(msAbs / 60) % 24, 'h'],
	];
	if (Math.floor(ms / 1000 / 60 / 60 / 24) <= 0)
	{
		times.push([Math.floor(msAbs) % 60, 'min']);
	}
	const t = times.filter(v => v[0]);
	return _.join(
		_.map(t, v => _.join(v, ' ')),
		' ',
	);

	// return (
	//     dayjs(msAbs).diff(0, 'day') + ' vrk ' +
	//     dayjs(msAbs).diff(0, 'hour') + ' h ' +
	//     dayjs(msAbs).diff(0, 'minute') + ' min '
	// );

	// return (
	//     Math.floor(msAbs / 60 / 24) ?
	//     Math.floor(msAbs / 60 / 24) + ' vrk ' : ''
	// )
	// + (
	//     Math.floor(msAbs / 60) % 24 ?
	//     Math.floor(msAbs / 60) % 24 + ' h ' : ''
	// )
	// + (
	//     Math.floor(ms / 1000 / 60 / 60 / 24) <= 0 &&
	//     Math.floor(msAbs) % 60 ?
	//     Math.floor(msAbs) % 60 + ' min' : ''
	// );
};

export const isValidDate = (d: unknown) => d instanceof Date && !isNaN(+d);

export const ToCurrency = (price: number, noEuroSign = false) =>
	(Number(price) || 0).toLocaleString('fi-FI', { minimumFractionDigits: 2 }) + (noEuroSign ? '' : ' €');

export const FixDst = (d: string | number, reverse = false) =>
{
	const date = new Date(d);
	const jan = new Date(date.getFullYear(), 0, 1);
	const offset = jan.getTimezoneOffset() - new Date(d).getTimezoneOffset();
	if (reverse) return new Date(+date + offset * 1000 * 60);
	return new Date(+date - offset * 1000 * 60);
};
