import _ from 'lodash';
import dayjs from 'dayjs';
import { styled } from 'styled';
import { TinyColumn } from '@antv/g2plot';
import { useRef } from 'react';
import { ToCurrency } from 'hooks/Dates';
import { useMount } from 'hooks/Hooks';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const Container = styled.div`
	position: relative;
	display: flex;
	& > div {
		position: relative;
		overflow: hidden;
		flex: 1;
	}
`;

type Props = {
	schedules: PostResponse;
	rangeLabel: string;
	rangeStart: Date;
	rangeEnd: Date;
};

export const KokonaisMyynti = (props: Props) =>
{
	const { schedules, rangeStart, rangeEnd, rangeLabel } = props;

	const total = _.reduce(
		schedules?.data,
		(r, v) =>
		{
			if (+new Date(v?.created) < +rangeStart) return r;
			r += v?.customprice ?? v?.calcprice ?? 0;
			return r;
		},
		0,
	);

	const ref = useRef<HTMLDivElement>(null);
	useMount(() =>
	{
		const container = ref?.current;
		if (!container) return;

		const chart = new TinyColumn(container, {
			height: 50,
			autoFit: true,
			renderer: 'svg',

			tooltip: {
				customContent: (x, data) => ToCurrency(data?.[0]?.value),
			},
			data: _.values(
				_.reduce(
					schedules?.data,
					(r, v) =>
					{
						if (+new Date(v?.created) < +rangeStart) return r;
						const k = dayjs(v?.created).format('DD.MM.YYYY');
						if (!r?.[k]) r[k] = 0;
						r[k] += v?.customprice ?? v?.calcprice ?? 0;
						return r;
					},
					{} as Assoc<any>,
				),
			),
		});

		chart.render();
		return () => chart.destroy();
	}, props);

	return (
		<Container>
			<div style={{ position: 'relative', flex: 1 }}>
				<span style={{ whiteSpace: 'nowrap', fontSize: '28px', fontWeight: 600, color: '#121' }}>
					{_.map(ToCurrency(total).split(','), (v, k) =>
						k === 1 ? (
							<span
								key={k}
								style={{ fontSize: '20px', fontWeight: 600 }}
							>
								,{v}
							</span>
						) : (
							v
						),
					)}
				</span>
				<div>{rangeLabel}</div>
			</div>

			<div ref={ref} />
		</Container>
	);
};
