import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { styled } from 'styled';
import { Timeblock } from './Timeblock';
import { CreateTimeblock } from './CreateTimeblock';
import { ResourcesPanel } from 'App/calendar/ResourcesPanel';
import { HoverCanvas } from 'App/calendar/HoverCanvas';
import { Spinning } from 'App/modern/Loading';
import { useStated } from 'hooks/Hooks';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const TimelaneContainer = styled.div`
	position: relative;
	height: 100%;
	min-height: 44px;
	width: 100%;
	display: flex;
	flex-direction: column;
	/* overflow: hidden; */
`;

const TimelaneHeader = styled.div`
	position: relative;
	height: 44px;
	width: 100%;
	display: flex;
	background: #fff;
`;

const HeaderBlock = styled.div`
	flex: 1;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TimelaneBody = styled.div`
	position: relative;
	height: 100%;
	min-height: 44px;
	width: 100%;
	display: flex;
`;

const Checker = styled.div`
	user-select: none;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	min-height: 44px;
	/* background: #fdfdfd; */
	/* background-image:
    linear-gradient(#00000010 0, transparent 1px), 
    linear-gradient(90deg, #00000030 0, transparent 1px);
  background-position: -1px -1px;
  background-size: 4.16667% 44px; */
	border: 1px solid #ddd;
	border-radius: 9px;
	overflow: hidden;
`;

const CheckerCanvas = styled.div<{ width: number; }>`
	user-select: none;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	/* background: #fdfdfd; */
	background-image: linear-gradient(#00000010 0, transparent 1px), linear-gradient(90deg, #00000030 0, transparent 1px);
	background-position: -1px -1px;
	background-size: ${ p => p.width }% 44px;
`;

const Ticker = styled.div`
	pointer-events: none;
	height: 100%;
	width: 1px;
	position: absolute;
	/* top: 44px; */
	top: 0;
	/* box-shadow: 0 0 1px 0px #09bdfe; */
	background: #00000010;
	border-right: 1px solid #09bdfe;
	border-top-left-radius: 9px;
	border-bottom-left-radius: 9px;

	:before {
		content: '';
		position: absolute;
		width: 9px;
		height: 9px;
		border-radius: 100px;
		background: #09bdfe;
		box-shadow: 0 0 2px -1px #09bdfe;
		top: 0px;
		margin-left: -4px;
	}
`;

const BlocksContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	/* overflow: hidden; */
`;

type Props = {
	resources: PostResponse;
	offices: PostResponse;
	schedules: PostResponse;
	start: Date;
	ending: Date;
	onSelect: (schedule: Assoc<any>) => Promise<boolean>;
};

export const Timelane = (props: Props) =>
{
	// console.log("[Timelane] render");
	const checker = useRef<HTMLDivElement>(null);
	const { offices, schedules, start, ending, onSelect } = props;
	const [state, set] = useStated({ search: '' as string });

	const DAY = 1000 * 60 * 60 * 24;
	const HOUR = 1000 * 60 * 60;
	const rangeLength = +ending - +start;
	const headerDivider = rangeLength > DAY ? DAY : HOUR;
	const tickerX = ((+new Date() - +start) / rangeLength) * 100;

	const onSearch = useCallback(
		_.debounce((search: string) => set({ search }), 100),
		[],
	);
	const search = String(state.search || '').toLowerCase();
	const resources = {
		...props?.resources,
		...(search && {
			data: _.filter(props?.resources?.data, v => String(v?.name).toLowerCase().includes(search)),
		}),
	};

	return (
		<TimelaneContainer>
			{!isFinite(schedules?.count || resources?.count || offices?.count) && <Spinning />}

			<HoverCanvas checker={checker} />

			<TimelaneBody>
				<ResourcesPanel
					resources={resources}
					offices={offices}
					// search={search}
					onSearch={search =>
					{
						onSearch(search);
					}}
				/>
				<BlocksContainer>
					<TimelaneHeader>
						{_.map(_.range(Math.round(rangeLength / headerDivider)), (v, k) => (
							<HeaderBlock key={k}>
								{rangeLength > 1000 * 60 * 60 * 24
									? new Date(+start + v * headerDivider).toLocaleString('fi', {
										day: 'numeric',
										weekday: 'short',
										month: 'numeric',
									})
									: new Date(+start + v * headerDivider).toLocaleString('fi', { hour: 'numeric', minute: '2-digit' })}
							</HeaderBlock>
						))}
					</TimelaneHeader>
					<Checker ref={checker}>
						<CheckerCanvas
							key={`${ +start }${ +ending }`}
							width={(headerDivider / rangeLength) * 100}
						>
							<CreateTimeblock
								checker={checker}
								resources={resources}
								schedules={schedules}
								start={start}
								ending={ending}
								onSelect={onSelect}
							/>
							{_.map(schedules?.data, (v, k) =>
							{
								if (+new Date(v?.ending) < +start && +new Date(v?.start) > +ending) return null;
								return (
									<Timeblock
										key={v?.id || k}
										checker={checker}
										schedule={v}
										schedules={schedules}
										resources={resources}
										rangeStart={start}
										rangeEnd={ending}
										onSelect={onSelect}
									/>
								);
							})}
							<Ticker
								style={{
									...(tickerX <= 0 ? { left: `${ tickerX }%` } : { paddingLeft: `${ tickerX }%` }),
								}}
							/>
						</CheckerCanvas>
					</Checker>
				</BlocksContainer>
			</TimelaneBody>
		</TimelaneContainer>
	);
}
