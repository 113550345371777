import { notification } from 'App/MainComponents';
import { Lang } from 'utils/Language';
import { ModernSpinner } from 'App/components/ModernSpinner';
import { Ikoni } from 'icons';
import { colors } from 'App/modern/Colors';
import { ReactNode } from 'react';

type NotifyOptions = {
	title: ReactNode;
	icon: ReactNode;
	message: ReactNode;
	spinner?: boolean;
};

export const showNotify = (opts: NotifyOptions) =>
{
	const { title, icon, message, spinner } = opts;

	notification.open({
		message: (
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: '-8px' }}>
				{
					icon
					// <Ikoni name='print' color={colors.blue.normal} style={{ fontSize: '2rem', marginRight: '1.25rem' }} />
				}
				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{spinner && <ModernSpinner style={{ marginRight: '0.55em', color: colors.blue.normal }} />}
						<span style={{ fontSize: '1.2rem' }}>{title}</span>
					</div>
					<div style={{ fontSize: '1rem', fontWeight: '300' }}>{message}</div>
				</div>
			</div>
		),
	});
};
