import _ from 'lodash';
import { Picture } from 'App/components/Picture';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { styled } from 'styled';
import { PostResponse, Put } from 'utils/Api';
import { hasPrivilege } from 'utils/UserUtils';
import { useStore } from 'store';
import { Avatar } from 'App/components/OitisCard';
import { Input } from 'App/MainComponents';
import { Assoc } from 'utils/Assoc';

const TimelanePanel = styled.div`
	position: relative;
	height: 100%;
	width: 200px;
	background: #fff;
	/* border-right: 1px solid #bbb; */
`;

const PanelBlock = styled.div`
	/* position: relative; */
	display: flex;
	align-items: center;

	width: 100%;
	height: 44px;

	background: #fff;
	padding: 0 10px 0 5px;
	max-width: 100%;
	/* min-width: 0px; */
	/* white-space: nowrap; */
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */

	&,
	& > div {
		/* flex: 1; */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	> ${ Avatar } {
		min-width: 32px;
		min-height: 32px;
		max-width: 32px;
		max-height: 32px;
		margin-right: 8px;
	}
`;

type ResourcesPanelProps = {
	resources: PostResponse;
	offices: PostResponse;
	onSearch: (search: string) => void;
	// search: string,
};

export const ResourcesPanel = (props: ResourcesPanelProps) =>
{
	const logged = useStore(s => s?.logged);
	const { resources, offices, onSearch } = props || {};

	const dragEnd = async (result: DropResult) =>
	{
		// Dropped outside the list
		if (!result.destination) return;
		const resource = _.find(resources?.data, { id: result?.draggableId });

		if (!resource?.office) return void console.error('Resources office not found:', resource);

		const items = Array.from(resources?.data);
		const [reorderedItem] = items.splice(result.source.index, 1);
		if (reorderedItem) items.splice(result.destination.index, 0, reorderedItem);

		// console.log(items.map((v, k) => [k, v]));

		resource.office.preferences = {
			...(resource.office?.preferences || {}),
			resourceOrder: _.mapValues(items, (v: Assoc<any>, k: number) => v?.id),
		};

		resources.data = items;

		return void await Put({
			path: 'office',
			body: {
				id: resource.office?.id,
				preferences: resource.office?.preferences,
			},
		});
	};

	const dragDisabled = Boolean(
		(offices?.data?.length > 1 && !logged?.settings?.office) || !hasPrivilege('office', 'update') || resources?.data?.length <= 1,
	);

	return (
		<DragDropContext onDragEnd={dragEnd}>
			<TimelanePanel>
				<PanelBlock>
					{resources?.count > 10 && (
						<Input
							allowClear
							style={{ margin: 0, maxHeight: '100%', width: '100%', flex: 1 }}
							placeholder='Hae...'
							onChange={e => onSearch(e.target.value)}
						// onKeyDown={(e) =>
						// {
						// 	if (e.key === 'Escape') {
						// 		e.currentTarget.value = '';
						// 		onSearch('');
						// 	}
						// }}
						/>
					)}
				</PanelBlock>

				<Droppable
					droppableId='droppable'
					isDropDisabled={dragDisabled}
				>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{_.map(resources?.data, (v, k) => (
								<Draggable
									key={v?.id}
									draggableId={v?.id}
									index={k}
									isDragDisabled={dragDisabled}
								>
									{provided => (
										<PanelBlock
											key={v?.id || k}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={{
												...provided.draggableProps.style,
											}}
										>
											<Avatar>
												<Picture
													src={v?.picture}
													table={'resource'}
													size={64}
												/>
											</Avatar>
											<div>{v?.name}</div>
										</PanelBlock>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</TimelanePanel>
		</DragDropContext>
	);
}
