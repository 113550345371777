import React from 'react';
// import hash_sum from 'hash-sum';

type Hook = () => Promise<void>;

type BaseStore<State extends {}> = {
	getStore(): State;
	setStore(partial: Partial<State>): void;
	useStore<S>(selector: (state: State) => S): S;
};

const createBaseStore = <State extends {}>(initial: State): BaseStore<State> =>
{
	const hooks = new Set<Hook>();
	const state: State = initial;

	const store: BaseStore<State> = {
		getStore: () => state,
		useStore: selector =>
		{
			const value = selector(state);
			const [tick, update] = React.useState(0);

			React.useEffect(() =>
			{
				const hook: Hook = async () => update(tick + 1);
				hooks.add(hook);
				return () => void hooks.delete(hook);
			}, [tick]);

			return value;
		},
		setStore: async partial =>
		{
			Object.assign(state, partial);
			for (const hook of hooks) hook();
		},
	};

	return store;
};

export const createStore = <State extends {}>(name: string, initial: State): BaseStore<State> =>
{
	const global = globalThis as typeof globalThis & Record<string, BaseStore<State>>;
	return (global[name] ??= createBaseStore<State>(initial));
};
