import { Assoc } from './Assoc';
import { Ikoni } from 'icons';
import { Input } from 'App/MainComponents';
import { BaseModal } from 'App/modals/BaseModal';
import { ModernButton } from 'App/modern/ModernButton';
import { styled } from 'styled';
import { useStated } from 'hooks/Hooks';
import { Put } from './Api';
import { lang } from './Language';
import { addPortal, removePortal } from 'App/components/Portal';
import { colors } from 'App/modern/Colors';
import { errorNotification } from './ErrorNotification';
import { showNotify } from 'App/modals/showNotify';

const DialogContent = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 1.3;
	word-wrap: break-word;
	padding: 24px 16px 4px 16px;

	& .header {
		display: flex;
		line-height: 1.5;

		& > div {
			flex: 1;
		}

		& > .anticon,
		& > i {
			font-size: 30px;
			padding: 4px 12px 0 0;
		}

		& > div > .title {
			color: #535353;
			display: block;
			font-size: 16px;
			font-weight: 500;
			line-height: 1.4;
			overflow: hidden;
		}
	}

	& .footer {
		display: flex;
		margin-top: 12px;
		align-self: end;
	}
`;

type ConfirmProps = {
	title?: React.ReactNode;
	icon?: React.ReactNode;
	content?: React.ReactNode;
	isInfo?: true,
};

export const confirmModal = async (props: ConfirmProps): Promise<boolean> =>
{
	return await new Promise((resolve: (confirm: boolean) => void) =>
	{
		const Content = () =>
		{
			const [state, set] = useStated({
				visible: true as boolean,
			});

			const close = (accept: boolean) =>
			{
				set({ visible: false });
				return resolve(accept);
			};

			return (
				<BaseModal
					visible={state.visible}
					afterClose={() => removePortal(portal)}
					onCancel={() => close(false)}
				>
					<DialogContent>
						<div className='header'>
							{icon || (
								<Ikoni
									name='circle-exclamation'
									filled
									style={{ color: colors.orange.normal }}
								/>
							)}
							<div>
								<span className='title'>{title || 'Vahvistus'}</span>
								<div style={{ marginTop: '6px' }}>{content}</div>
							</div>
						</div>
						<div className='footer'>
							{
								isInfo ?
									<ModernButton color='blue' onClick={() => close(false)}>
										Sulje
									</ModernButton> :
									<>
										<ModernButton onClick={() => close(false)}>Peruuta</ModernButton>
										<ModernButton
											color='blue'
											onClick={() => close(true)}
										>
											Vahvista
										</ModernButton>
									</>
							}
						</div>
					</DialogContent>
				</BaseModal>
			);
		};

		const { title, icon, content, isInfo } = props || {};
		const portal = addPortal(Content);
	});
};

export const confirmDelete = async (table: string, data: Assoc<any>, askReason?: boolean): Promise<boolean> =>
{
	return await new Promise((resolve: (confirm: boolean) => void) =>
	{
		const Content = () =>
		{
			const [state, set] = useStated({
				visible: true as boolean,
				reason: '' as string,
			});

			const close = (accept: boolean) =>
			{
				set({ visible: false });
				return resolve(accept);
			};

			return (
				<BaseModal
					visible={state.visible}
					afterClose={() => removePortal(portal)}
					onCancel={() => close(false)}
				>
					<DialogContent>
						<div className='header'>
							<Ikoni
								// name='circle-exclamation'
								name='circle-trash'
								filled
								style={{ color: colors.red.normal }}
							/>
							<div>
								<span className='title'>Vahvistus</span>
								<div style={{ marginTop: '6px' }}>Poistetaanko {lang(table, true)}?</div>
								<div style={{ marginTop: '8px' }}>
									{askReason && (
										<Input.TextArea
											autoSize={{ minRows: 1, maxRows: 4 }}
											placeholder={'Syy varauksen poistoon'}
											required
											value={data.deletereason}
											onChange={e =>
											{
												data.deletereason = e.target.value;
												set({ reason: data.deletereason });
											}}
										/>
									)}
								</div>
							</div>
						</div>
						<div className='footer'>
							<ModernButton onClick={() => close(false)}>Peruuta</ModernButton>
							<ModernButton
								color='blue'
								disabled={askReason && !state.reason}
								onClick={async (): Promise<void> =>
								{
									if (askReason && !data?.deletereason)
									{
										return showNotify({
											title: 'Tarkista tiedot',
											icon: (
												<Ikoni
													name='exclamation-triangle'
													fontColor={colors.orange.normal}
													style={{ fontSize: '2rem', marginRight: '1.25rem' }}
												/>
											),
											message: <>{lang('selite puuttuu')}</>,
										});
									}

									try
									{
										await Put({ path: table, body: { ...data, active: 'deleted' } });

										showNotify({
											title: <>{lang('deleted')}</>,
											message: <>{`${ lang(table) } ${ lang('deleted', true) }`}</>,
											icon: (
												<Ikoni
													name='check-circle'
													fontColor={colors.green.normal}
													style={{ fontSize: '2rem', marginRight: '1.25rem' }}
												/>
											),
										});
									}
									catch (e)
									{
										errorNotification(e);
									}
									finally
									{
										return void close(true);
									}
								}}
							>
								Vahvista
							</ModernButton>
						</div>
					</DialogContent>
				</BaseModal>
			);
		};

		const portal = addPortal(Content);
	});
};

export const unsavedChanges = async (): Promise<boolean> =>
{
	return await confirmModal({
		title: <>Tallentamattomia muutoksia</>,
		content: <>Hylätäänkö muutokset?</>,
	});
};
