import _ from 'lodash';
import { lang } from 'utils/Language';
import { showNotify } from 'App/modals/showNotify';
import { Ikoni } from 'icons';
import { colors } from 'App/modern/Colors';

export const errorNotification = (e: unknown) =>
{
	console.error(e);
	const { error } = (e || {}) as { error: string | string[]; };

	showNotify({
		icon: (
			<Ikoni
				name='exclamation-triangle'
				fontColor={colors.orange.normal}
				style={{ fontSize: '2rem', marginRight: '1.25rem' }}
			/>
		),
		title: (error instanceof Array && lang(error?.[0] || '')) || 'Virhe',
		message:
			(typeof error === 'string' && lang(error || '')) ||
			(error instanceof Array && _.map(error?.slice(1), (v, k) => <div key={k}>{lang(v)}</div>)),
	});
};
