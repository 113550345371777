import { request } from 'utils/Api';
import { errorNotification } from 'utils/ErrorNotification';
import { Ikoni } from 'icons';
import { colors } from 'App/modern/Colors';
import { showNotify } from './showNotify';

export const printContract = async (id: string) =>
{
	showNotify({
		title: 'Tulostetaan',
		message: 'Tulostetaan sopimus',
		icon: (
			<Ikoni
				name='print'
				fontColor={colors.blue.normal}
				style={{ fontSize: '2rem', marginRight: '1.25rem' }}
			/>
		),
		spinner: true,
	});

	try
	{
		const data = await request({ method: 'POST', path: 'print', body: { id } });
		const blob = await data.blob();
		const printURL = URL.createObjectURL(blob);

		const iframe: HTMLIFrameElement = document.querySelector('[print_frame]') || document.createElement('iframe');

		iframe.setAttribute('print_frame', '');
		iframe.style.position = 'absolute';
		iframe.style.visibility = 'hidden';
		iframe.style.maxWidth = '1px';
		iframe.style.maxHeight = '1px';
		iframe.src = printURL;

		return await new Promise((resolve: (value: void) => void) =>
		{
			iframe.onload = async () =>
			{
				if (navigator?.userAgent?.match?.(/firefox/i)) await new Promise(r => setTimeout(r, 1000));

				const contentWindow = iframe?.contentWindow;
				if (!contentWindow) return resolve();

				contentWindow.focus();
				contentWindow.print();
				await new Promise(r => setTimeout(r, 1000));
				return resolve();
			};

			document.body.appendChild(iframe);
		});
	}
	catch (e)
	{
		try
		{
			if (e instanceof Response) e = await e.json();
		}
		catch (e) { }

		errorNotification(e);
	}
}
