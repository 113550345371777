import { ReactNode, SVGProps } from 'react';
import { styled } from 'styled';

const EmptyContainer = styled.div`
	color: #777;
	padding: 12px 8px;
	display: flex;
	flex-direction: column;
	align-items: center;

	& > svg {
		width: 100%;
		max-height: 50px;
	}

	& > span {
		font-size: 13px;
	}

	& > div {
		margin-top: 15px;
	}
`;

export const EmptyContent = ({ children }: { children?: ReactNode; }) => (
	<EmptyContainer>
		<EmptyIcon />
		<span>Ei tuloksia</span>
		{children && <div>{children}</div>}
	</EmptyContainer>
);

export const EmptyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={184}
		height={152}
		viewBox='0 0 184 152'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g
			fill='none'
			fillRule='evenodd'
		>
			<g transform='translate(24 31.67)'>
				<ellipse
					fill='#f5f5f5'
					fillOpacity={0.8}
					cx={67.797}
					cy={106.89}
					rx={67.797}
					ry={12.668}
				/>
				<path
					fill='#aeb8c2'
					d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
				/>
				<path d='M115.097 86.214L94.19 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H45.284c-1.54 0-3.047.66-4.048 1.867L20.329 86.214v13.792h94.768V86.214z' />
				<path
					fill='#f5f5f7'
					d='M33.83 0h67.933a4 4 0 014 4v93.344a4 4 0 01-4 4H33.83a4 4 0 01-4-4V4a4 4 0 014-4z'
				/>
				<path
					fill='#dce0e6'
					d='M42.678 9.953h50.237a2 2 0 012 2V36.91a2 2 0 01-2 2H42.678a2 2 0 01-2-2V11.953a2 2 0 012-2zm.262 39.814h49.713a2.262 2.262 0 110 4.524H42.94a2.262 2.262 0 010-4.524zm0 11.763h49.713a2.262 2.262 0 110 4.525H42.94a2.262 2.262 0 010-4.525zm78.873 43.502c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 01-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569a7.33 7.33 0 01-.221 1.789z'
				/>
			</g>
			<path
				fill='#dce0e6'
				d='M149.121 33.292l-6.83 2.65a1 1 0 01-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
			/>
			<g
				fill='#fff'
				transform='translate(149.65 15.383)'
			>
				<ellipse
					cx={20.654}
					cy={3.167}
					rx={2.849}
					ry={2.815}
				/>
				<path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
			</g>
		</g>
	</svg>
);
