import _ from 'lodash';

import { Skeleton } from 'App/MainComponents';
import { SqlDate } from 'hooks/Dates';
import { Lang } from 'utils/Language';
import { usePost } from 'hooks/usePost';
import { Area } from '@antv/g2plot';
import { OitisCard } from 'App/components/OitisCard';
import { useRef } from 'react';
import { EmptyContent } from 'App/components/EmptyIcon';
import { Badge } from 'App/components/Badge';
import { OitisCardHeader } from 'App/components/OitisCardHeader';
import { useMount } from 'hooks/Hooks';
import { PostOptions } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

export const Visitors = () =>
{
	let time = Date.now() - 1000 * 60 * 60 * 24 * 30;
	time = Math.floor(time / (1000 * 60)) * (1000 * 60);

	const conds: PostOptions = {
		path: 'visitor',
		body: {
			time: `>=${ SqlDate(time) }`,
		},
		header: {
			limit: 5000,
			sort: 'time',
			dir: 'desc',
		},
	};

	const [visitors, status] = usePost(conds);

	const referers: { [key: string]: number; } = {};
	const addresses: { [key: string]: number; } = {};

	const getData = () =>
	{
		const timeFrame = 1000 * 60 * 60;
		const keys: { [key: string]: number; } = {};
		let start = 0,
			end = 0;
		const arr: Assoc<any> = {};

		_.map(visitors?.data, v =>
		{
			const referer =
				String(v?.referer || '')
					.replace(/^http[s]*:[/]{2}(.*?)[:/].*/isu, '$1')
					.replace(/^www\./, '') || '(tuntematon)';
			const address = String(v?.address || '').replace(/[:]*.*?[:*](.*?)/isu, '$1') || 'Tuntematon osoite';
			referers[referer] = (referers?.[referer] || 0) + 1;
			addresses[address] = (addresses?.[address] || 0) + 1;

			const date = new Date(v?.time);
			const time = Math.floor(+date / timeFrame);

			if (!start || time < start) start = time;
			if (!end || time > end) end = time;

			if (!arr[time]) arr[time] = {};
			if (!arr[time][referer]) arr[time][referer] = {};
			if (!arr[time][referer][address]) arr[time][referer][address] = 0;
			arr[time][referer][address]++;

			const key = `${ time }_${ referer }`;
			keys[key] = (keys[key] || 0) + 1;
		});

		const result = [];
		for (let time = start; time < end; time++)
		{
			for (const referer in referers)
			{
				result.push({
					referer,
					date: new Date(time * timeFrame).toLocaleString('fi'),
					value: Number(Object.keys(arr?.[time]?.[referer] || [])?.length) || 0,
				});
			}
		}

		return result;
	};

	const data = getData();

	const ref = useRef<HTMLDivElement>(null);
	useMount(() =>
	{
		const container = ref?.current;
		if (!container) return;

		const chart = new Area(container, {
			height: 360,
			autoFit: true,
			smooth: true,
			renderer: 'svg',

			xField: 'date',
			yField: 'value',
			seriesField: 'referer',
			appendPadding: 10,
			legend: { position: 'top' },
			interactions: [{ type: 'brush-x' }],
			// modes: {
			// 	// Allow users to drag canvas, zoom canvas, and drag nodes
			// 	default: ['drag-canvas', 'zoom-canvas', 'drag-node'],
			// },
			tooltip: {
				// customContent: chartTooltip({
				// 	placeholder: 'Ei kävijöitä',
				// 	formatValue: (v) => (<>{v} eri kävijää</>),
				// }),

				customItems: items =>
				{
					return _.map(
						_.filter(items, v => v.value > 0),
						v => ({
							...v,
							value: `${ Number(v.value) } kävijää`,
							name: v.name.substring(0, 25) + (v.name.length > 25 ? '...' : ''),
						}),
					);
				},
			},
			data,
		});
		chart.render();
		return () =>
		{
			chart.destroy();
		};
	}, data);

	return (
		<OitisCard>
			<OitisCardHeader>
				<h2 style={{ flex: 1 }}>
					<Lang>visitors</Lang>
					{'\u2000'}
					<Badge
						count={Object.keys(addresses)?.length}
						loading={visitors?.count === undefined}
					/>
				</h2>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}></div>
			</OitisCardHeader>
			{(visitors?.count === undefined && <Skeleton active />) ||
				(visitors?.count === 0 && <EmptyContent />) ||
				(visitors?.count > 0 && (
					<div ref={ref} />
				))
				// <>
				// 	{
				// 		<Area {...} />
				// 	}
				// </>
			}
		</OitisCard>
	);
}
