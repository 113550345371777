import _ from 'lodash';
import dayjs from 'dayjs';
import { styled } from 'styled';
import { TimeBlockContent } from 'App/calendar/Timeblock';
import { openModal } from 'App/modals/ModalPopup';
import { Tip } from 'App/popup/Popup';
import { SchedulePopup } from 'App/calendar/SchedulePopup';
import { Ikoni } from 'icons';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Post, PostResponse } from 'utils/Api';
import { colors } from 'App/modern/Colors';

import moment from 'moment';
import 'moment/locale/fi';
import { Assoc } from 'utils/Assoc';
moment.locale('fi');

// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const Container = styled.div`
	.rbc-month-view,
	.rbc-month-row + .rbc-month-row,
	.rbc-day-bg + .rbc-day-bg {
		border: 0;
	}
	.rbc-header {
		border: 0 !important;
		text-align: end;
		font-weight: 400;
		font-size: 16px;
		text-transform: capitalize;
	}
	.rbc-date-cell,
	.rbc-day-bg {
		border-top: 2px solid #eee;
		padding: 5px 0px;
	}
	.rbc-off-range-bg {
		background: #efefef;
	}
	.rbc-row-segment {
		min-height: 34px;
		position: relative;
		overflow: hidden;
		border-bottom: 1px solid transparent;

		> div {
			width: 100%;
			height: 100%;
			color: #efefef;
			cursor: pointer;
		}
	}
`;

const Row = styled.div`
	overflow: hidden;

	> div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

type Props = {
	table: string;
	start: Date;
	ending: Date;
	schedules: PostResponse;
	onShowMore?: (date: Date) => void;
};

const localizer = momentLocalizer(moment);

export const SchedulesCalendarOld = (props: Props) =>
{
	const { table, start, ending, schedules, onShowMore } = props;

	return (
		// <Calendar
		//   locale={fiFI}
		//   onPanelChange={(date) => {
		//     console.log(date.format('DD.MM.YYYY'));
		//   }}
		//   dateCellRender={(value) => {
		//     return <>
		//       {
		//         _.map(_.filter(schedules?.data, (item: any) => {
		//           if (value.isSame(dayjs(item?.start), 'd')) return true;
		//           return false;
		//         }), (item: any, k) => {
		//           return (
		//             <BadgeContent key={k}>
		//               <Badge status={'success'} text={`${dayjs(item?.start).format('HH:mm')} ${item?.resource?.name}`} />
		//               {/* <Button>{ `${ dayjs(item?.start).format('HH:mm') } ${ item?.resource?.name }` }</Button> */}
		//             </BadgeContent>
		//           );
		//         })
		//       }
		//     </>;
		//   }}
		// />
		// <CalendarView
		//   offices={offices}
		//   resources={resources}
		//   schedules={schedules}
		//   start={start}
		//   ending={ending}
		//   onSelect={(schedule: any, done: () => void) => {
		//     this.setState({
		//       modal: {
		//         table,
		//         data: {
		//           ..._.mapValues(user?.types?.[table], (v) => (v?.default || undefined)),
		//           ..._.cloneDeep(schedule),
		//         },
		//         props: {
		//           visible: true,
		//           onOk: () => { modal.props.visible = false; this.setState({ modal }); done(); },
		//           onCancel: () => { modal.props.visible = false; this.setState({ modal }); done(); },
		//         },
		//       }
		//     })
		//   }}
		// />
		<Container>
			<Calendar
				localizer={localizer}
				selectable
				views={['month']}
				defaultView='month'
				showMultiDayTimes
				// showAllEvents
				// handleDragStart={(e) =>
				// {
				//     console.log('handleDragStart', e);
				// }}
				onSelectSlot={slotInfo =>
				{
					console.log('create schedule:', slotInfo);

					openModal({
						table,
						data: async () => ({
							start: +slotInfo.end > +slotInfo.start ? slotInfo.start : +dayjs(slotInfo.start),
							ending: +slotInfo.end > +slotInfo.start ? slotInfo.end : +dayjs(slotInfo.end).endOf('d'),
						}),
					});
				}}
				date={new Date(+start)}
				toolbar={false}
				onShowMore={(_events, date) =>
				{
					onShowMore?.(date);
				}}
				messages={{
					showMore: count => `${ count } lisää...`,
					// allDay: 'Koko päivä',
				}}
				// onSelectEvent={(event) =>
				// {
				// 	console.log("open schedule:", event);
				// 	const schedule = event?.data;
				// 	openModal({
				// 		table: 'schedule',
				// 		data: async () => (await Post('schedule', { id: schedule?.id }, { limit: 1 }))?.data?.[0],
				// 	});
				// }}
				components={{
					eventWrapper: props =>
					{
						const schedule = props?.event?.data;
						const _start = dayjs(schedule?.start);
						const _ending = dayjs(schedule?.ending);

						const background =
							(schedule?.active === 'deleted' && '#777777') ||
							(schedule?.status === 'new' && colors.blue.normal) ||
							(schedule?.status === 'progress' && colors.green.normal) ||
							(schedule?.status === 'ready' && colors.green.normal) ||
							(schedule?.status === 'cancelled' && colors.orange.normal) ||
							(schedule?.active === 'inactive' && '#AAAAAA') ||
							'#389FFF';

						return (
							<>
								<Tip
									color={background}
									content={<SchedulePopup schedule={schedule} />}
								>
									<TimeBlockContent
										style={{
											// overflow: 'hidden',
											// position: 'absolute',
											background,
											padding: '2px 5px',
											borderRadius: '5px',
										}}
										onPointerDown={e =>
										{
											e.preventDefault();
											e.stopPropagation();
										}}
										onClick={async e =>
										{
											e.preventDefault();
											e.stopPropagation();

											console.log('open schedule:', schedule?.id);

											openModal({
												table: 'schedule',
												data: async () =>
													(
														await Post({
															path: 'schedule',
															body: {
																id: schedule?.id,
															},
															header: { limit: 1 },
														})
													)?.data?.[0],
											});
										}}
									>
										<Row>
											<div>
												{Boolean(schedule?.source === 'nettivaraus' || schedule?.online_booking === true) && (
													<Ikoni
														name='cart-shopping'
														twoTone
														style={{ marginRight: '2px' }}
													/>
												)}
												{_start.isBefore(dayjs(start), 'd') && _start.format('DD.MM.')}
												{_start.isBefore(dayjs(start), 'y') && _start.format('YYYY')}
												{'\u2000'}
												{_start.format('HH:mm')}
												<> - </>
												{!_start.isSame(dayjs(_ending), 'd') && _ending.format('DD.MM.')}
												{_ending.isBefore(dayjs(ending), 'y') && _ending.format('YYYY')}
												{'\u2000'}
												{_ending.format('HH:mm')}
											</div>
											<div>{schedule?.resource?.name}</div>
										</Row>
									</TimeBlockContent>
								</Tip>
							</>
						);
					},
				}}
				events={_.map(schedules?.data, v => ({
					resource: v?.resource?.name,
					title: v?.resource?.name,
					start: new Date(v?.start),
					end: new Date(v?.ending),
					allDay: false,
					data: v,
				}))}
				style={{ height: '100%' }}
			/>
		</Container>
	);
}
