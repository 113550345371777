import _ from 'lodash';
import { setStore, useStore } from 'store';
import { Avatar } from 'App/components/OitisCard';
import { ModernList, ModernListItem } from 'App/modern/ModernList';
import { Ikoni } from 'icons';
import { lang } from 'utils/Language';
import { Picture } from 'App/components/Picture';
import { NavLink } from 'router';
import { Post, PostOptions } from 'utils/Api';
import { openModal } from 'App/modals/ModalPopup';
import { Badge } from 'App/components/Badge';
import { Popup, Tip } from 'App/popup/Popup';
import { styled, css } from 'styled';
import { getToken, setToken } from 'utils/Token';
import { colors } from 'App/modern/Colors';
import { Assoc } from 'utils/Assoc';
import { Tag } from 'App/modern/Tag';
import { useMount, useStated } from '../../hooks/Hooks';
import { usePost } from '../../hooks/usePost';

const CardActions = styled.ul`
	display: flex;
	border: 0px;
	align-items: center;
	justify-content: center;
	background: #fafafa;
	border-top: 1px solid #e8e8e8;
	list-style: none;
	margin: 0;
	padding: 0;
	height: 40px;
	cursor: default;

	&:empty {
		display: none;
	}
`;

const CardAction = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const stripeBackground = `repeating-linear-gradient(-45deg, #00000008 0px, #00000008 10px, transparent 10px, transparent 20px)`;

const Container = styled.div<{ active?: 'active' | 'inactive' | 'deleted'; }>`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100% - 32px);
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 12px;
	box-shadow: 0 0 3px 0 rgb(0 0 0 / 3%);
	margin-bottom: 32px;
	overflow: hidden;
	transition: box-shadow 0.1s ease-out;
	cursor: pointer;

	${ p =>
		p?.active === 'inactive' &&
		css`
			background: ${ stripeBackground } ${ colors.orange.normal }08;
			filter: contrast(0.95);
		`}

	${ p =>
		p?.active === 'deleted' &&
		css`
			background: ${ stripeBackground } ${ colors.red.normal }08;
			filter: contrast(0.95);
		`}

	h3, h5 {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	h3 {
		color: #333;
		/* white-space: nowrap; */
	}

	h5 {
		color: #555;
		/* height: 100%; */
		/* max-height: 2.5em; */
	}

	&:active,
	&:focus,
	&:focus-within,
	&:hover,
	&:target {
		border: 1px solid #ddd;
		box-shadow: 0 0 5px 3px rgb(0 0 0 / 5%);
	}
`;

const Header = styled.div`
	position: relative;
	overflow: hidden;
	height: 120px;
	i {
		font-size: 42px;
	}
`;

const Body = styled.div`
	flex: 1;
	padding: 15px 20px 25px 20px;
`;

const TagButton = styled(Tag)`
	position: absolute;
	top: 0;
	left: 0;
	margin: 6px;
	z-index: 1;
	transition: filter 100ms ease-in-out;

	:hover {
		filter: contrast(0.9) brightness(1.1);
	}
`;

type Props = {
	table: string;
	data: Assoc<any>;
	onClick?: () => void;
	onSettings?: () => void;
};

const CategoryCount = ({ category }: { category: string; }) =>
{
	const [resources, status] = usePost({
		path: 'resource',
		body: {
			active: 'active',
			category,
		},
		header: {
			fields: {
				id: true,
				name: true,
			},
		},
	});

	if (!resources?.count) return null;

	return (
		<Popup
			color='#fff'
			style={{ color: '#555', pointerEvents: 'auto', padding: 0 }}
			content={
				<ModernList style={{ minWidth: '150px', maxWidth: '250px', maxHeight: '350px', overflow: 'auto' }}>
					{_.map(resources?.data, (item, key) => (
						<ModernListItem
							key={item?.id || key}
							hover
							onClick={e =>
							{
								e.stopPropagation();

								const table = 'resource';
								const { id } = item;
								openModal({
									table,
									data: async () =>
										(
											await Post({
												path: table,
												body: { id },
												header: { limit: 1 },
											})
										)?.data?.[0],
								});
							}}
						>
							{/* <Avatar> */}
							{/* <Picture table={table} src={item?.picture} size={64} /> */}
							{/* </Avatar> */}
							<div style={{ flex: 1, marginLeft: '12px' }}>{item?.name}</div>
						</ModernListItem>
					))}
				</ModernList>
			}
		>
			<div>
				<Ikoni name='list-tree' style={{ fontSize: '16px', paddingRight: 8 }} />
				<Badge count={resources?.count} loading={status === 'loading'} />
			</div>
		</Popup>
	);
};

export const Item = (props: Props) =>
{
	const [user, links] = useStore(s => [s?.logged?.user, s?.logged?.links]);
	const { data, table, onClick, onSettings } = props;
	const { picture, name, description } = data;

	return (
		<Container active={data?.active}>
			<Header onClick={onClick}>
				<Picture table={table} src={picture} />
				{Boolean(data?.parents?.length) && (
					<Tip
						content={
							<span>
								<Ikoni name='folder-open' />
								{'\u2000'}
								Avaa ylempi tuote
							</span>
						}
					>
						<TagButton
							color={'blue'}
							onClick={e =>
							{
								e.stopPropagation();
								const id: string = data?.parents?.[0]?.id;
								if (!id) return;
								openModal({
									table,
									data: async () =>
										(
											await Post({
												path: table,
												body: { id },
												header: { limit: 1 },
											})
										)?.data?.[0],
								});
							}}
						>
							{data?.parents?.[0]?.name}
						</TagButton>
					</Tip>
				)}
			</Header>

			<Body onClick={onClick}>
				<small>{data?.office?.name || data?.company?.name}</small>
				<h3>
					{data?.super === true && (
						<>
							<Ikoni name='star' twoTone fontColor={colors.blue.normal} fontSize={16} />
							{'\u2000'}
						</>
					)}
					{name}
				</h3>
				<h5>{description || data?.value}</h5>
			</Body>

			<CardActions>
				{Boolean(table === 'category') && <CategoryCount category={data?.id} />}

				{Boolean(table === 'resource' && data?.subresources?.length) && (
					<CardAction onClick={onSettings}>
						<NavLink
							style={{ width: '100%', height: '100%' }}
							to={`/${ lang(links?.[table]?.title || table, true) }/${ data?.id }`}
						>
							<Popup
								color='#fff'
								style={{ color: '#555', pointerEvents: 'auto', padding: 0 }}
								content={
									<ModernList
										style={{ minWidth: '150px', maxWidth: '250px', maxHeight: '350px', overflow: 'auto' }}
									>
										{_.map(data?.subresources, (item, k) => (
											<ModernListItem
												key={item?.id || k}
												hover
												onClick={e =>
												{
													e.stopPropagation();
													const id: string = item?.id;
													if (!id) return;
													openModal({
														table,
														data: async () =>
															(
																await Post({
																	path: table,
																	body: { id },
																	header: { limit: 1 },
																})
															)?.data?.[0],
													});
												}}
											>
												<Avatar>
													<Picture table={table} src={item?.picture} size={64} />
												</Avatar>
												<div style={{ flex: 1, marginLeft: '12px' }}>{item?.name}</div>
											</ModernListItem>
										))}
									</ModernList>
								}
							>
								<div>
									<Ikoni name='list-tree' style={{ fontSize: '16px' }} />
									{'\u2000'}
									<Badge
										count={data?.subresources?.length}
										loading={data?.subresources?.length === undefined}
									/>
								</div>
							</Popup>
						</NavLink>
					</CardAction>
				)}
				{/* <li style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
									onClick={() =>
									{
										onClick?.();
									}}
							>
									<Ikoni name="wrench" />
							</li> */}
				{/* <li style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
									onClick={() =>
									{
										onClick?.();
									}}
							>
									<Ikoni name="Ellipsis" />
							</li> */}
				{Boolean(user?.super === true && table === 'user') && (
					<Tip
						content={
							<div>
								<Ikoni name='star' />
								{'\u2000'}
								Kirjaudu käyttäjänä
							</div>
						}
					>
						<CardAction
							onClick={async e =>
							{
								e.stopPropagation();
								const superToken = getToken();
								if (!superToken) throw Error('Super token missing');

								localStorage?.setItem?.('superToken', superToken);
								setToken(data?.apikey);
								setStore({ logged: undefined });
							}}
						>
							<Ikoni name='right-to-bracket' style={{ padding: '6px', fontSize: '16px' }} />
						</CardAction>
					</Tip>
				)}
				{/* <li style={{ width: '33.3333%' }}>
					<span>
					<span role="img" aria-label="ellipsis" className="anticon anticon-ellipsis">
						<svg viewBox="64 64 896 896" focusable="false" data-icon="ellipsis" width="1em" height="1em" fill="currentColor" aria-hidden="true">
							<path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z" />
						</svg>
					</span>
					</span>
				</li> */}
			</CardActions>

			{/* <div className="oitis-product-footer">
				<Button>
					Muokkaa
				</Button>
	
				<Button type="primary">
					Poista
				</Button>
			</div> */}
		</Container>
	);
};