import { Post } from 'utils/Api';
import { Checkbox, Form, Input, message } from 'App/MainComponents';
import { Ikoni } from 'icons';
import { styled } from 'styled';
import { useParams } from 'router';
import { useStated } from 'hooks/Hooks';
import { setToken } from 'utils/Token';
import { ModernButton } from 'App/modern/ModernButton';
import { Loader } from 'App/modern/Loading';
import { OitisLogo } from 'App/OitisLogo';
import { Assoc } from 'utils/Assoc';

import bg from 'assets/images/bg.png';

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: url(${ bg });
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 100%;

	.oitis-login-content {
		position: relative;
		overflow: hidden;
		max-width: 420px;
		width: 94%;
		margin: auto;
		padding: 35px 35px 20px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
		font-size: 14px;

		@media screen and (max-width: 575px) {
			padding: 20px 20px 10px;
		}
	}
`;

export const Login = () =>
{
	const params: { page: string; secret: string; } = useParams();
	const [state, set] = useStated({
		view: (['recover', 'change'].includes(params?.page) ? params.page : '') as '' | 'recover' | 'change',
		status: '' as '' | 'invalid' | 'success',
		recoverStatus: '' as '' | 'invalid' | 'success',
		loading: false as boolean,
	});
	const { view, loading } = state;

	const login = async (email: string, password: string): Promise<void> =>
	{
		set({ loading: true });
		try
		{
			const res = await Post<{ access_token?: string; }>({ path: 'login', body: { email, password } });
			if (!res?.access_token) throw Error('Login failed');
			setToken(res.access_token);

			set({ status: 'success' });
			message.success('Tervetuloa');
			window.location.replace('/');
		}
		catch (e)
		{
			set({ status: 'invalid' });
			message.error('Kirjautuminen epäonnistui');
		}
		finally
		{
			return void set({ loading: false });
		}
	};

	const recover = async (email: string): Promise<void> =>
	{
		set({ loading: true });
		try
		{
			await Post({ path: 'recover', body: { email } });
			message.success('Salasanan vaihto-ohjeet lähetetty sähköpostiisi');
			set({ view: '', recoverStatus: 'success' });
		}
		catch (e)
		{
			message.error('Käyttäjätunnusta ei löydy');
			set({ recoverStatus: 'invalid' });
		}
		finally
		{
			return void set({ loading: false });
		}
	};

	const changePassword = async (password: string, password_again: string, secret: string): Promise<void> =>
	{
		if (password !== password_again)
		{
			return message.error('Salasanat eivät täsmää');
		}

		try
		{
			set({ loading: true });
			await Post({ path: 'reset', body: { password, secret } });
			message.success('Salasanan vaihdettu, voit nyt kirjautua uudella salasanalla');
			set({ view: '', recoverStatus: 'success' });
		}
		catch (e)
		{
			const err = e as { error: string; };
			if (err?.error === 'weak') message.error('Salasanan minimipituus on 8 merkkiä');
			else message.error('Salasanan vaihtolinkki on vanhentunut tai virheellinen');

			set({ recoverStatus: 'invalid' });
		}
		finally
		{
			return void set({ loading: false });
		}
	};

	return (
		<LoginContainer>
			{(view === 'change' && params?.secret && (
				<div className='oitis-login-content'>
					{loading && (
						<Loader>
							<Ikoni
								name='spinner-third'
								twoTone
								fontSize={48}
								spin
							/>
						</Loader>
					)}
					<div
						className='oitis-login-header'
						style={{ display: 'flex' }}
					>
						<div>
							<h2>Uusi salasana</h2>
						</div>
						<div style={{ flex: 1, textAlign: 'end' }}>
							<OitisLogo style={{ height: '30px' }} />
						</div>
					</div>
					<div className='oitis-mb-4'>
						<h4>Vaihda uusi salasana</h4>
						<p>Syötä uusi salasana</p>
					</div>
					<Form
						className='ant-form ant-form-horizontal oitis-signin-form oitis-form-row0'
						onFinish={(e: Assoc<any>) =>
						{
							changePassword(e?.password, e?.password_again, params?.secret);
						}}
					>
						<div
							className='ant-row ant-form-item'
							style={{ rowGap: 0 }}
						>
							<div className='ant-col ant-form-item-control'>
								<Form.Item
									name='password'
									rules={[
										{
											required: true,
											message: 'Syötä salasana',
										},
									]}
								>
									<Input
										type='password'
										prefix={
											<Ikoni
												name='lock'
												twoTone
												className='site-form-item-icon'
											/>
										}
										placeholder='Salasana'
									/>
								</Form.Item>
							</div>
						</div>
						<div
							className='ant-row ant-form-item'
							style={{ rowGap: 0 }}
						>
							<div className='ant-col ant-form-item-control'>
								<Form.Item
									name='password_again'
									rules={[
										{
											required: true,
											message: 'Syötä salasana uudelleen',
										},
									]}
								>
									<Input
										type='password'
										prefix={
											<Ikoni
												name='lock'
												twoTone
												className='site-form-item-icon'
											/>
										}
										placeholder='Salasana uudelleen'
									/>
								</Form.Item>
							</div>
						</div>
						<div
							className='ant-row ant-form-item'
							style={{ rowGap: 0 }}
						>
							<div className='ant-col ant-form-item-control oitis-text-center'>
								<Form.Item>
									<ModernButton
										type='submit'
										color='blue'
									>
										Vaihda salasana
									</ModernButton>
								</Form.Item>
							</div>
						</div>
					</Form>
					{/* <div style={{ marginTop: '8px', textAlign: 'right' }}>
                                    <a href="/recover" onClick={(e) => {
                                        e.preventDefault();
                                        set({ view: '' });
                                    }}>
                                        Palaa kirjautumiseen
                                    </a>
                                </div> */}
				</div>
			)) ||
				(view === 'recover' && (
					<div className='oitis-login-content'>
						{loading && (
							<Loader>
								<Ikoni
									name='spinner-third'
									twoTone
									fontSize={48}
									spin
								/>
							</Loader>
						)}
						<div
							className='oitis-login-header'
							style={{ display: 'flex' }}
						>
							<div>
								<h2>Tilaa uusi salasana</h2>
							</div>
							<div style={{ flex: 1, textAlign: 'end' }}>
								<OitisLogo style={{ height: '30px' }} />
							</div>
						</div>
						<div className='oitis-mb-4'>
							<h4>Vaihda salasana</h4>
							<p>Saat salasanan vaihto-ohjeet sähköpostiisi.</p>
						</div>
						<Form
							className='ant-form ant-form-horizontal oitis-signin-form oitis-form-row0'
							onFinish={(e: Assoc<any>) =>
							{
								recover(e?.email);
							}}
						>
							<div
								className='ant-row ant-form-item'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control'>
									<Form.Item
										name='email'
										rules={[
											{
												required: true,
												message: 'Syötä sähköpostiosoite',
											},
										]}
									>
										<Input
											type='email'
											prefix={
												<Ikoni
													name='user'
													twoTone
													className='site-form-item-icon'
												/>
											}
											placeholder='Sähköposti'
										/>
									</Form.Item>
								</div>
							</div>
							<div
								className='ant-row ant-form-item'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control oitis-text-center'>
									<Form.Item>
										<ModernButton
											type='submit'
											color='blue'
										>
											Vaihda salasana
										</ModernButton>
									</Form.Item>
								</div>
							</div>
						</Form>
						<div style={{ marginTop: '8px', textAlign: 'right' }}>
							<a
								href='/recover'
								onClick={e =>
								{
									e.preventDefault();
									set({ view: '' });
								}}
							>
								Palaa kirjautumiseen
							</a>
						</div>
					</div>
				)) || (
					<div className='oitis-login-content'>
						{loading && (
							<Loader>
								<Ikoni
									name='spinner-third'
									twoTone
									fontSize={48}
									spin
								/>
							</Loader>
						)}
						<div
							className='oitis-login-header'
							style={{ display: 'flex' }}
						>
							<div>
								<h2>Kirjaudu</h2>
							</div>
							<div style={{ flex: 1, textAlign: 'end' }}>
								<OitisLogo style={{ height: '30px' }} />
							</div>
						</div>
						<Form
							style={{ position: 'relative' }}
							className='ant-form ant-form-horizontal oitis-signin-form oitis-form-row0'
							initialValues={{
								remember: true,
							}}
							onFinish={(e: Assoc<any>) =>
							{
								login(e?.email, e?.password);
							}}
						>
							<div
								className='ant-row ant-form-item'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control'>
									<Form.Item
										name='email'
										rules={[
											{
												required: true,
												message: 'Syötä sähköpostiosoite',
											},
										]}
									>
										<Input
											type='email'
											autoComplete='username'
											prefix={
												<Ikoni
													name='user'
													twoTone
													className='site-form-item-icon'
												/>
											}
											placeholder='Sähköposti'
										/>
									</Form.Item>
								</div>
							</div>
							<div
								className='ant-row ant-form-item'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control'>
									<Form.Item
										name='password'
										rules={[
											{
												required: true,
												message: 'Syötä salasana',
											},
										]}
									>
										<Input
											type='password'
											autoComplete='current-password'
											prefix={
												<Ikoni
													name='lock'
													twoTone
													className='site-form-item-icon'
												/>
											}
											placeholder='Salasana'
										/>
									</Form.Item>
								</div>
							</div>
							<div
								className='ant-row ant-form-item'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control'>
									<Form.Item
										name='remember'
										valuePropName='checked'
										style={{ marginBottom: '8px' }}
									>
										<Checkbox>Muista minut</Checkbox>
									</Form.Item>
								</div>
								<div
									className='ant-col ant-form-item-control'
									style={{ textAlign: 'end' }}
								>
									<Form.Item
										name='remember'
										valuePropName='checked'
										style={{ marginBottom: '8px' }}
									>
										<a
											href='/login/recover'
											onClick={e =>
											{
												e.preventDefault();
												set({ view: 'recover' });
											}}
										>
											Salasana unohtui
										</a>
									</Form.Item>
								</div>
							</div>
							<div
								className='ant-row ant-form-item oitis-text-center'
								style={{ rowGap: 0 }}
							>
								<div className='ant-col ant-form-item-control'>
									<Form.Item>
										<ModernButton
											type='submit'
											color='blue'
										>
											Kirjaudu
										</ModernButton>
									</Form.Item>
								</div>
							</div>
						</Form>
						<div style={{ marginTop: '8px', textAlign: 'right' }}>
							<a href='/register'>Luo uusi tili</a>
						</div>
					</div>
				)}
		</LoginContainer>
	);
};
