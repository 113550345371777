import _ from 'lodash';
import { Select } from 'App/MainComponents';
import { Tag } from 'App/modern/Tag';
import { Avatar } from 'App/components/OitisCard';
import { Picture } from './Picture';
import { usePost } from 'hooks/usePost';
import { useStated } from 'hooks/Hooks';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { Assoc } from 'utils/Assoc';
import { PostOptions } from 'utils/Api';

const Label = ({ table, data }: { table: string; data: Assoc<any>; }) => (
	<>
		<Avatar style={{ marginRight: '10px', width: '28px', height: '28px' }}>
			<Picture
				table={table}
				src={data?.picture}
				size={64}
			/>
		</Avatar>
		<span>{data?.name}</span>
	</>
);

type Props = {
	value: any;
	table: string;
	where?: Assoc<any>;
	placeholder?: string;
	onChange: (value: any) => void;
};

export const JoinSelect = (props: Props) =>
{
	const [state, set] = useStated({
		search: undefined as string | undefined,
	});

	const { value, table, placeholder, where, onChange } = props;
	const { search } = state;

	const conds: PostOptions = {
		path: table,
		body: {
			active: 'active',
			...(search && { name: `%${ search }%` }),
			...where,
		},
		header: {
			limit: 20,
			sort: 'name',
			dir: 'asc',
		},
	};

	const [data, status] = usePost(conds);

	return (
		<Select
			mode='multiple'
			getPopupContainer={getPopupContainer}
			loading={status === 'loading'}
			virtual
			showArrow
			labelInValue
			filterOption={false}
			style={{ width: '100%' }}
			tagRender={props =>
			{
				const { onClose, label } = props;
				return (
					<Tag
						color={'blue'}
						style={{ margin: '2px 4px 2px 0' }}
						onClick={onClose}
					>
						{label}
					</Tag>
				);
			}}
			onSearch={value =>
			{
				set({ search: value });
			}}
			onBlur={() =>
			{
				set({ search: undefined });
			}}
			placeholder={placeholder}
			onChange={(newValues, options) =>
			{
				const values = _.chain([...(value || []), ..._.map(options, (v: Assoc<any>) => v?.data)])
					.filter(v => _.find(newValues as Assoc<any>[], n => n?.value === v?.id))
					.uniqBy(f => f?.id)
					.value();
				onChange(values);
			}}
			value={_.map(value, v => ({
				data: v,
				value: v?.id,
				label: (
					<Label
						data={v}
						table={table}
					/>
				),
			}))}
			options={_.map(data?.data, (v, k) => ({
				data: v,
				value: v?.id,
				label: (
					<Label
						data={v}
						table={table}
					/>
				),
			}))}
		/>
	);
}
