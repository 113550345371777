import _ from 'lodash';
import BUILD_NUMBER from 'build_number.json';
import { Ikoni } from 'icons';
import { Picture } from 'App/components/Picture';
import { openModal } from 'App/modals/ModalPopup';
import { NavLink } from 'router';
import { setStore, useStore } from 'store';
import { styled } from 'styled';
import { DEVELOPMENT, Put } from 'utils/Api';
import { lang } from 'utils/Language';
import { NotificationButton } from './Notifications';
import { AutoFill } from './AutoFill';
import { SearchBox } from './SearchBox';
import { Wrap } from './Wrap';
import { UsersOnline } from './UsersOnline';
import { usePost } from 'hooks/usePost';
import { Avatar, DropdownMenu } from 'App/components/OitisCard';
import { clearToken } from 'utils/Token';
import { MobileOnly, DesktopOnly } from 'App/modern/Visibility';
import { Popup } from 'App/popup/Popup';
import { OitisLogo } from 'App/OitisLogo';
// import { CustomElement } from 'CustomElement';

const Container = styled.div`
	position: relative;
	margin: 0;
	background: #fff;

	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	box-shadow: none;
	height: auto;

	/* z-index: 1; */
	color: #262626;

	& > ${ Wrap } {
		/* padding: 0 12px; */

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		/* padding: 0 16px; */
	}
`;

const HeaderItem = styled.div`
	margin: 0;
	padding: 16px 8px;
	height: 100%;
	display: flex;
	align-items: center;

	:first-child {
		padding-left: 24px;
		padding-right: 12px;
	}
	:last-child {
		padding-left: 12px;
		padding-right: 24px;
	}
`;

const UserAvatar = styled.button`
	display: inline-flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;

	& > span:first-child {
		margin: 0 6px 0 12px;
	}
`;

const LogoLink = styled(NavLink)`
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	min-width: 75px;
	max-width: 100px;
	flex: 0 auto;
	margin-right: 20px;

	img {
		width: 100%;
		height: auto;
	}
`;

const DevLogo = styled.div`
	position: absolute;
	top: 75%;
	/* left: 37%; */
	right: 0%;
	text-align: right;
	white-space: nowrap;
	font-size: 10.75px;
	font-weight: 400;
`;

export const Header = () =>
{
	const logged = useStore(s => s?.logged);
	const { settings, user } = logged || {};
	const [offices] = usePost({ path: 'office' });
	if (!logged) return null;

	return (
		<Container>
			<Wrap>
				<MobileOnly>
					<HeaderItem onClick={() => setStore({ sidebar: true })}>
						<Ikoni name='bars' />
					</HeaderItem>
				</MobileOnly>

				<HeaderItem>
					<LogoLink to='/'>
						<OitisLogo style={{ height: '30px' }} />
						{user?.super === true &&
							(DEVELOPMENT ? (
								<DevLogo>Development</DevLogo>
							) : (
								<DevLogo>
									<>Build </>
									{new Date(BUILD_NUMBER?.date || 0)
										.toLocaleDateString('fi', {
											day: '2-digit',
											month: '2-digit',
											year: '2-digit',
											// hour: '2-digit',
											// minute: '2-digit',
										})
										.replace(/\D/g, '')}
								</DevLogo>
							))}
					</LogoLink>
				</HeaderItem>

				<DesktopOnly>
					<HeaderItem>
						<SearchBox />
					</HeaderItem>
					{user?.super === true && (
						<HeaderItem>
							<AutoFill
								table={'company'}
								style={{ width: 200 }}
								allowClear={false}
								placeholder={lang('company')}
								value={user?.company}
								onChange={async e =>
								{
									if (user?.company?.id === e?.id) return;

									const cloned = _.cloneDeep(logged);
									cloned.settings = {
										...cloned.settings,
										office: undefined,
									};
									cloned.super = {
										...cloned.super,
										company: e,
									};
									if (!cloned?.user) return;
									cloned.user.company = e || undefined;
									await Put({ path: 'user', body: cloned.user });
									setStore({ logged: cloned });
									// refetchAll();
								}}
							/>
						</HeaderItem>
					)}
					{offices?.data?.length > 1 && (
						<HeaderItem>
							<AutoFill
								table={'office'}
								style={{ width: 200 }}
								placeholder={lang('office')}
								value={settings?.office}
								onChange={e =>
								{
									const cloned = _.cloneDeep(logged);
									cloned.settings = {
										...cloned.settings,
										office: e || undefined,
									};
									setStore({ logged: cloned });
									// refetchAll();
								}}
							/>
						</HeaderItem>
					)}
				</DesktopOnly>

				<div style={{ flex: 1 }} />

				{/* <ModernButton>Test <Loading /></ModernButton>
					<ModernButton disabled>Test <Loading /></ModernButton>
					<ModernButton color="blue">Test <Loading /></ModernButton>
					<ModernButton color="red">Test <Loading /></ModernButton>
					<ModernButton color="yellow">Test <Loading /></ModernButton>
					<ModernButton color="green">Test <Loading /></ModernButton> */}

				{/* <CustomElement /> */}

				{user?.super === true && (
					<DesktopOnly>
						<HeaderItem>
							<UsersOnline />
						</HeaderItem>
					</DesktopOnly>
				)}

				{/* Mobiili haku, TODO */}
				{/* <li className="oitis-notify oitis-notify-search oitis-d-inline-block oitis-d-lg-none">
											<Popover
												trigger="click"
												overlayClassName="oitis-popover-horizontal"
												overlayStyle={{ maxWidth: '100%' }}
												placement="bottomRight"
												content={
														<div className="oitis-d-flex">
															<Dropdown
																getPopupContainer={getPopupContainer}
																overlay={
																		<Menu onClick={() =>
																		{
																			//
																		}}>
																			<Menu.Item key="page">Sivu</Menu.Item>
																			<Menu.Item key="schedule">Varaus</Menu.Item>
																			<Menu.Item key="resource">Resurssi</Menu.Item>
																		</Menu>
																}
															>
																<ModernButton>
																		Sivu <Icon type="down" />
																</ModernButton>
															</Dropdown>
															<SearchBox styleName="oitis-popover-search-bar"
																placeholder="Hae..."
																onChange={() =>
																{
																		//
																}} />
														</div>
												}>
												<span className="oitis-pointer oitis-d-block"><i className="icon icon-search-new" /></span>

											</Popover>
										</li> */}

				<HeaderItem>
					<NotificationButton />
				</HeaderItem>

				{/* <li className="oitis-msg">
					<Popover overlayClassName="oitis-popover-horizontal" placement="bottomRight"
						content={
						<AppNotifications user={user} />
						}
						trigger="click"
					>
						<Badge count={1} className="oitis-pointer">
						<Avatar shape="square" icon={
								<Ikoni name="Message" />
							} />
						</Badge>
					</Popover>
				</li> */}

				{/* <li className="oitis-language">
					<Popover
						overlayClassName="oitis-popover-horizontal"
						placement="bottomRight"
						content={
						<div className="oitis-popover-lang-scroll">
							<ul className="oitis-sub-popover">
								{
								_.map(['fi', 'gb', 'se', 'pl', 'fr'], (language: string, k) => (
									<li key={k}
										className="oitis-media oitis-pointer"
										onClick={(e) => {
										//  this.props.switchLanguage(language)
										}}
									>
										<i className={`flag flag-24 oitis-mr-2 flag-${language}`} />
										<span className="oitis-language-text">{String(language).toUpperCase()}</span>
									</li>
								))
								}
							</ul>
						</div>
						}
						trigger="click"
					>
						<Avatar shape="circle" className="oitis-avatar oitis-pointer"
						src={
								<i className={`flag flag-24 flag-fi`} />
							}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							background: '#fff',
						}}
						/>
					</Popover>
				</li> */}

				<HeaderItem>
					<Popup
						trigger={'click'}
						placement='bottomRight'
						content={
							<DropdownMenu>
								{Boolean(user?.offices?.[0]) && (
									<button
										onClick={async () =>
										{
											openModal({
												table: 'office',
												data: async () => user?.offices?.[0],
											});
										}}
									>
										<Ikoni name='wrench' />
										Oma toimipiste
									</button>
								)}
								<button
									onClick={async () =>
									{
										if (!user) return;
										openModal({
											table: 'user',
											data: async () => user,
										});
									}}
								>
									<Ikoni
										name='user'
										twoTone
									/>
									Oma tili
								</button>

								<hr />

								<button
									onClick={async () =>
									{
										clearToken();
										window.location.replace('/login');
									}}
								>
									<Ikoni name='power-off' />
									Kirjaudu ulos
								</button>
							</DropdownMenu>
						}
					>
						<UserAvatar>
							<span>{user?.name}</span>
							<Avatar>
								<Picture
									src={user?.company?.picture}
									table={'user'}
									size={64}
								/>
							</Avatar>
						</UserAvatar>
					</Popup>
				</HeaderItem>
			</Wrap>
		</Container>
	);
}
