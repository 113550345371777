import React from 'react';
// import hash_sum from 'hash-sum';
// import { isEqual, cloneDeep } from 'lodash';

export const useStated = <S extends {}>(initial: S): [S, (value: Partial<S>) => void] =>
{
	const [state, set] = React.useState<S>(initial);
	return [
		state,
		(value: Partial<S>): void =>
		{
			set((value = { ...state, ...value }));
			Object.assign(state, value);
		},
	];
};

const fastHash = (str: string) =>
{
	if (!str?.length) return;
	let hash = 0;
	for (let i = 0; i < str.length; i++)
	{
		hash = ((hash << 5) - hash + str.charCodeAt(i)) | 0;
	}
	return hash >>> 0;
};

type Unmount = void | (() => void);
type Destructor = Unmount | Promise<Unmount>;

export const useMount = <D extends {}>(callback: () => Destructor, deps?: D): void =>
{
	return React.useEffect(() =>
	{
		const destructor = callback();
		if (!destructor) return;
		if (destructor instanceof Function) return destructor;
		if (destructor instanceof Promise) return () => destructor?.then?.(cb => cb instanceof Function && cb?.());
	}, [fastHash(JSON.stringify(deps))]);
};
