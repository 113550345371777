import { Assoc } from 'utils/Assoc';
import { Ikoni } from 'icons';

export const SchedulePopup = ({ schedule }: { schedule?: Assoc<any>; }) =>
{
	const dateFormat: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric' };
	const timeFormat: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

	const start = new Date(schedule?.start);
	const ending = new Date(schedule?.ending);

	return (
		<>
			{(['waiting', 'failed'].includes(schedule?.payment_status) && (
				<div style={{ borderBottom: '1px solid #ffffff55', paddingBottom: '5px', marginBottom: '5px' }}>
					<Ikoni name='spinner-third' bgOpacity={0.5} spin twoTone fontSize={'1.1em'} />
					{'\u2000'}
					Verkkomaksu kesken
				</div>
			)) ||
				(schedule?.payment_status === 'paid' && (
					<div style={{ borderBottom: '1px solid #ffffff55', paddingBottom: '5px', marginBottom: '5px' }}>
						<Ikoni name='euro-sign' bgOpacity={0.5} twoTone fontSize={'1.1em'} />
						{'\u2000'}
						Maksettu verkossa
					</div>
				)) ||
				((schedule?.source === 'nettivaraus' || schedule?.online_booking === true) && (
					<div style={{ borderBottom: '1px solid #ffffff55', paddingBottom: '5px', marginBottom: '5px' }}>
						<Ikoni name='cart-shopping' bgOpacity={0.5} twoTone fontSize={'1.1em'} />
						{'\u2000'}
						Nettivaraus
					</div>
				))}
			{start.toLocaleString('fi', { ...dateFormat, ...timeFormat })}
			<> - </>
			{Boolean(start.toLocaleString('fi', dateFormat) === ending.toLocaleString('fi', dateFormat))
				? ending.toLocaleString('fi', timeFormat)
				: ending.toLocaleString('fi', { ...dateFormat, ...timeFormat })}
			<br />
			<small>{schedule?.resource?.name}</small>
			<br />
			{Boolean(schedule?.customer) && (
				<>
					<small>{schedule?.customer?.name}</small>
					<br />
				</>
			)}
			{Boolean(schedule?.customprice ?? schedule?.calcprice) && (
				<>
					<small>{schedule?.customprice ?? schedule?.calcprice} €</small>
				</>
			)}
		</>
	);
};