import _ from 'lodash';
import dayjs from 'dayjs';
import { styled } from 'styled';
import { Form, FormInstance, Input, FormItemProps } from 'App/MainComponents';
import { Lang, lang } from 'utils/Language';
import { TypedInput } from '../components/TypedInput';
import { PrivilegeTable } from './PrivilegeTable';
import { Ikoni } from 'icons';
import { AutoFill } from 'App/components/AutoFill';
import { DatePickerBorderless } from './RangePickerBorderless';
import { OrderLineList } from 'App/components/OrderLineList';
import { hasPrivilege } from 'utils/UserUtils';
import { ReactNode } from 'react';
import { getStore, FieldType } from 'store';
import { JsonTable } from './JsonTable';
import { colors } from 'App/modern/Colors';
import { Tip } from 'App/popup/Popup';
import { Assoc } from 'utils/Assoc';

const FormItemStyled = styled(Form.Item)`
	padding: 0 8px;
	margin-bottom: 8px;

	.ant-form-item-label {
		position: relative;
		padding: 0 12px 0 0;

		label {
			height: 36px;
			min-height: 36px;
			max-height: 36px;
			display: inline-flex;
			align-items: center;
			position: relative;
			max-width: 100%;
			text-align: right;
			height: auto;

			div {
				white-space: nowrap;
				span {
					white-space: normal;
				}
			}
		}
	}

	.ant-form-item-explain-error {
		opacity: 0.7;
		margin-top: 2px;
		color: ${ colors.red.active };
		transition: color 300ms ease;
		transition-property: color, opacity;
	}
`;

// export type FieldType = {
// 	type: 'uuid' | 'json' | string;
// 	default?: unknown;
// 	join?: string;
// 	flags?: Assoc<any>;
// };

type ItemProps = FormItemProps<any> & { fieldType?: FieldType; };
// type ItemProps = HTMLAttributes<HTMLInputElement> & {
// 	label: ReactNode,
// 	fieldType?: any,
// 	noStyle?: boolean,
// };

const Item = (props: ItemProps) =>
{
	const { children, hidden, label, style, className, fieldType, noStyle, ...rest } = props || {};

	if (noStyle || hidden)
	{
		const itemProps = {
			...rest,
			hidden,
			noStyle: true,
		};

		return <Form.Item {...itemProps}>{children}</Form.Item>;
	}

	return (
		<FormItemStyled
			{...{
				...rest,
				className,
				style,
				// style: { ...style, maxHeight: 'auto', background: 'red' },
				label: (
					<Tip trigger={'click'} content={<>{label}</>}>
						<div style={{ ...(fieldType?.flags?.super === true && { fontStyle: 'italic' }) }}>
							{fieldType?.flags?.super === true && (
								<>
									<Ikoni name='star' twoTone fontColor={colors.blue.normal} />
									{'\u2000'}
								</>
							)}
							<span>{label}</span>
						</div>
					</Tip>
				),
			}}
		>
			{children}
		</FormItemStyled>
	);
};

type Props = {
	name: string;
	path: any;
	table: string;
	data: Assoc<any>;
	fieldType: FieldType;
	form: FormInstance;
	children?: ReactNode;
};

export const FormItem = (props: Props) =>
{
	const { user } = getStore()?.logged || {};
	if (!user) return null;

	// console.log("Rendered");
	const { fieldType, name, path, data, table, children, form } = props;

	// const data = form?.getFieldsValue();
	const setValues = (values: Assoc<any>) =>
	{
		// form?.setFieldsValue({ ...form?.getFieldsValue(), ...values });
		form?.setFieldsValue?.(values);
	};

	if (fieldType?.flags?.cascade && name !== 'order_lines') return null;

	let hidden = false;
	if (user?.super !== true)
	{
		if (fieldType?.type === 'uuid') hidden = true;

		if (name === 'user_level' && !hasPrivilege('user', 'delete')) hidden = true;
		if (name === 'offices' && !hasPrivilege('user', 'delete')) hidden = true;
		if (name === 'users' && !hasPrivilege('office', 'delete')) hidden = true;

		if (table == 'resource' && !hasPrivilege('resource', 'delete'))
		{
			if (name === 'reservation_mode') hidden = true;
			if (name === 'category') hidden = true;
		}

		if (
			[
				'id',
				'created',
				'modified',
				'deleted',
				'status',
				'source',
				'payment_type',
				'foreign_companies',
				'contract',
				'terms',
				'option_group',
			].includes(name)
		)
			hidden = true;
	}

	if (name === 'active' && !data?.id) hidden = true;
	if (name === 'online_booking' && !data?.[name]) hidden = true;
	if (name === 'deletereason' && !data?.[name]) hidden = true;

	if (children)
	{
		return (
			<Item
				key={name}
				name={path}
				initialValue={data?.[name] ?? fieldType?.default}
				fieldType={fieldType}
				label={<Lang>{name}</Lang>}
				hidden={hidden}
				rules={[
					{
						required: Boolean(
							!fieldType?.flags?.readonly && !fieldType?.flags?.allow_any && fieldType?.default === undefined,
						),
						message: (
							<>
								<Lang>required field</Lang>: <Lang>{name}</Lang>
							</>
						),
					},
				]}
			>
				{children}
			</Item>
		);
	}

	if (fieldType?.join === 'privilege')
		return (
			<Item
				key={name}
				name={path}
				initialValue={data?.[name] ?? fieldType?.default}
				fieldType={fieldType}
				label={<Lang>{name}</Lang>}
				hidden={hidden}
				rules={[
					{
						required: Boolean(
							!fieldType?.flags?.readonly && !fieldType?.flags?.allow_any && fieldType?.default === undefined,
						),
						message: (
							<>
								<Lang>required field</Lang>: <Lang>{name}</Lang>
							</>
						),
					},
				]}
			>
				<PrivilegeTable
				// value={data?.[k]}
				// onChange={(value) => {
				//     form?.setFieldsValue({ ...formValues, [k]: value });
				// }}
				/>
			</Item>
		);

	if (fieldType?.type === 'json')
		return (
			<Item key={name} name={path} fieldType={fieldType} label={<Lang>{name}</Lang>}>
				<JsonTable />
			</Item>
		);

	if (name === 'office')
	{
		if (user?.offices?.length === 1)
		{
			hidden = true;
		}
	}

	if (table === 'schedule' && name === 'office')
	{
		if (data?.resource?.office?.id)
		{
			hidden = true;
		}
	}

	if (table === 'schedule' && name === 'order_lines')
	{
		if (!data?.resource?.id)
		{
			hidden = true;
		}
	}

	if (table === 'schedule' && name === 'start')
	{
		return (
			<Item style={{ display: 'flex' }} hidden={hidden} fieldType={fieldType} label={<>Varausaika</>}>
				<Input.Group className='ant-picker ant-picker-range' style={{ display: 'flex', maxHeight: '36px' }}>
					<Item
						name={'start'}
						initialValue={data?.start && dayjs(data?.start)?.isValid?.() ? dayjs(data?.start) : undefined}
						noStyle
						rules={[
							{
								required: Boolean(
									!fieldType?.flags?.readonly &&
									!fieldType?.flags?.allow_any &&
									fieldType?.default === undefined,
								),
								message: (
									<>
										<Lang>required field</Lang>: <Lang>start</Lang>
									</>
								),
							},
						]}
					>
						<DatePickerBorderless range={[data?.start, data?.ending]} placeholder={lang('start')} />
					</Item>
					<Ikoni name='arrow-right' />
					<Item
						name={'ending'}
						initialValue={data?.ending && dayjs(data?.ending)?.isValid?.() ? dayjs(data?.ending) : undefined}
						noStyle
						rules={[
							{
								required: Boolean(
									!fieldType?.flags?.readonly &&
									!fieldType?.flags?.allow_any &&
									fieldType?.default === undefined,
								),
								message: (
									<>
										<Lang>required field</Lang>: <Lang>ending</Lang>
									</>
								),
							},
						]}
					>
						<DatePickerBorderless
							range={[data?.start, data?.ending]}
							disabledDate={current => current && dayjs(current).isBefore(dayjs(data?.start), 'd')}
							placeholder={lang('ending')}
						/>
					</Item>
				</Input.Group>
				{/* {
					Boolean(overlap) &&
					<div style={{ marginTop: '4px', color: colors.red.normal }}>
						Päällekkäinen varaus: {overlap}
					</div>
				} */}
			</Item>
		);
	}

	if (table === 'schedule' && name === 'ending')
	{
		return null;
	}

	// Custom price reason
	if (table === 'schedule' && name === 'pricereason')
	{
		if (!_.isNumber(data?.customprice) || data?.customprice === data?.calcprice)
		{
			hidden = true;
		}
	}

	// Customer VAT-number
	if (table === 'customer' && name === 'vatnumber')
	{
		if (!data?.customer?.iscompany && !data?.iscompany)
		{
			hidden = true;
		}
	}

	// Delivery address
	if (table === 'customer' && ['delivery_address', 'delivery_postal', 'delivery_city'].includes(name))
	{
		if (!data?.customer?.delivery_different && !data?.delivery_different)
		{
			hidden = true;
		}
	}

	const InputItem = () =>
	{
		if (fieldType?.join && table === 'schedule' && name === 'office')
			return (
				<AutoFill
					table={fieldType?.join}
					placeholder={lang(name)}
					// where={{
					//     // office: formValues?.office?.id,
					//     // parents: 'null',
					// }}
					onChange={() =>
					{
						setValues({
							resource: undefined,
						});
					}}
				/>
			);

		if (fieldType?.join && table === 'schedule' && name === 'resource')
			return (
				<AutoFill
					table={fieldType?.join}
					placeholder={lang(name)}
					where={{
						// office: formValues?.office?.id,
						parents: 'null',
					}}
					onChange={e =>
					{
						setValues({
							office: e?.office,
							order_lines: undefined,
						});
					}}
				/>
			);

		if (table === 'schedule' && name === 'order_lines')
			return data?.resource?.id ? (
				<OrderLineList
					table={'resource'}
					resource={data?.resource}
					where={{
						parents: [data?.resource?.id, ..._.map(data?.order_lines, v => v?.resource)],
					}}
				/>
			) : (
				<Lang>Valitse ensin resurssi</Lang>
			);

		return <TypedInput readOnly={fieldType?.flags?.readonly} name={name} def={fieldType} table={table} />;
	};

	return (
		<>
			<Item
				name={path}
				hidden={hidden}
				fieldType={fieldType}
				label={<Lang>{name}</Lang>}
				initialValue={data?.[name] ?? fieldType?.default}
				rules={[
					{
						required: Boolean(
							!fieldType?.flags?.readonly && !fieldType?.flags?.allow_any && fieldType?.default === undefined,
						),
						message: (
							<>
								<Lang>required field</Lang>: <Lang>{name}</Lang>
							</>
						),
					},
				]}
				style={{
					...((table === 'schedule' && name === 'resource' && { order: -2 }) ||
						(table === 'schedule' && name === 'order_lines' && { order: -1 }) || { order: 1 }),
				}}
			>
				{InputItem()}
			</Item>
			{Boolean(user?.super === true && name === 'id' && data?.apikey) && (
				<Item fieldType={fieldType} label={'Avainkoodi'}>
					<Input readOnly name={'apikey'} value={data?.apikey} />
				</Item>
			)}
		</>
	);
};
