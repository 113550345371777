import { ReactNode } from 'react';
import { mobileCheck } from 'hooks/MobileCheck';
import { useMount } from 'hooks/Hooks';
import { setStore, useStore } from 'store';

export const useResizeListener = () =>
{
	// [state, set] = useStated({
	// 	mobile: false as boolean
	// });

	return useMount(() =>
	{
		const resize = () =>
		{
			// console.log('Resized:', window.innerWidth, 'x', window.innerHeight);

			const mobile = mobileCheck();
			setStore({ mobile });
		};

		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	});
}

export const DesktopOnly = ({ children }: { children: ReactNode; }) =>
{
	const mobile = useStore(s => s?.mobile);
	// console.log('[DesktopOnly] rendered');

	if (!mobile) return <>{children}</>;
	return null;
}

export const MobileOnly = ({ children }: { children: ReactNode; }) =>
{
	const mobile = useStore(s => s?.mobile);
	// console.log('[MobileOnly] rendered');

	if (mobile) return <>{children}</>;
	return null;
}
