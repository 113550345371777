import { Ikoni } from 'icons';
import { Badge } from 'App/components/Badge';
import _ from 'lodash';
import { styled } from 'styled';
import { usePost } from 'hooks/usePost';
import { Popup } from 'App/popup/Popup';
import { DesktopOnly } from 'App/modern/Visibility';
import { Tag } from 'App/modern/Tag';
import { SqlDate, ToDuration } from 'hooks/Dates';
import { ModernButton } from 'App/modern/ModernButton';
import { NavLink } from 'router';
import { Assoc } from 'utils/Assoc';

const Container = styled.div`
	max-width: 300px;
	padding: 8px 0;
	h4 {
		border-bottom: 1px solid #e8e8e8;
		padding: 4px 12px 8px 12px;
		margin: 0 0 4px 0;
	}
`;

const Row = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 4px 12px;
	color: #545454;

	> div {
		flex: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding: 0 10px;
	}
`;

export const UsersOnline = () =>
{
	// type Data = {
	// 	sources: {
	// 		[referer: string]: {
	// 			referer: string,
	// 			uniques: number,
	// 			latest: string,
	// 		};
	// 	},
	// 	total: number;
	// };

	const timeFrame = 1000 * 60 * 60 * 1;
	const time = new Date(Date.now() - timeFrame);
	const duration = ToDuration(timeFrame);
	const nowDateStr = new Date().toLocaleDateString();

	// const [visitors] = usePost<Data>({ path: 'visitors' });
	const [visitors] = usePost({
		path: 'visitor',
		body: {
			time: `>=${ SqlDate(time) }`,
		},
		header: {
			limit: 2000,
		},
	});

	const total = Number(_.uniqBy(visitors?.data, v => v?.address)?.length) || 0;

	const sources = _.chain(visitors?.data)
		.reduce((r, v, k) =>
		{
			const time = new Date(v?.time);
			const address = String(v?.address || '');
			const referer =
				String(v?.referer || '')
					.replace(/^http[s]*:[/]{2}(.*?)[:/].*/isu, '$1')
					.replace(/^www\./, '') || '(tuntematon)';

			if (!r[referer])
				r[referer] = {
					uniques: {},
					latest: 0,
				};

			r[referer].uniques[address] = true;
			r[referer].latest = Math.max(+r[referer]?.latest, +time);

			return r;
		}, {} as Assoc<any>)
		.map((v, k: string) => ({
			referer: k,
			uniques: Number(Object.keys(v?.uniques)?.length) || 0,
			latest: new Date(v?.latest)?.toJSON(),
		}))
		.sortBy(v => -new Date(v?.latest))
		.value();

	return (
		<Popup
			trigger={'click'}
			placement='bottom'
			color='#fff'
			content={
				<Container>
					<h4 style={{ display: 'flex' }}>
						<span>{Number(total || 0)} kävijää</span>
						<span style={{ marginLeft: 'auto' }}>
							<Tag color='blue'>
								{duration}{' '}
								<Ikoni
									name='clock'
									twoTone
								/>
							</Tag>
						</span>
					</h4>
					{_.map(sources || [], (v, k) =>
					{
						const latest = new Date(v?.latest);

						return (
							<Row key={v?.referer || k}>
								<Ikoni
									name='users'
									twoTone
								/>
								<div> {v?.referer || 'Tuntematon'} </div>
								<Tag color='blue'>
									{latest.toLocaleDateString() === nowDateStr && 'klo '}
									{latest.toLocaleString('fi', {
										...(latest.toLocaleDateString() !== nowDateStr && {
											day: 'numeric',
											month: 'numeric',
										}),
										hour: 'numeric',
										minute: '2-digit',
									})}
								</Tag>
								<Tag color='green'>
									<> {v?.uniques || 0} </>
									<>
										{' '}
										<Ikoni
											name='users'
											twoTone
										/>{' '}
									</>
								</Tag>
							</Row>
						);
					})}
					<Row style={{ paddingTop: '10px', justifyContent: 'right' }}>
						<NavLink to='/visitor'>Näytä tilastot</NavLink>
					</Row>
				</Container>
			}
		>
			<ModernButton>
				<Ikoni
					name='users'
					twoTone
				/>
				<span>
					{Number(total || 0)} kävijää | {duration}
				</span>

				{/* <Tag color="blue">
					{duration} <Ikoni name="clock" twoTone />
				</Tag> */}
			</ModernButton>
		</Popup>
	);
}
