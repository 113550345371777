import _ from 'lodash';
import { Ikoni } from 'icons';
import { EmptyContent } from 'App/components/EmptyIcon';
import { Divider } from 'App/modern/Divider';
import { Loading } from 'App/modern/Loading';
import { cloneElement, ReactElement, useRef } from 'react';
import { useMount, useStated } from 'hooks/Hooks';
import { colors } from './Colors';


type Props = {
	items: ReactElement[];
	total: number;
	status?: 'loading' | 'ready' | 'error';
	limit?: number;
	height?: number;
	onLoad: (off: number) => boolean;
};

export const Virtual = (props: Props) =>
{
	const [state, set] = useStated({
		offset: 0 as number,
		scrollTop: 0 as number,
		index: -1 as number,
	});

	const scrollRef = useRef<HTMLDivElement>(null);
	const itemsRef = useRef<HTMLDivElement>(null);

	const { items, total, status, onLoad } = props;
	const height = props?.height ?? 500;
	const limit = props?.limit ?? 8;

	const onScroll = () =>
	{
		const { offset } = state;
		const scrollElem = scrollRef?.current;
		if (!scrollElem) return;

		const scrollBox = scrollElem.getBoundingClientRect();
		const scroll = Math.ceil(scrollElem.scrollTop + scrollBox.height);
		set({ scrollTop: scroll });

		if (scroll < scrollElem.scrollHeight - 16) return;
		if (offset >= items?.length) return;
		if (offset >= total) return;
		if (items?.length >= total) return;

		const off = offset + limit;
		if (!onLoad(off)) return;
		set({ offset: off });
	};

	useMount(() => onScroll(), [!scrollRef?.current, items.length]);

	useMount(() =>
	{
		const scrollElem = scrollRef?.current;
		const itemsElem = itemsRef?.current;
		if (!scrollElem) return;
		if (!itemsElem) return;

		const keyDown = (e: KeyboardEvent) =>
		{
			const selectedItem = itemsElem?.children?.[state.index];
			// if (selectedItem) selectedItem.dispatchEvent(new PointerEvent('pointerover'));
			if (selectedItem) selectedItem.dispatchEvent(new FocusEvent('focus'));
			// console.log(e.key, state.index, items, items.length, selectedItem);

			if (e.key === 'Enter' && selectedItem) (selectedItem as HTMLDivElement).click();
			else if (e.key === 'ArrowDown') set({ index: _.clamp(state.index + 1, 0, items.length - 1) });
			else if (e.key === 'ArrowUp') set({ index: _.clamp(state.index - 1, 0, items.length - 1) });
			else return;
			e.preventDefault();

			const scrollBox = scrollElem.getBoundingClientRect();
			const itemBox = itemsElem?.children?.[state.index]?.getBoundingClientRect?.();
			if (!itemBox) return;
			
			console.log(scrollBox.bottom, itemBox.bottom, scrollElem.scrollTop);

			let scrollTo = 0;
			if (state.index === items.length - 1)
			{
				scrollTo = scrollElem.scrollHeight;
			} else if (itemBox?.bottom > scrollBox.bottom)
			{
				scrollTo = scrollElem.scrollTop + (itemBox.bottom - scrollBox.bottom) * 2;
			} else if (itemBox?.top < scrollBox.top)
			{
				scrollTo = scrollElem.scrollTop + (itemBox.top - scrollBox.top) * 2;
			}

			if (scrollTo)
			{
				scrollElem.scrollTo({
					top: scrollTo,
					behavior: 'smooth',
				});
			}
		};
		window.addEventListener('keydown', keyDown);
		return () =>
		{
			window.removeEventListener('keydown', keyDown);
		};
	}, [items.length, state.index]);

	const { index } = state;

	return (
		<div
			ref={scrollRef}
			style={{
				position: 'relative',
				maxHeight: height,
				overflow: 'hidden auto',
				color: '#585858',
				// paddingTop: scroll,
				// paddingBottom: scroll,
			}}
			onScroll={() => onScroll()}
		>
			<div ref={itemsRef}>
				{_.map(items, (child, k) =>
				{
					return cloneElement(child, {
						// style: {
						// 	// border: '1px solid transparent',
						// 	// transition: 'all 100ms ease',
						// 	...(index === k && {
						// 		background: '#f5f5f5',
						// 		border: `1px solid ${colors.blue.normal}`,
						// 		borderRadius: '6px',
						// 		outline: '1px solid red',
						// 	}),
						// },

						...(index === k && {
							active: true,
						}),
					});
					// <div
					// 	key={k}
					// 	// tabIndex={-1}
					// 	style={{
					// 		border: '1px solid transparent',
					// 		transition: 'all 100ms ease',
					// 		...(index === k && { background: '#f5f5f5', border: `1px solid ${colors.blue.normal}`, borderRadius: '6px' }),
					// 	}}
					// >
					// {child}
					// </div>
					// return (
					//     <VirtualChild key={k}>
					//         {child}
					//     </VirtualChild>
					// );
				})}
			</div>
			{(status === 'loading' && (
				<Divider>
					<Loading />
				</Divider>
			)) ||
				(status === 'ready' &&
					((Boolean(items?.length > limit && items?.length >= total) && (
						<Divider>
							<small>
								<Ikoni
									name='check'
									style={{ color: colors.green.normal }}
								/>{' '}
								Kaikki ladattu
							</small>
						</Divider>
					)) ||
						(Boolean(items?.length > 0 && total > items?.length) && (
							<Divider>
								<Ikoni
									name='angle-down'
									fontSize={16}
									style={{ color: '#999' }}
								/>
							</Divider>
						)) ||
						(Boolean(total === 0 && items?.length === 0) && <EmptyContent />)))}
		</div>
	);
}
