import { BaseModal } from 'App/modals/BaseModal';
import { EditModal } from 'App/modals/EditModal';
import { ScheduleModal } from 'App/modals/ScheduleModal';
import { unsavedChanges } from 'utils/ApiFunctions';
import { useMount, useStated } from 'hooks/Hooks';
import { Assoc } from 'utils/Assoc';
import { addPortal, removePortal } from 'App/components/Portal';

type ModalData = Assoc<any>;

export type ModalType = {
	table: string;
	data: ModalData;
	visible?: boolean;
	loading?: boolean;
	onOk: () => void;
	onCancel: () => void;
	onUnsaved?: () => void;
	overrideSave?: (values: ModalData, modified: ModalData) => Promise<boolean | void>;
};

type ModalOptions = {
	table: string;
	data: () => ModalData | Promise<ModalData>;
	onOk?: () => void;
	onCancel?: () => void;
	afterClose?: () => void;
	overrideSave?: (values: ModalData, modified: ModalData) => Promise<boolean | void>;
};

export const openModal = async (options: ModalOptions): Promise<boolean> =>
{
	return await new Promise((resolve: (confirm: boolean) => void) =>
	{
		const Content = () =>
		{
			const [state, set] = useStated({
				visible: true as boolean,
				loading: true as boolean,
				unsaved: false as boolean,
				data: {} as ModalData,
			});
			const { visible, loading, data } = state;

			useMount(async () =>
			{
				// console.log("Modal mounted");
				const data = await options.data();
				set({ data, loading: false });
			});

			const modal: ModalType = {
				...options,
				data,
				loading,
				onUnsaved: () =>
				{
					set({ unsaved: true });
				},
				onOk: () =>
				{
					set({ visible: false });
					return resolve(true);
				},
				onCancel: async () =>
				{
					if (state.unsaved && !(await unsavedChanges())) return;
					set({ visible: false });
					return resolve(false);
				},
			};

			return (
				<BaseModal
					width={1200}
					// loading={data === undefined}
					// confirmLoading={loading}
					// unsaved={unsaved}
					loading={loading}
					visible={visible}
					onCancel={() => modal.onCancel()}
					afterClose={() =>
					{
						// console.log("after close");
						removePortal(portal);
					}}
				>
					{(modal.table === 'schedule' && <ScheduleModal modal={modal} />) || <EditModal modal={modal} />}
				</BaseModal>
			);
		};

		const portal = addPortal(Content);
	});
};
