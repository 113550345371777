import dayjs from 'dayjs';
import { fiFI } from 'App/MainComponents';
import { DatePicker } from 'App/MainComponents';
import { SqlDate } from 'hooks/Dates';
import { getPopupContainer } from 'utils/GetPopupContainer';

type Props = {
	value?: dayjs.ConfigType;
	onChange?: (value?: string) => void;
	placeholder?: string;
	disabledDate?: (current: dayjs.Dayjs) => boolean;
	range?: [dayjs.ConfigType, dayjs.ConfigType];
};

export const DatePickerBorderless = (props: Props) =>
{
	const { value, onChange, disabledDate, range, placeholder } = props;

	const now = dayjs();
	const start = dayjs(range?.[0]);
	const ending = dayjs(range?.[1]);
	const valid = range?.[0] && range?.[1] && start?.isValid?.() && ending?.isValid?.();

	return (
		<DatePicker
			getPopupContainer={getPopupContainer}
			bordered={false}
			suffixIcon={null}
			style={{ flex: 1, paddingRight: 0 }}
			placeholder={placeholder}
			format='HH:mm DD.MM.YYYY'
			minuteStep={15}
			showTime={{ format: 'HH:mm' }}
			showNow
			disabledDate={disabledDate}
			dateRender={current =>
			{
				return (
					<div
						className={[
							'ant-picker-cell',
							(Boolean(valid && current.isSame(start, 'd')) && 'ant-picker-cell-in-view ant-picker-cell-range-start') ||
							(Boolean(valid && current.isSame(ending, 'd')) && 'ant-picker-cell-in-view ant-picker-cell-range-end') ||
							(Boolean(valid && current.isBetween(start, ending)) && 'ant-picker-cell-in-view ant-picker-cell-in-range') ||
							(Boolean(valid && current.isSameOrAfter(now, 'd')) && 'ant-picker-cell-in-view'),
						].join(' ')}
					>
						<div className='ant-picker-cell-inner'>{current.date()}</div>
					</div>
				);
			}}
			value={value && dayjs(value)?.isValid?.() ? dayjs(value) : undefined}
			onSelect={e =>
			{
				// console.log('onSelect', e);
				onChange?.(SqlDate(e));
			}}
			onChange={e =>
			{
				// console.log('onChange', e);
				onChange?.(SqlDate(e));
			}}
		/>
	);
}
