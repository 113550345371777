import { ReactNode } from 'react';
import { styled, keyframes, css } from 'styled';
import { Ikoni } from 'icons';
import { DeleteIconButton } from 'App/components/DeleteIconButton';
import { colors } from 'App/modern/Colors';
import { unsavedChanges } from 'utils/ApiFunctions';
import { useStated } from 'hooks/Hooks';

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const fadeOut = keyframes`
	0% { opacity: 1; }
	100% { opacity: 0.001; }
`;

const scaleIn = keyframes`
	0% {
		transform: scale(0.8);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
`;

const scaleOut = keyframes`
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.8);
		opacity: 0.001;
	}
`;

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: 1080;
`;

const Mask = styled.div<{ out?: boolean; }>`
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color: #ccc;
	font-size: 72px;

	display: flex;
	align-items: center;
	justify-items: center;
	place-content: center;

	opacity: 0;
	backdrop-filter: blur(0.5px);
	animation: forwards ${ p => (p?.out ? fadeOut : fadeIn) } 400ms;
	animation-timing-function: ease;

	/* animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); */
	/* animation-delay: 50ms; */
`;

const ContentOuter = styled.div`
	position: fixed;
	inset: 0;
	overflow: hidden auto;
	outline: 0;
	z-index: 1000;
	text-align: center;

	&:before {
		display: inline-block;
		width: 0;
		height: 100%;
		vertical-align: middle;
		content: '';
	}
`;

const Content = styled.div<{ out?: boolean; }>`
	position: relative;
	display: inline-block;
	margin: 4px auto;
	text-align: left;
	padding-bottom: 0;
	vertical-align: middle;
	color: #000000d9;
	width: auto;
	top: 0;

	width: 400px;
	max-width: 100%;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
	z-index: 1;
	padding: 6px 24px 16px 24px;

	& > .anticon,
	& > i {
		position: absolute;
		right: 0px;
		padding: 8px 22px;
		cursor: pointer;
		z-index: 10;
	}

	opacity: 0;
	animation: forwards ${ p => (p?.out ? scaleOut : scaleIn) } 400ms;
	/* animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); */
	/* animation-delay: 50ms; */

	animation-timing-function: ${ p => (p?.out ? 'cubic-bezier(.78, .14, .15, 0.86)' : 'cubic-bezier(.08, .82, .17, 1.00)') };
`;

const ModalHeader = styled.div`
	position: absolute;
	display: flex;
	justify-content: end;
	align-items: center;
	height: 48px;
	width: 100%;
	left: 0px;
	top: 0px;
	display: flex;
	overflow: hidden;
	border-radius: inherit;
	padding: 12px 24px;

	& > i {
		font-size: 26px;
		z-index: 1;
	}
`;

const LoadingOverlay = styled.div<{ show?: boolean; }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(0.25px);
	background: #fff8;
	z-index: 10;
	opacity: 1;
	transition: opacity 300ms ease;

	${ p =>
		!p?.show &&
		css`
			pointer-events: none !important;
			opacity: 0 !important;
		`}

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;

		& > i {
			font-size: 68px;
			line-height: 100px;
			color: ${ colors.blue.normal };
			filter: drop-shadow(0px 0px 8px #fff);
		}
	}
`;

type Props = {
	children: ReactNode;
	visible?: boolean;
	loading?: boolean;
	unsaved?: boolean;
	width?: number;

	onCancel?: () => void;
	afterClose?: () => void;
	// overrideSave?: (values: any, modified: any) => Promise<any>,
};

export const BaseModal = (props: Props) =>
{
	const { onCancel, afterClose } = props;
	const { children, visible, loading, unsaved, width } = props;
	const [{ show }, set] = useStated({ show: visible });
	const showing = visible ?? show;

	// useMount(() =>
	// {
	// 	if (!showing)
	// 	{
	// 		console.log("[BaseModal] Will unmount");
	// 		setTimeout(() => {
	// 			console.log("[BaseModal] Is unmounted?", props)
	// 		}, 2000);
	// 	}
	// }, [showing]);

	const cancel = async (): Promise<void> =>
	{
		if (unsaved && !(await unsavedChanges())) return;
		onCancel?.();
		set({ show: false });
	};

	return (
		<Container
			style={{
				// transformOrigin: "-5.5px 317px",
				...(!showing && { pointerEvents: 'none' }),
			}}
		>
			<Mask
				out={!showing}
				onAnimationEnd={() =>
				{
					if (!showing) afterClose?.();
				}}
			>
				{
					// loading && <Loading />
				}
			</Mask>

			<ContentOuter
				role='dialog'
				onPointerDown={e =>
				{
					const sourceTarget = e.target as HTMLElement;
					const sourceCurrentTarget = e.currentTarget;

					const onPointerUp = async (e: PointerEvent): Promise<void> =>
					{
						window.removeEventListener('pointerup', onPointerUp);
						if (sourceTarget !== e.target) return;
						if (sourceCurrentTarget !== e.target) return;
						await cancel();
					};
					window.addEventListener('pointerup', onPointerUp, { once: true });
				}}
			>
				<Content
					role='document'
					out={!showing}
					style={{
						width: `${ Number(width) || 400 }px`,
					}}
				>
					<ModalHeader>
						<DeleteIconButton
							name='square-xmark'
							onClick={cancel}
						/>
					</ModalHeader>

					{children}

					<LoadingOverlay show={loading}>
						<div>
							<Ikoni
								name='spinner-third'
								twoTone
								spin
							/>
						</div>
					</LoadingOverlay>
				</Content>
			</ContentOuter>
		</Container>
	);
}
