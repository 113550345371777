import { styled, css } from 'styled';

export const ModernListItem = styled.label<{ hover?: boolean; border?: boolean; }>`
	flex: 1;
	min-height: 34px;
	/* max-height: 34px; */
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	color: #545454;
	display: flex;
	padding: 5px 12px;
	cursor: pointer;
	/* pointer-events: auto!important; */
	/* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
	transition: background 0.3s ease;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	& > div {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	${ p =>
		p?.hover &&
		css`
			&:hover {
				background: #f5f5f5;
			}
			&:active:not(:disabled) {
				background-color: #e6faff;
			}
		`}

	&.active:not(:disabled) {
		background-color: #e6faff;
		font-weight: 500;
	}

	${ p =>
		p?.border &&
		css`
			border-bottom: 1px solid #e8e8e8;
			&:last-child {
				border-bottom: none;
			}
		`}

	@media screen and (max-width: 576px) {
		flex-wrap: wrap;
	}

	/* > div {
		display: flex;
		align-items: center;
		position: relative;
	} */
`;

export const ModernList = styled.div<{ border?: boolean; }>`
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	/* border: 1px solid transparent; */
	border-radius: 6px;
	min-height: 36px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

	${ p =>
		p?.border &&
		css`
			border: 1px solid #d9d9d9;

			&:not(:disabled) {
				&:hover {
					border-color: #28aaeb;
				}

				&:active,
				&:focus,
				&:focus-within {
					border-color: #28aaeb;
					box-shadow: 0 0 0 2px rgb(3 143 222 / 20%);
				}
			}
		`}
`;
