import { styled, css } from 'styled';
import { useStated } from 'hooks/Hooks';
import { BackendURL } from 'utils/Api';
import { colors } from 'App/modern/Colors';
import { Loader } from 'App/modern/Loading';
import { SystemIcon } from './SystemIcon';
import { ModernSpinner } from './ModernSpinner';

const BgImg = styled.img`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	transform: scale(1.2);
	filter: blur(10px) brightness(95%);
`;

const Img = styled.img`
	z-index: 1;
	position: relative;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	/* mix-blend-mode: multiply; */
`;

const PictureContainer = styled.div<{ ready: boolean; color: string; }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-bottom: 1px solid ${ p => p?.color }22;

	img {
		opacity: 0;
		transition: opacity 200ms ease;
		${ p =>
		p?.ready &&
		css`
				opacity: 1;
			`}
	}

	.anticon,
	i,
	svg {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		max-height: 50%;
		z-index: 1;
		color: ${ p => p?.color };

		> svg {
			width: 100%;
			height: 100%;
		}
	}

	canvas {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	small {
		opacity: 0.5;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(0deg, ${ p => p?.color }16 0%, ${ p => p?.color }04 80%);
	}
`;

type Props = {
	src?: string;
	alt?: string;
	table?: string;
	size?: number;
	className?: string;
};

export const Picture = (props: Props) =>
{
	const [state, set] = useStated({
		error: false as boolean,
		ready: false as boolean,
		bgColor: undefined as void | string,
	});

	const { src, alt, table, size } = props;
	const { error, ready, bgColor } = state;
	const url = `${ BackendURL }/thumb/?w=${ size || 400 }&u=${ src }`;

	const onLoad = (image: HTMLImageElement) =>
	{
		try
		{
			const c = document.createElement('canvas');
			const ctx = c.getContext('2d', { willReadFrequently: true });
			if (!ctx) return;

			const getPixel = (x: number, y: number) => ctx?.getImageData?.(x, y, 1, 1)?.data;
			const colors: Uint8ClampedArray[] = [];
			const size = 64;
			const offset = 2;

			const w = (c.width = size);
			const h = (c.width = size);
			ctx.clearRect(0, 0, w, h);

			ctx.drawImage(image, 0, 0, w, h);
			colors.push(getPixel(offset, offset));
			colors.push(getPixel(w - 1 - offset, offset));
			colors.push(getPixel(offset, h - 1 - offset));
			colors.push(getPixel(w - 1 - offset, h - 1 - offset));
			c.remove();

			const alpha = colors.find(v => (v?.[3] ?? 0) < 32);
			const solid = colors.find((v, k) =>
			{
				const n = (k + 1) % 4;
				if (v?.[0] !== colors[n]?.[0]) return;
				return true;
			});

			const [color] = colors;
			if (!color) return;
			if (alpha) return set({ bgColor: 'transparent' });
			if (solid) return set({ bgColor: `rgba(${ color?.join(',') })` });
		}
		catch {}
	};

	return (
		<PictureContainer
			color={colors.blue.normal}
			ready={ready}
			style={{
				...(bgColor && { background: bgColor }),
			}}
		>
			{(() =>
			{
				if (src && !error)
					return (
						<>
							{Boolean(ready && !bgColor) && (
								<BgImg
									src={url} //
									loading='lazy'
									decoding='async'
									crossOrigin='anonymous'
									alt={alt}
								/>
							)}
							<Img
								src={url}
								loading='lazy'
								decoding='async'
								crossOrigin='anonymous'
								alt={alt}
								onError={() => set({ error: true })}
								onLoad={e =>
								{
									if (ready) return;
									set({ ready: true });
									onLoad(e.currentTarget);
								}}
							/>
						</>
					);

				if (table && (!src || error)) return <SystemIcon name={table} />;
				return <ModernSpinner />;
				// if (src && loading && !error) return <ModernSpinner />;
			})()}
		</PictureContainer>
	);
};