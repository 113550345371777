import { Put } from 'utils/Api';

export default void (() =>
{
	// Collect errors
	window.addEventListener('error', e =>
	{
		try
		{
			const { colno, error, filename, lineno, message } = e;
			Put({
				path: 'errorlog',
				body: {
					href: String(window.location.href),
					msg: String(message),
					url: String(filename),
					lineNo: Number(lineno),
					columnNo: Number(colno),
					error: String(error),
				},
			});
		}
		catch (e)
		{
			console.error('Error:', e);
		}
	});
})();