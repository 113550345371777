import _ from 'lodash';
import { useStore } from 'store';
import { Row, Col, Pagination, Skeleton, Input } from 'App/MainComponents';
import { Ikoni } from 'icons';
import { Post, Put } from 'utils/Api';
import { Lang, lang } from 'utils/Language';
import { ListView } from 'App/components/ListView';
import { Item } from './Item';
import { confirmModal } from 'utils/ApiFunctions';
import { usePost } from 'hooks/usePost';
import { NavLink, useParams } from 'router';
import { hasPrivilege } from 'utils/UserUtils';
import { useStated } from 'hooks/Hooks';
import { openModal } from 'App/modals/ModalPopup';
import { Badge } from 'App/components/Badge';
import { DropdownMenu, OitisCard } from 'App/components/OitisCard';
import { Divider } from 'App/modern/Divider';
import { OitisCardHeader } from 'App/components/OitisCardHeader';
import { ModernButton } from 'App/modern/ModernButton';
import { EmptyContent } from 'App/components/EmptyIcon';
import { Popup } from 'App/popup/Popup';
import { colors } from 'App/modern/Colors';
import { errorNotification } from 'utils/ErrorNotification';
import { Assoc } from 'utils/Assoc';

type Props = {
	table: string;
};

export const Items = (props: Props) =>
{
	const { table } = props;
	const { settings, links, types } = useStore(s => s?.logged) || {};
	const urlParams: { id?: string; } = useParams() || {};

	const [state, set] = useStated({
		view: 'grid' as 'grid' | 'list',
		params: {
			limit: 10 as number,
			offset: 0 as number,
			sort: 'modified' as string,
			dir: 'desc' as 'asc' | 'desc',
		},
		search: undefined as string | undefined,
		active: 'active' as 'active' | 'inactive' | 'deleted' | '',
		...(table === 'resource' && { subresource: { id: urlParams?.id || 'null' } }),
	});

	const { view, params, subresource, active, search } = state;

	const conds = {
		path: table,
		body: {
			...(active && { active }),
			...(table === 'resource' && { parents: subresource?.id }),
			...(settings?.office && { office: settings?.office?.id }),
			...(search !== undefined && { name: `%${ search }%` }),
		},
		header: {
			limit: params?.limit,
			offset: params?.offset,
			sort: params?.sort,
			dir: params?.dir,
		},
	};

	const [data] = usePost(conds);

	const ResourceName = ({ id }: { id: string; }) =>
	{
		const [data] = usePost({ path: 'resource', body: { id } });
		if (data?.data?.length !== 1) return null;
		return <> / {data?.data?.[0]?.name}</>;
	};

	return (
		<OitisCard>
			<OitisCardHeader>
				<h2 style={{ flex: 1 }}>
					<Lang>{links?.[table]?.title || 'not found'}</Lang>
					{!!(table === 'resource' && subresource?.id && !subresource?.id?.match(/null/i)) && (
						<ResourceName id={subresource?.id} />
					)}
					{'\u2000'}
					<Badge count={data?.count} loading={data?.count === undefined} />
				</h2>

				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{data?.count > 0 && (
						<Pagination
							style={{ marginRight: '15px' }}
							showSizeChanger
							showLessItems
							jumpPrevIcon={<Ikoni name='angles-left' />}
							jumpNextIcon={<Ikoni name='angles-right' />}
							prevIcon={<Ikoni name='angle-left' />}
							nextIcon={<Ikoni name='angle-right' />}
							total={data?.count}
							pageSize={params.limit}
							current={params.offset / params.limit + 1}
							onChange={async (page: number, pageSize: number) =>
							{
								params.offset = Math.max(0, page - 1) * (pageSize || params.limit);
								params.limit = pageSize || params.limit;

								set({ params });
							}}
						/>
					)}

					<div style={{ marginRight: '15px' }}>
						<Input
							placeholder='Hae...'
							allowClear
							onChange={e =>
							{
								// onSearch(e.target.value);
								set({ search: e.target.value });
							}}
						/>
					</div>

					{Boolean(data?.count > 1 && hasPrivilege(table, 'update')) && (
						<ModernButton
							onClick={() =>
							{
								openModal({
									table,
									data: async () => _.mapValues(types?.[table], v => v?.default || undefined),
									overrideSave: async (values, modified) =>
									{
										// console.log("Modal data:", values, modified);

										if (!Object.keys(modified || {}).length)
										{
											return void (await confirmModal({
												isInfo: true,
												title: 'Massa-ajo',
												content: <>Lomakkeella ei ole muutoksia</>,
											}));
										}

										const massData = await Post({
											path: table,
											body: conds?.body,
											header: {
												limit: data?.count,
												offset: 0,
											},
										});

										const confirmed = await confirmModal({
											title: 'Vahvistus',
											content: (
												<>
													Ajetaanko seuraavat muutokset {massData?.count} riviin? <br />
													Toimintoa ei voi peruuttaa. <br />
													<hr />
													<table>
														<tbody>
															{_.map(modified, (v, k) => (
																<tr key={k}>
																	<td style={{ paddingRight: '10px', color: '#888' }}>{lang(k)}: </td>
																	<td>{String(v?.name || v || '')}</td>
																</tr>
															))}
														</tbody>
													</table>
												</>
											),
										});

										if (confirmed)
										{
											for (const k in massData?.data)
											{
												const row = {
													...massData?.data?.[Number(k)],
													...modified,
												};

												try
												{
													await Put({ path: table, body: row });
												}
												catch (e)
												{
													errorNotification(e);
												}
											}
										}

										return confirmed;
									},
								});
							}}
						>
							<Ikoni name='clone' />
							{'\u2000'}
							Massa-ajo ({data?.count})
						</ModernButton>
					)}

					{hasPrivilege(table, 'create') && (
						<ModernButton
							color='blue'
							onClick={() =>
							{
								openModal({
									table,
									data: async () => _.mapValues(types?.[table], v => v?.default || undefined),
								});
							}}
						>
							<Ikoni name='pen-line' />
							{'\u2000'}
							<Lang>create new</Lang>
						</ModernButton>
					)}

					{Boolean(types?.[table]?.parents) && (
						<NavLink to={`/${ lang(links?.[table]?.title || table, true) }`} style={{ marginRight: '15px' }}>
							<ModernButton
								color={subresource?.id !== 'null' ? 'blue' : undefined}
								onClick={() =>
								{
									set({ subresource: { id: subresource?.id === 'null' ? '!null' : 'null' } });
								}}
							>
								{subresource?.id === 'null' ? 'Näytä' : 'Piilota'}
								<> lisätuotteet</>
							</ModernButton>
						</NavLink>
					)}

					<Popup
						trigger={'click'}
						placement='bottomRight'
						content={
							<DropdownMenu>
								<button
									onClick={() =>
									{
										set({
											view: view === 'list' ? 'grid' : 'list',
										});
									}}
								>
									{view === 'grid' && (
										<>
											<Ikoni name='bars' /> Lista
										</>
									)}
									{view === 'list' && (
										<>
											<Ikoni name='grid-2' /> Ruudukko
										</>
									)}
								</button>
								<hr />
								<button
									className={active === 'deleted' ? 'active' : ''}
									style={{
										...(active === 'deleted' && { background: colors.blue.normal, color: '#fff' }),
										flexWrap: 'nowrap',
									}}
									onClick={() =>
									{
										set({ active: active === 'deleted' ? 'active' : 'deleted' });
									}}
								>
									{active === 'deleted' ? (
										<Ikoni name='square-check' twoTone fontSize={'1.3em'} />
									) : (
										<Ikoni name='square' fontSize={'1.3em'} fontWeight={100} />
									)}
									{'\u2000'}
									Näytä poistetut
								</button>

								<button
									className={active === 'inactive' ? 'active' : ''}
									style={{
										...(active === 'inactive' && { background: colors.blue.normal, color: '#fff' }),
										flexWrap: 'nowrap',
									}}
									onClick={() =>
									{
										set({ active: active === 'inactive' ? 'active' : 'inactive' });
									}}
								>
									{active === 'inactive' ? (
										<Ikoni name='square-check' twoTone fontSize={'1.3em'} />
									) : (
										<Ikoni name='square' fontSize={'1.3em'} fontWeight={100} />
									)}
									{'\u2000'}
									Näytä inaktiiviset
								</button>
							</DropdownMenu>
						}
					>
						<ModernButton>
							<Ikoni name='wrench' />
						</ModernButton>
					</Popup>

					<ModernButton
						onClick={() =>
						{
							set({
								view: view === 'list' ? 'grid' : 'list',
							});
						}}
					>
						{view === 'grid' && <Ikoni name='bars' />}
						{view === 'list' && <Ikoni name='grid-2' />}
					</ModernButton>
				</div>
			</OitisCardHeader>

			<>
				{(data?.count === undefined && <Skeleton active />) ||
					(data?.count === 0 && (
						<EmptyContent>
							{hasPrivilege(table, 'create') && (
								<ModernButton
									color='blue'
									onClick={() =>
									{
										openModal({
											table,
											data: async () => _.mapValues(types?.[table], v => v?.default || undefined),
										});
									}}
								>
									<Lang>create new</Lang>
								</ModernButton>
							)}
							{active !== '' && (
								<ModernButton
									onClick={() =>
									{
										set({ active: '' });
									}}
								>
									Näytä kaikki
								</ModernButton>
							)}
						</EmptyContent>
					)) ||
					(view === 'list' && (
						<ListView
							key='listview'
							data={data}
							table={table}
							onSelect={(record: Assoc<any>) =>
							{
								openModal({
									table,
									data: async () => record,
								});
							}}
							onChange={(_params: Assoc<any>) =>
							{
								set({ params: { ...params, ..._params } });
							}}
						/>
					)) || (
						<Row style={{ margin: '0 -8px' }}>
							{_.map(data?.data, (v, k) => (
								<Col
									key={v?.id || k}
									style={{ minWidth: '20%', padding: '0 8px', margin: 0 }}
									xs={24}
									sm={12}
									md={12}
									lg={4}
								>
									<Item
										table={table}
										data={v}
										onSettings={() =>
										{
											if (!v?.id) return;
											set({
												subresource: v as { id: string; },
											});
										}}
										onClick={async () =>
										{
											openModal({
												table,
												data: async () =>
													(
														await Post({
															path: table,
															body: { id: v?.id },
															header: { limit: 1 },
														})
													)?.data?.[0],
											});
										}}
									/>
								</Col>
							))}
						</Row>
					)}
				{data?.count > 0 && (
					<>
						<Divider />
						<Pagination
							showSizeChanger
							total={data?.count}
							pageSize={params.limit}
							current={params.offset / params.limit + 1}
							onChange={async (page: number, pageSize: number) =>
							{
								params.offset = Math.max(0, page - 1) * (pageSize || params.limit);
								params.limit = pageSize || params.limit;
								set({ params });
							}}
						/>
					</>
				)}
			</>
		</OitisCard>
	);
};