import { CSSProperties, ReactNode } from 'react';

export const Divider = ({ children, style }: { children?: ReactNode; style?: CSSProperties; }) =>
{
	return (
		<div
			role='separator'
			className='ant-divider ant-divider-horizontal ant-divider-with-text ant-divider-with-text-center ant-divider-plain'
			style={style}
		>
			<span className='ant-divider-inner-text'>{children}</span>
		</div>
	);
}
