import _ from 'lodash';
import { useStore } from 'store';
import { Redirect, Route, Switch } from 'router';
import { hasPrivilege } from 'utils/UserUtils';
import { lang } from 'utils/Language';
import { Page } from './components/Page';
import { PageLayout } from './components/PageLayout';
import { Home } from './pages/Home';
import { Items } from './pages/Items';
import { Schedules } from './pages/Schedules';
import { Visitors } from './pages/Visitors';
import { Reports } from './pages/Reports';
import { Sidebar } from './components/Sidebar';
import { PortalOut } from './components/Portal';
import { usePollUpdater } from 'utils/Polling';
import { ConfigProvider, fiFI } from './MainComponents';
import { SuperLogs } from './pages/SuperLogs';

export const Authenticated = () =>
{
	const logged = useStore(s => s?.logged) || {};
	usePollUpdater();

	return (
		<ConfigProvider locale={fiFI}>
			<Page>
				<PortalOut />
				<Sidebar />

				<Switch>
					{_.map(logged?.links || {}, (v, key) => (
						<Route
							key={key}
							path={[`/${ key }/:id?`, `/${ (lang(v?.title) || key).toLowerCase() }/:id?`]}
						>
							<PageLayout>
								{(key === 'visitor' && <Visitors />) || (key === 'schedule' && <Schedules />) || <Items table={key} />}
							</PageLayout>
						</Route>
					))}

					<Route
						key='reports'
						path={[`/reports`, `/raportit`]}
					>
						<PageLayout>
							<Reports />
						</PageLayout>
					</Route>

					{logged?.user?.super === true && (
						<Route
							key='super_logs'
							path={[`/super_logs`]}
						>
							<PageLayout>
								<SuperLogs />
							</PageLayout>
						</Route>
					)}

					<Route
						path='/'
						exact
					>
						<PageLayout>{!hasPrivilege('office', 'update') ? <Redirect to='/schedule' /> : <Home />}</PageLayout>
					</Route>

					<Route path='*'>
						<Redirect to='/' />
						{/* 
						<PageLayout table={''}>
							<NotFound />
						</PageLayout> 
					*/}
					</Route>
				</Switch>
			</Page>
		</ConfigProvider>
	);
}
