import _ from 'lodash';
import dayjs from 'dayjs';
import { useStore } from 'store';

import { Col, RangePicker, Row, Select } from 'App/MainComponents';
import { OitisCard } from 'App/components/OitisCard';
import { SqlDate } from 'hooks/Dates';
import { useStated } from 'hooks/Hooks';
import { usePost } from 'hooks/usePost';
import { ReactNode } from 'react';
import { styled } from 'styled';
import { Spinning } from 'App/modern/Loading';
import { Myynti } from 'App/charts/Myynti';
import { Suosituimmat } from 'App/charts/Suosituimmat';
import { ToimipisteetMyynti } from 'App/charts/ToimipisteetMyynti';
import { AlkavatVaraukset } from 'App/charts/AlkavatVaraukset';
import { KokonaisMyynti } from 'App/charts/KokonaisMyynti';
import { Tervetuloa } from 'App/charts/Tervetuloa';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { PostOptions } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

// const Rivi = styled.div`
// 	position: relative;
// 	display: flex;
// 	flex-wrap: wrap;
// `;

// const Kolumni = styled.div`
// 	/* display: flex; */
// 	/* flex-wrap: wrap; */
// 	min-width: 25%;
// 	max-width: 100%;
// 	padding: 0 10px;
// 	flex: auto;

// 	@media screen and (max-width: 991px) {
// 		min-width: 100%;
// 		padding: 0;
// 	}
// `;

const TopRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: -26px auto 16px;
	justify-content: end;
	padding: 0 24px;
`;

// const Column = ({ children, xs, lg, flex }: { children: ReactNode, xs?: ColSpanSize, lg?: any, flex?: any, }) =>
// {
// 	return (
// 		// <Kolumni>
// 		//     {children}
// 		// </Kolumni>

// 		<Col xs={xs} lg={lg} flex={flex}>
// 			{children}
// 		</Col>
// 	);
// };

const Column = Col;

const MetricsCard = (props: { loading: boolean; title?: ReactNode; children: ReactNode; }) =>
{
	const { loading, title, children } = props;
	return (
		<OitisCard>
			{title}
			{children}
			{loading && <Spinning />}
		</OitisCard>
	);
};

export const Home = () =>
{
	const [state, set] = useStated({
		rangeStart: dayjs().subtract(1, 'month').startOf('day'),
		rangeEnd: dayjs().endOf('day'),
		rangeLabel: '1 kk' as string,
	});

	const settings = useStore(s => s?.logged?.settings);
	const { rangeStart, rangeEnd, rangeLabel } = state;

	const scheduleConds: PostOptions = {
		path: 'schedule',
		body: {
			active: 'active',
			start: `>=${ SqlDate(rangeStart) }`,
			created: `<=${ SqlDate(rangeEnd) }`,
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			limit: 10000,
			sort: 'created',
			dir: 'asc',
			fields: {
				id: true,
				created: true,
				modified: true,
				calcprice: true,
				customprice: true,
				start: true,
				ending: true,
				status: true,
				online_booking: true,
				source: true,
				office: {
					id: true,
					name: true,
					picture: true,
				},
				resource: {
					id: true,
					name: true,
					picture: true,
				},
				customer: {
					id: true,
					name: true,
					created: true,
				},
			},
		},
	};

	const officeConds: PostOptions = {
		path: 'office',
		body: {
			// active: 'active',
			...(settings?.office && { id: settings?.office?.id }),
		},
		header: {
			limit: 200,
			fields: {
				id: true,
				name: true,
				picture: true,
			},
		},
	};

	const resourceConds: PostOptions = {
		path: 'resource',
		body: {
			// active: 'active',
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			limit: 200,
			fields: {
				id: true,
				name: true,
				picture: true,
			},
		},
	};

	const [schedules] = usePost(scheduleConds);
	const [offices] = usePost(officeConds);
	const [resources] = usePost(resourceConds);

	const spinning = schedules === undefined || offices === undefined || resources === undefined;

	return (
		<>
			<TopRow>
				{rangeLabel === 'Aikaväli' && (
					<RangePicker
						style={{
							marginRight: '12px',
						}}
						value={[rangeStart, rangeEnd]}
						onChange={values =>
						{
							set({
								rangeStart: values?.[0] || dayjs().subtract(1, 'month'),
								rangeEnd: values?.[1] || dayjs(),
							});
						}}
					/>
				)}
				<Select
					getPopupContainer={getPopupContainer}
					value={rangeLabel}
					style={{ width: '150px' }}
					onChange={(_value: unknown, option: Assoc<any>) =>
					{
						set({
							rangeStart: option?.data?.[0],
							rangeEnd: option?.data?.[1],
							rangeLabel: option?.value,
						});
						// getData();
					}}
				>
					{_.map(
						[
							{ value: 'Kuluva kk', data: [dayjs().startOf('month'), dayjs()] },
							{ value: '1 kk', data: [dayjs().subtract(1, 'month'), dayjs()] },
							{ value: '6 kk', data: [dayjs().subtract(6, 'month'), dayjs()] },
							{ value: 'Vuosi', data: [dayjs().subtract(12, 'month'), dayjs()] },
							{ value: 'Aikaväli', data: [rangeStart, rangeEnd] },
						],
						v => (
							<Select.Option
								key={v.value}
								value={v.value}
								data={v.data}
								style={{ padding: '5px 12px' }}
							>
								{v?.value}
							</Select.Option>
						),
					)}
				</Select>
			</TopRow>

			<Row>
				<Column
					xs={24}
					lg={6}
				>
					<OitisCard>
						<Tervetuloa
							spinning={spinning}
							schedules={schedules}
							rangeLabel={rangeLabel}
							rangeStart={rangeStart.toDate()}
							rangeEnd={rangeEnd.toDate()}
						/>
					</OitisCard>

					<MetricsCard
						loading={spinning}
						title={<>Kokonaismyynti</>}
					>
						<KokonaisMyynti
							schedules={schedules}
							rangeLabel={rangeLabel}
							rangeStart={rangeStart.toDate()}
							rangeEnd={rangeEnd.toDate()}
						/>
					</MetricsCard>

					{offices?.data?.length > 1 && (
						<MetricsCard
							loading={spinning}
							title={<>Toimipisteet</>}
						>
							<ToimipisteetMyynti
								schedules={schedules}
								offices={offices}
								rangeStart={rangeStart.toDate()}
								rangeEnd={rangeEnd.toDate()}
							/>
						</MetricsCard>
					)}
				</Column>

				<Column
					xs={24}
					lg={18}
				>
					<Row>
						<Column
							xs={24}
							lg={24}
						>
							<MetricsCard loading={spinning}>
								<AlkavatVaraukset schedules={schedules} />
							</MetricsCard>
						</Column>
					</Row>

					<Row>
						<Column
							xs={24}
							lg={_.filter(resources?.data, f => !f?.parents?.length)?.length > 1 ? 16 : 24}
						>
							<MetricsCard
								loading={spinning}
								title='Myynti'
							>
								<Myynti
									schedules={schedules}
									offices={offices}
									rangeStart={rangeStart.toDate()}
									rangeEnd={rangeEnd.toDate()}
								/>
							</MetricsCard>
						</Column>

						{Boolean(_.filter(resources?.data, f => !f?.parents?.length)?.length > 1) && (
							<Column
								xs={24}
								lg={8}
							>
								<MetricsCard
									loading={spinning}
									title={<>Suosituimmat ({resources?.data?.length < 10 ? resources?.data?.length : 10})</>}
								>
									<Suosituimmat
										schedules={schedules}
										rangeStart={rangeStart.toDate()}
										rangeEnd={rangeEnd.toDate()}
									/>
								</MetricsCard>
							</Column>
						)}
					</Row>
				</Column>
			</Row>
		</>
	);
}
