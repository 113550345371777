import { ReactNode } from 'react';
import { styled } from 'styled';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	h2 {
		margin-bottom: 12px;
	}
`;

type Props = {
	children?: ReactNode;
};

export const OitisCardHeader = (props: Props) =>
{
	const { children } = props;

	return <Container>{children}</Container>;
}
