import _ from 'lodash';
import dayjs from 'dayjs';
import { styled, css } from 'styled';
import { Ikoni } from 'icons';
import { DatePicker, InputNumber, Input, Switch, Select, TimePicker, Row, Col, Radio } from 'App/MainComponents';
import { Put } from 'utils/Api';
import { Lang, lang } from 'utils/Language';
import { AutoFill } from './AutoFill';
import { JoinSelect } from './JoinSelect';
import { Picture } from './Picture';
import { useStore, FieldType } from 'store';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { colors } from 'App/modern/Colors';

const StyledNumber = styled(InputNumber)`
	${ p =>
		p.readOnly &&
		css`
			outline: 0;
			border: 1px solid #d9d9d9;
			box-shadow: none;
		`}
`;

export const readFile = async (file: File): Promise<string | ArrayBuffer | null> =>
{
	return await new Promise((resolve, reject) =>
	{
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
};

const StyledSelect = styled(Select)`
	width: 100%;
	height: 100%;

	i {
		font-size: 18px !important;
		vertical-align: text-top;
	}
`;

const Uploader = styled.label`
	i {
		font-size: 32px;
	}
`;

type Props = {
	def: FieldType;
	name: string;
	value?: any;
	table: string;
	readOnly?: boolean;
	onChange?: (v: any) => void;
};

const minuteStep = 15;

export const TypedInput = (props: Props) =>
{
	const user = useStore(s => s?.logged?.user);
	const { onChange, def, name, value, table, readOnly } = props;
	// const [loading, setLoading] = useState(true);

	// useMount(() =>
	// {
	// 	setLoading(false);
	// });

	const setData = (data: any) => onChange?.(data);

	if (name === 'contract')
	{
		return (
			<Row gutter={12}>
				{/* --- TODO: Sopimuspohja upload --- */}
				{/* <Col>
					<Upload
						action={(file) =>
						{
							// `${ BackendURL }/upload`
							return '';
						}}
						onChange={(info) =>
						{
							if (info.file.status !== 'uploading')
							{
								console.log(info.file, info.fileList);
							}
							if (info.file.status === 'done')
							{
								message.success(`${ info.file.name } file uploaded successfully`);
							}
							else if (info.file.status === 'error')
							{
								message.error(`${ info.file.name } file upload failed.`);
							}
						}}
						progress={{
							strokeColor: {
								'0%': '#108ee9',
								'100%': '#87d068',
							},
							strokeWidth: 3,
							format: percent => `${ Number((percent ?? 0).toFixed(2)) }%`,
						}}
					>
						<ModernButton>
							Valitse tiedosto 
							<Ikoni name="Upload" style={{ marginLeft: '6px' }} />
						</ModernButton>
					</Upload>
				</Col> */}
				<Col flex={1}>
					{/* <Input.TextArea */}
					<Input
						readOnly={readOnly}
						disabled={readOnly}
						// autoSize={{ minRows: 1, maxRows: 8 }}
						placeholder={lang(name)}
						onChange={e => setData(e.target.value)}
						value={value}
					/>
				</Col>
			</Row>
		);
	}

	if (name === 'picture')
	{
		return (
			<Uploader className='ant-upload ant-upload-select ant-upload-select-picture-card'>
				<Picture table={table} src={value} />
				<input
					type='file'
					accept='image/*'
					style={{ display: 'none' }}
					onChange={async e =>
					{
						const file = e.target?.files?.[0];
						if (!file) return;
						const data = await readFile(file);
						const res = await Put<{ filename?: string; }>({ path: 'picture', body: { data } });
						if (!res?.filename) throw Error('Upload failed');
						setData(res?.filename);
						return '';
					}}
				/>
			</Uploader>
			// <StyledUpload
			// 	listType='picture-card'
			// 	showUploadList={false}
			// 	accept='image/*'
			// 	action={async (file) =>
			// 	{
			// 		const data = await FileReaderAsync(file);
			// 		const res = await Put<{ filename?: string; }>('picture', { data });
			// 		if (!res?.filename) throw Error('Upload failed');
			// 		setData(res?.filename);
			// 		return '';
			// 	}}
			// 	progress={{
			// 		strokeColor: {
			// 			'0%': '#108ee9',
			// 			'100%': '#87d068',
			// 		},
			// 		strokeWidth: 3,
			// 		format: percent => `${ (Number(percent) || 0).toFixed(2) }%`,
			// 	}}

			// >
			// 	<Picture table={table} src={value} />
			// </StyledUpload>
		);
	}

	if (def?.type === 'enum')
	{
		return (
			<StyledSelect
				getPopupContainer={getPopupContainer}
				placeholder={lang(name)}
				onChange={e => setData(e)}
				value={value}
			>
				{_.map(
					def?.enum,
					(v2, k2) =>
						Boolean(value === 'deleted' || v2 !== 'deleted') && (
							<Select.Option key={k2} value={v2}>
								{name === 'active' &&
									((v2 === 'active' && (
										<Ikoni name='circle-check' twoTone fontColor={colors.green.normal} bgOpacity={0.2} />
									)) ||
										(v2 === 'inactive' && (
											<Ikoni name='circle-minus' twoTone fontColor={colors.orange.normal} bgOpacity={0.2} />
										)) ||
										(v2 === 'deleted' && (
											<Ikoni name='circle-xmark' twoTone fontColor={colors.red.normal} bgOpacity={0.2} />
										)))}
								{'\u2000'}
								<Lang>{v2}</Lang>
							</Select.Option>
						),
				)}
			</StyledSelect>
		);
	}

	if (def?.type === 'row' && def?.join)
	{
		return <AutoFill table={def?.join} placeholder={lang(name)} onChange={e => setData(e)} value={value} />;
	}

	if (def?.type === 'join'&& def?.join)
	{
		return <JoinSelect table={def?.join} placeholder={lang(name)} onChange={e => setData(e)} value={value} />;
	}

	if (def?.type === 'datetime')
	{
		return (
			<DatePicker
				getPopupContainer={getPopupContainer}
				style={{ width: '100%' }}
				disabled={readOnly}
				placeholder={lang(name)}
				inputReadOnly={readOnly}
				format='HH:mm DD.MM.YYYY'
				minuteStep={minuteStep}
				showTime={{ format: 'HH:mm' }}
				showNow
				onSelect={e => setData(e?.format('YYYY-MM-DD HH:mm:00'))}
				onChange={e => setData(e?.format('YYYY-MM-DD HH:mm:00'))}
				value={value && dayjs(value)?.isValid?.() ? dayjs(value) : undefined}
			/>
		);
	}

	if (def?.type === 'date')
	{
		return (
			<DatePicker
				getPopupContainer={getPopupContainer}
				style={{ width: '100%' }}
				placeholder={lang(name)}
				disabled={readOnly}
				inputReadOnly={readOnly}
				format='DD.MM.YYYY'
				minuteStep={minuteStep}
				showToday
				onSelect={e => setData(e?.format('YYYY-MM-DD'))}
				onChange={e => setData(e?.format('YYYY-MM-DD'))}
				value={value && dayjs(value)?.isValid?.() ? dayjs(value) : undefined}
			/>
		);
	}

	if (def?.type === 'time')
	{
		return (
			<TimePicker
				getPopupContainer={getPopupContainer}
				style={{ width: '100%' }}
				disabled={readOnly}
				placeholder={lang(name)}
				inputReadOnly={readOnly}
				minuteStep={minuteStep}
				format={'HH:mm'}
				showNow
				onSelect={e => setData(dayjs(e).format('HH:mm:00'))}
				onChange={e => setData(dayjs(e).format('HH:mm:00'))}
				value={value && dayjs(value, 'HH:mm:ss')?.isValid?.() ? dayjs(value, 'HH:mm:ss') : undefined}
			/>
		);
	}

	if (def?.type === 'float' || def?.type === 'number')
	{
		return (
			<StyledNumber
				readOnly={readOnly}
				// formatter={value => `${value} €`}
				// parser={value => (value || '').replace(' €', '')}
				// step={name.includes('price') ? 0.01 : 1}
				placeholder={lang(name)}
				decimalSeparator=','
				precision={name.includes('price') ? 2 : 0}
				style={{ width: '100%' }}
				onChange={e => setData(e)}
				value={value}
			/>
		);
	}

	if (def?.type === 'int' && ( name.includes('duration')))
	{
		return (
			<InputNumber
				readOnly={readOnly}
				formatter={value =>
					`${ Math.floor(value / 60 / 24) } vrk ${ Math.floor((value / 60) % 24) } h ${ Math.floor(value % 60) } min`
				}
				parser={value =>
				{
					const matches = (value || '').match(/(.*?)vrk(.*?)h(.*?)min/);
					let val = Number(_.replace(matches?.[1] || '', /\s/g, '') || 0) * 60 * 24;
					val += Number(_.replace(matches?.[2] || '', /\s/g, '') || 0) * 60;
					val += Number(_.replace(matches?.[3] || '', /\s/g, '') || 0);
					console.log(value, val);
					return val;
				}}
				step={1}
				style={{ width: '100%' }}
				onChange={e => setData(e)}
				value={value}
			/>
		);
	}

	if (def?.type === 'int')
	{
		return (
			<InputNumber
				readOnly={readOnly}
				placeholder={lang(name)}
				precision={0}
				style={{ width: '100%' }}
				onChange={e => setData(e)}
				value={value}
			/>
		);
	}

	if (def?.type === 'boolean')
	{
		return (
			<Switch
				// readOnly={readOnly}
				disabled={readOnly}
				// checkedChildren={<Ikoni name="check" />}
				// unCheckedChildren={<Ikoni name="close" />}
				onChange={e => setData(e)}
				checked={value}
			/>
		);
	}

	if (def?.type === 'string' || def?.type === 'text')
	{
		return (
			<Input.TextArea
				readOnly={readOnly}
				disabled={readOnly}
				placeholder={lang(name)}
				autoSize={{ maxRows: def?.type === 'text' || def?.flags?.type === 'textarea' ? 8 : 1 }}
				style={{
					minWidth: '100px',
					// ...((def?.type === 'text' || def?.flags?.type === 'textarea') && { maxHeight: '200px' }),
					maxHeight: '200px',
				}}
				onChange={e => setData(e.target.value)}
				value={value}
			/>
		);
	}

	if (user?.super === true && def?.type === 'uuid')
	{
		return (
			<Input
				readOnly
				disabled={readOnly}
				placeholder={lang(name)}
				onClick={e => e.currentTarget.select()}
				onChange={e => setData(e.target.value)}
				value={value}
			/>
		);
	}

	// if (def?.type === 'json')
	//     return (
	// <Form.List
	//     initialValue={
	//         // _.map(value, (v: any, k)=>({
	//         //     title: _.toString(k),
	//         //     value: _.toString(v),
	//         // }))
	//         value
	//     }
	//     name={name}
	// >
	//     {(fields, { add, remove }) => (
	//         <>
	//             {
	//                 _.map(fields, (({ key, name, fieldKey, ...restField }) =>
	//                 (
	//                     <div key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }}>
	//                         <Form.Item
	//                             {...restField}
	//                             style={{ marginBottom: 0, width: '100%', marginRight: '8px' }}
	//                             name={[name, 'title']}
	//                             fieldKey={[fieldKey, 'title']}
	//                             rules={[{ required: true, message: 'Otsikko puuttuu' }]}
	//                         >
	//                             <TextArea placeholder="Otsikko" autoSize={{ minRows: 1, maxRows: 10}} />
	//                         </Form.Item>
	//                         <Form.Item
	//                             {...restField}
	//                             style={{ marginBottom: 0, width: '100%', marginRight: '8px' }}
	//                             name={[name, 'value']}
	//                             fieldKey={[fieldKey, 'value']}
	//                             rules={[{ required: true, message: 'Tieto puuttuu' }]}
	//                         >
	//                             <TextArea placeholder="Tieto" autoSize={{ minRows: 1, maxRows: 10 }} />
	//                         </Form.Item>
	//                         <Ikoni name="circle-xmark" style={{ marginTop: '8px' }} onClick={() => remove(name)} />
	//                     </div>
	//                 )))
	//             }
	//             <Form.Item>
	//                 <Button type="dashed" onClick={() => add()} block icon={<Ikoni name="plus" />}>
	//                     <Lang>add new</Lang>
	//                 </Button>
	//             </Form.Item>
	//         </>
	//     )}
	// </Form.List>
	//     );

	return <>Unknown: {String(def?.type)}</>;
};
