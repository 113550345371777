import { BrowserRouter, Redirect, Route, Switch } from 'router';
import { Logged, setStore, useStore } from 'store';
import { DEVELOPMENT, Post } from 'utils/Api';
import { Login } from './pages/Login';
import { Authenticated } from './Authenticated';

import { useMount, useStated } from 'hooks/Hooks';
import { getToken, clearToken } from 'utils/Token';
import { useResizeListener } from 'App/modern/Visibility';

// import 'antd/dist/antd.css';
import 'assets/css/index.css';
import 'assets/css/newcss4.css';
import 'assets/css/fontfaces.css';
import hash_sum from 'hash-sum';
import { Assoc } from 'utils/Assoc';

export const App = () =>
{
	const [{ error }, set] = useStated({ error: false });
	const user = useStore(s => s?.logged?.user);

	if (user?.super) localStorage?.removeItem?.('superToken');
	useResizeListener();

	useMount(async () =>
	{
		try
		{
			const token = getToken();
			if (!token) throw Error('No token');
			// if (user?.company?.id && user?.id === prev?.userId && (!prev?.companyId || user?.company?.id === prev?.companyId)) return;
			// if (user?.id !== prev?.user?.id && user?.company?.id !== prev?.user?.company?.id)

			const logged = await Post<Logged>({ path: 'logged' });
			if (!logged?.settings) logged.settings = {};
			// console.log('logged:', logged);

			// --- TESTI ---
			// types.user.super = true;
			// --- TESTI ---

			try
			{
				const translations = await (async () =>
				{
					const translations = await Post({
						path: 'translation',
						body: {
							lang: 'fi',
						},
						header: {
							sort: '',
							limit: 10000,
							fields: {
								lang: true,
								name: true,
								value: true,
							},
						},
					});

					// Remove duplicates
					const dups: Record<string, Assoc<any>> = {};
					(translations?.data || []).map(value =>
					{
						const { id, ...rest } = value || {};
						const hash = hash_sum(rest);
						if (!dups[hash]) dups[hash] = rest;
					});

					// const before = translations?.data?.length || 0;
					// console.log(
					// 	`Before:`,
					// 	before,
					// 	`After:`,
					// 	Object.values(translations.data).length,
					// 	`Duplicates:`,
					// 	before - Object.values(translations.data).length,
					// );

					translations.data = Object.values(dups);
					return translations;
				})();

				setStore({ translations });
			}
			catch (e)
			{
				console.error('Could not get translations');
			}

			return void setStore({ logged });
		}
		catch (e)
		{
			if (DEVELOPMENT) console.error('Error:', e);
			clearToken();
			return void set({ error: true });
		}
	}, [user?.id, user?.company?.id]);

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Switch>
				<Route path={['/login/:page?/:secret?', '/kirjaudu/:page?/:secret?']}>
					<Login />
				</Route>
				{Boolean(error) ? <Redirect to='/login' /> : Boolean(user) && <Authenticated />}
			</Switch>
		</BrowserRouter>
	);
};