import _ from 'lodash';
import { getStore, useStore } from 'store';

export const lang = (key: string, lower?: boolean): string =>
{
	let ret = _.upperFirst(key);
	const translations = getStore()?.translations?.data;

	if (translations instanceof Array)
	{
		const found: string | void = _.find(translations, f => f?.lang === 'fi' && f?.name === key)?.value;
		if (typeof found === 'string') ret = found;
	}

	if (lower) ret = ret.toLowerCase();
	return String(ret || '');
};

type Props = {
	children: string;
	lower?: boolean;
};

export const Lang = (props: Props) =>
{
	const { children, lower } = props;
	useStore(s => s?.translations?.data);
	// return lang(children, lower) as unknown as ReactElement;
	// return <>{lang(children, lower)}</>;
	return lang(children, lower) as unknown as JSX.Element;

	// return (
	//   // <Tooltip title="moro">
	//     {ret || ''}
	//   // </Tooltip>
	// );
};
