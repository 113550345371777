import { getStore, setStore, useStore } from 'store';

// const Container = styled.div`
// 	/* position: absolute; */
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	width: 100vw;
// 	height: 100%;
// 	pointer-events: none;
// 	overflow: hidden;
// 	/* z-index: 1080; */
// 	z-index: 1090;
// `;

export type PortalElement = {
	element: () => JSX.Element;
	key: number | string;
};

export const addPortal = (element: () => JSX.Element): PortalElement =>
{
	const portals = [...(getStore()?.portals || [])];
	const portal: PortalElement = { element, key: `${ Date.now() }_${ Math.random() }` };
	portals.push(portal);
	setStore({ portals });
	return portal;
}

export const removePortal = (portalElement: PortalElement) =>
{
	const portals = [...(getStore()?.portals || [])];
	portals.splice(portals.indexOf(portalElement), 1);
	setStore({ portals });
}

export const PortalOut = () =>
{
	const portals = useStore(s => s?.portals) || [];
	return (
		<>
			{portals.map(v =>
			{
				const Element = v.element;
				return <Element key={v.key} />;
			})}
		</>
	);
}

// export const portal = ({ children }: { children: ReactNode; }) =>
// {
// 	const mount = portalRef?.current;
// 	if (!mount || !children) return null;
// 	const el = document.createElement("div");

// 	useMount(() =>
// 	{
// 		mount?.appendChild(el);
// 		return () =>
// 		{
// 			// console.log("portal unmounted");
// 			mount?.removeChild(el);
// 		};
// 	}, [el, mount]);

// 	return createPortal(children, mount);
// };

// export const Portal = ({ children }: { children: ReactNode; }) =>
// {
// 	const mount = portalRef?.current;
// 	if (!mount || !children) return null;

// 	// const mount = document.getElementById("portal");
// 	// const mount = document.body;

// 	// const el = document.createElement("div");

// 	// useMount(() =>
// 	// {
// 	//     mount?.appendChild(el);
// 	//     return () =>
// 	//     {
// 	//         // console.log("portal unmounted");
// 	//         mount?.removeChild(el);
// 	//     };
// 	// }, [el, mount]);

// 	return createPortal(children, mount);
// };
