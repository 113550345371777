import _ from 'lodash';
import { Ikoni } from 'icons';
import { Lang, lang } from 'utils/Language';
import { NavLink } from 'router';
import { SystemIcon } from 'App/components/SystemIcon';
import { styled } from 'styled';
import { DropdownMenu } from 'App/components/OitisCard';
import { setStore, useStore } from 'store';
import { Wrap } from 'App/components/Wrap';
import { Popup } from 'App/popup/Popup';
import { hasPrivilege } from 'utils/UserUtils';
import { colors } from 'App/modern/Colors';
import { setToken } from 'utils/Token';

const Container = styled.div`
	background-color: ${ colors.blue.normal };
	color: #fffffd;

	& > ${ Wrap } {
		flex: 1 1 0%;
		display: flex;
		color: #fffffd;
		line-height: 45px;
		padding: 0 12px;

		& > a,
		& > button {
			background: none !important;
			outline: 0 !important;
			border: 0 !important;

			color: #fffffd !important;
			font-size: inherit;
			position: relative;
			display: block;
			margin: 0;
			white-space: nowrap;
			cursor: pointer;
			transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
			overflow: hidden;

			padding: 0 18px;
			&:last-child,
			&:first-child {
				padding: 0 12px;
			}

			&:before {
				content: '';
				position: absolute;
				width: 0px;
				height: 0px;
				left: 50%;
				bottom: 0px;
				transform-origin: 0px 100%;
				transform: translate(-50%, 100%);
				transition: transform 100ms ease-out;

				border-left: 14px solid transparent;
				border-right: 14px solid transparent;
				border-bottom: 8px solid #f5f5f5;
			}

			&.active:before,
			&:hover:before {
				transform: translate(-50%, 10%);
			}
		}
	}
`;

export const Navbar = () =>
{
	const [user, links] = useStore(s => [s?.logged?.user, s?.logged?.links]);
	if (!user) return null;
	const superToken = localStorage?.getItem?.('superToken');

	return (
		<Container>
			<Wrap>
				{_.map(links || {}, (v, k) =>
				{
					if (v?.level === 'super') return null;
					return (
						<NavLink
							key={k}
							to={`/${ (lang(links?.[k]?.title) || k).toLowerCase() }`}
						>
							<Lang>{v?.title}</Lang>
						</NavLink>
					);
				})}

				{hasPrivilege('office', 'create') && (
					<NavLink to={`/${ lang('reports').toLowerCase() }`}>
						<Lang>{'reports'}</Lang>
					</NavLink>
				)}

				<div style={{ flex: 1 }} />
				{user?.super === true && (
					<Popup
						trigger={'click'}
						placement='bottom'
						stretch='width'
						content={
							<DropdownMenu>
								{_.map(links || {}, (v, k) =>
								{
									if (v?.level !== 'super') return null;
									return (
										<NavLink
											key={k}
											to={`/${ (lang(links?.[k]?.title) || k).toLowerCase() }`}
											onClick={e =>
											{
												console.log(e);
												e?.currentTarget?.blur();
											}}
										>
											<SystemIcon name={k} />
											{'\u2000'}
											<Lang>{v?.title}</Lang>
										</NavLink>
									);
								})}
							</DropdownMenu>
						}
					>
						<button>
							<Ikoni name='star' />
							{'\u2000'}
							<Lang>super</Lang>
						</button>
					</Popup>
				)}

				{Boolean(superToken?.length) && (
					<button
						onClick={() =>
						{
							if (!superToken) return;
							setToken(superToken);
							localStorage.removeItem('superToken');
							setStore({ logged: undefined });
						}}
					>
						<Lang>super</Lang>
						{'\u2000'}
						<Ikoni
							name='right'
							filled
						/>
					</button>
				)}
			</Wrap>
		</Container>
	);
}
