import _ from 'lodash';
import { BackendURL } from './Api';
import { useMount } from 'hooks/Hooks';
import { refetch } from 'hooks/usePost';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

const EventSource = NativeEventSource ?? EventSourcePolyfill;

export const usePollUpdater = () =>
{
	return useMount(() =>
	{
		const source = new EventSource(`${ BackendURL }/poll`, {
			withCredentials: true,
		});

		// source.onopen = e => console.log('EventSource open', e);
		source.onerror = e => console.log('EventSource error', e);
		source.onmessage = ({ data }) =>
		{
			try
			{
				const events = JSON.parse(data);
				if (events instanceof Array) events.forEach(path => refetch(path));
				// console.log('Events:', events, e);
			} 
			catch (e)
			{
				console.error('EventSource error:', e);
			}
		};

		return () => source.close();
	});
};
