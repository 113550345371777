import _ from 'lodash';
import dayjs from 'dayjs';
import { useStore } from 'store';
import { useStated } from 'hooks/Hooks';
import { SqlDate } from 'hooks/Dates';
import { usePost } from 'hooks/usePost';
import { OitisCard } from 'App/components/OitisCard';
import { SchedulesHeader } from 'App/calendar/SchedulesHeader';
import { SchedulesViews } from 'App/calendar/SchedulesViews';
import { PostOptions } from 'utils/Api';

export type ScheduleViewType = 'calendar' | 'timeline' | 'list';
export type ScheduleRange = 'day' | 'week' | 'month';
export type SchedulesState = {
	start: Date;
	ending: Date;
	view: ScheduleViewType;
	range: ScheduleRange;
	show24h: boolean;
	showReserved: boolean;
	showDeleted: boolean;
	holidayInfo: string;
	sort: string;
	dir: string;
};

export const Schedules = () =>
{
	// console.log("[Schedules] render");

	const table = 'schedule';
	const { user, settings } = useStore(s => s?.logged) || {};
	const { preferences } = user || {};
	const savedRange = preferences?.['schedule.range'] || 'day';

	const [state, set] = useStated<SchedulesState>({
		view: preferences?.['schedule.view'] || 'timeline',
		range: savedRange,
		start: dayjs().startOf(savedRange).toDate(),
		ending: dayjs().endOf(savedRange).toDate(),
		show24h: preferences?.['schedule.show24h'] || false,
		showReserved: preferences?.['schedule.showReserved'] || false,
		showDeleted: false,
		holidayInfo: '',
		sort: 'modified',
		dir: 'desc',
	});

	const { view, start, ending, showDeleted, showReserved } = state;


	const resourceConds: PostOptions = {
		path: 'resource',
		body: {
			active: 'active',
			parents: 'null',
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			limit: 200,
			sort: 'name',
			dir: 'asc',
			// sort: 'ordering',
			// fields: {
			//   id: true,
			//   name: true,
			//   picture: true,
			//   pricefields: {
			//     name: true,
			//     price: true,
			//     conditions: true,
			//   },
			//   ordering: true,
			//   company: {
			//     id: true,
			//     name: true,
			//   },
			//   office: {
			//     id: true,
			//     name: true,
			//     preferences: true,
			//   },
			// },
		},
	};

	const scheduleConds: PostOptions = {
		path: table,
		body: {
			active: showDeleted ? 'deleted' : 'active',
			ending: `>=${ SqlDate(dayjs(start).subtract(1, 'hour')) }`,
			start: `<=${ SqlDate(ending) }`,
			...(settings?.office && { office: settings?.office?.id }),
		},
		header: {
			limit: 200,
			sort: 'start',
			dir: 'desc',
			// fields: {
			//   id: true,
			//   active: true,
			//   created: true,
			//   modified: true,
			//   calcprice: true,

			//   start: true,
			//   ending: true,
			//   status: true,

			//   company: { id: true, name: true, },
			//   office: { id: true, name: true, },
			//   customer: { id: true, name: true, },
			//   resource: { id: true, name: true, },

			//   customprice: true,
			//   deletereason: true,
			//   infotext: true,
			//   online_booking: true,
			//   order_lines: true,
			//   payment_amount: true,
			//   payment_started: true,
			//   payment_status: true,
			//   payment_type: true,
			//   pricereason: true,
			//   scheduletype: true,
			//   source: true,
			// },
		},
	};

	// const [schedules, resourcesData, offices] = useMultiPost(scheduleConds, resourceConds);

	const [schedules] = usePost(scheduleConds);
	const [resourcesData] = usePost(resourceConds);
	const [offices] = usePost({
		path: 'office',
		body: { active: 'active' },
	});

	// Sort resources
	if (resourcesData?.data?.length && (offices?.data?.length === 1 || settings?.office?.id))
	{
		resourcesData.data = _.sortBy(resourcesData?.data, resource =>
		{
			const resourceOrder = resource?.office?.preferences?.resourceOrder;
			const foundKey = _.findKey(resourceOrder, f => f === resource?.id);
			return 1 + Number(foundKey || 0);
		});
	}

	const resources = _.cloneDeep(resourcesData);

	// Show reserved only
	if (resourcesData?.data?.length > 1 && showReserved)
	{
		const reserved = _.filter(resourcesData?.data, v => _.find(schedules?.data, f => v?.id === f.resource?.id));
		if (reserved?.length > 0) resources.data = reserved;
	}

	const setRange = (range: ScheduleRange, add?: number) =>
	{
		const { start, show24h } = state;
		const office = settings?.office || (offices?.data?.length === 1 ? offices?.data?.[0] : undefined);

		const _start = dayjs(add !== undefined ? start : undefined)
			.add(add || 0, range)
			.startOf(range)
			.toDate();
		const _ending = dayjs(_start).endOf(range).toDate();
		let holidayInfo = '';

		const openings = office?.openings;
		const opening = _.find(openings, { weekday: dayjs(_start).weekday() });

		if (opening)
		{
			const opentime = dayjs(opening?.opentime, 'HH:mm:ss');
			const closetime = dayjs(opening?.closetime, 'HH:mm:ss');
			if (show24h)
			{
				_start.setHours(opentime.hour(), opentime.minute(), 0, 0);
				_ending.setHours(closetime.hour(), closetime.minute(), 0, 0);
			}
		}

		const holidays = office?.holidays;
		const holiday = _.find(holidays, v => dayjs(_start).isBetween(dayjs(v?.opendate), dayjs(v?.closedate), 'd', '[]'));

		if (holiday)
		{
			holidayInfo = holiday?.description;
			const opentime = dayjs(holiday?.opentime, 'HH:mm:ss');
			const closetime = dayjs(holiday?.closetime, 'HH:mm:ss');
			if (show24h)
			{
				_start.setHours(opentime.hour(), opentime.minute(), 0, 0);
				_ending.setHours(closetime.hour(), closetime.minute(), 0, 0);
			}
		}

		set({
			range,
			start: _start,
			ending: _ending,
			holidayInfo,
		});
	};

	return (
		<OitisCard>
			<SchedulesHeader
				schedules={schedules}
				offices={offices}
				resources={resourcesData}
				setRange={setRange}
				setState={(s: Partial<SchedulesState>) => set(s)}
				state={state}
			/>
			<SchedulesViews
				schedules={schedules}
				offices={offices}
				resources={resources}
				view={view}
				start={start}
				ending={ending}
			/>
		</OitisCard>
	);
}
