import { RefObject } from 'react';
import { styled } from 'styled';
import { useMount, useStated } from 'hooks/Hooks';

const Container = styled.div`
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	/* background: #ff000022; */
	z-index: 1;
	pointer-events: none;

	> div {
		height: 44px;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: #00000011;
		pointer-events: none;
		border-radius: 6px;
	}
`;

export const HoverCanvas = (props: { checker: RefObject<HTMLDivElement>; }) =>
{
	const { checker } = props;
	const [{ line }, set] = useStated({ line: -1 });

	useMount(() =>
	{
		const pointerMove = (e: PointerEvent) =>
		{
			const rect = checker.current?.getBoundingClientRect();
			if (!rect) return;

			let index = -1;
			if (e.clientY >= rect?.top && e.clientY <= rect?.bottom && e.clientX >= rect?.left && e.clientX <= rect?.right)
			{
				index = Math.floor((e.clientY - (rect?.top || 0)) / 44) + 1;
			}

			// if (line === index) return;
			set({ line: index });
		};

		const pointerLeave = (e: PointerEvent) => set({ line: -1 });

		const { current } = checker;
		current?.addEventListener('pointermove', pointerMove);
		current?.addEventListener('pointerleave', pointerLeave);

		return () =>
		{
			current?.removeEventListener('pointermove', pointerMove);
			current?.removeEventListener('pointerleave', pointerLeave);
		};
	});

	return <Container>{line > 0 && <div style={{ top: line * 44 }} />}</Container>;
}
