import { colors } from 'App/modern/Colors';
import { Ikoni } from 'icons';
import styled from 'styled-components';

export const FormTableIconButton = styled(Ikoni).attrs(p => ({
	bgOpacity: 0.25,
	fontColor: colors.blue.active,
	fontSize: 18,
	role: 'button',
	...p,
}))`
	cursor: pointer;
	padding: 4px;
	opacity: 0.7;
	transition: opacity 200ms ease;

	&:hover {
		opacity: 1;
	}
`;