import { styled, css, keyframes } from 'styled';

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(270deg);
	}
`;

const strokeSpin = keyframes`
	0% {
		stroke-dashoffset: 187;
	}
	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}
	to {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
`;

const Svg = styled.svg`
	width: 1em;
	height: 1em;
	transform-origin: center;
	animation: ${ rotate } 1.5s linear infinite;
	color: inherit;
	path {
		fill: none;
		stroke: currentColor;
		stroke-width: 4.8;
		stroke-dasharray: 187;
		stroke-dashoffset: 0;
		stroke-linecap: square;
		transform-origin: center;
		animation: ${ strokeSpin } 1.5s ease-in-out infinite;
	}
`;

export const ModernSpinner = (props: React.SVGAttributes<SVGSVGElement>) =>
{
	return (
		<Svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 48 48'
			{...props}
		>
			<path d='M24,2.4000000000000004A21.6,21.6 0 1 1 2.4000000000000004,24' />
		</Svg>
	);
};
