import Cookies from 'js-cookie';
// import { getStored } from "store";

export const setToken = (token: string): void =>
{
	localStorage?.setItem?.('token', token);
	Cookies?.set?.('token', token);
}

export const getToken = (): string | void =>
{
	// const { user, settings } = getStored()?.logged || {};
	// // console.log("getToken", user, settings);
	// if (user?.super === true && settings?.user?.apikey)
	// {
	//     // console.log("user set", settings?.user);
	//     return settings?.user?.apikey;
	// }

	// console.log(
	// 	'getToken:',
	// 	Cookies.get('token'),
	// 	localStorage.getItem('token'),
	// );

	const token = Cookies?.get?.('token') || localStorage?.getItem?.('token') || undefined;
	if (token) setToken(token);
	return token;
}

export const clearToken = () =>
{
	localStorage?.removeItem?.('token');
	Cookies?.remove?.('token');
	localStorage?.removeItem?.('superToken');
}
