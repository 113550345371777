import { Assoc } from 'utils/Assoc';
import { getToken, clearToken } from 'utils/Token';
import { refetch } from 'hooks/usePost';

const { location } = window || {};

const isLocalhost = Boolean(
	location.hostname === 'localhost' ||
	location.hostname === '[::1]' ||
	location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/),
);

export const DEVELOPMENT = process.env.NODE_ENV === 'development' || isLocalhost;
export const BackendURL = DEVELOPMENT ? 'http://localhost:5000' : 'https://hallinta.oitis.fi/endpoint';

type RequestFields = { [key: string]: boolean | RequestFields; };

export type RequestOptions = Readonly<{
	path: string;
	method: 'POST' | 'PUT';
	body?: Assoc<any>;
	header?: {
		limit?: number;
		offset?: number;
		sort?: string;
		dir?: 'desc' | 'asc';
		fields?: RequestFields;
	};
	extra?: {
		signal: AbortSignal;
	};
}>;

export const request = async (opts: RequestOptions): Promise<Response> =>
{
	const { method, path, body, extra } = opts || {};
	const { fields, ...header } = opts?.header || {};
	const token = getToken();

	const res = await fetch(`${ BackendURL }/${ path }`, {
		method,
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			...(token && { Authorization: token }),
			...(fields && { fields: JSON.stringify(fields) }),
			...(header as Record<string, string>),
		},
		body: JSON.stringify(body),
		...extra,
	});

	if (res.status === 401)
	{
		clearToken();
		if (!location?.href?.match(/login|kirjaudu/isu))
		{
			location.replace('/login');
			throw { error: 'Not authenticated' };
		}
	}

	if (res.status !== 200) throw res;
	return res;
}

export type PostOptions = Omit<RequestOptions, 'method'>;
export type PostResponse<T = Assoc<any>> = { data: T[]; count: number; };

export const Post = async <T = PostResponse>(opts: PostOptions): Promise<T> =>
{
	const { path, body, header, extra } = opts || {};
	try
	{
		const res = await request({ method: 'POST', path, body, header, extra } as RequestOptions);
		const json = await res.json();
		return json;
	}
	catch (e)
	{
		if (e instanceof Response) throw await e.json();
		throw Error(`Tapahtui virhe, yritä hetken kuluttua uudelleen`);
	}
}

export type PutOptions = Omit<RequestOptions, 'method'> & { body: Assoc<any>; };
export type PutResponse = { id: string; };

export const Put = async <T = PutResponse>(opts: PutOptions): Promise<T> =>
{
	const { path, body, header, extra } = opts || {};
	try
	{
		const res = await request({ method: 'PUT', path, body, header, extra } as RequestOptions);
		const json = await res.json();

		setTimeout(async () => refetch(path), 100);
		return json;
	}
	catch (e)
	{
		if (e instanceof Response) throw await e.json();
		throw Error(`Tapahtui virhe, yritä hetken kuluttua uudelleen`);
	}
}
