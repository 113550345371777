import { styled } from 'styled';
import { Component, CSSProperties, ReactElement, ReactNode, RefObject, useRef } from 'react';
import Trigger, { TriggerProps } from 'rc-trigger';
import { getPopupContainer } from 'utils/GetPopupContainer';
import getPlacements from 'antd/lib/_util/placements';

const getTooltipPlacements = () =>
{
	const placements = getPlacements({
		arrowPointAtCenter: false,
		autoAdjustOverflow: true,
		// autoAdjustOverflow: { adjustX: 1, adjustY: 1 },
		// verticalArrowShift: 1,
		// horizontalArrowShift: 1,
		// arrowWidth: 8,
	});

	return placements;
};

// const builtinPlacements = {
// 	left: {
// 		points: ['cr', 'cl'],
// 	},
// 	right: {
// 		points: ['cl', 'cr'],
// 	},
// 	top: {
// 		points: ['bc', 'tc'],
// 	},
// 	bottom: {
// 		points: ['tc', 'bc'],
// 	},
// 	topLeft: {
// 		points: ['bl', 'tl'],
// 	},
// 	topRight: {
// 		points: ['br', 'tr'],
// 	},
// 	bottomRight: {
// 		points: ['tr', 'br'],
// 	},
// 	bottomLeft: {
// 		points: ['tl', 'bl'],
// 	},
// };

// const onPopupAlign = (domNode: HTMLElement, align: any) =>
// {
// 	const placements: any = getTooltipPlacements();

// 	const placement = Object.keys(placements).find(
// 		key =>
// 			placements[key].points[0] === align.points[0] &&
// 			placements[key].points[1] === align.points[1],
// 	);

// 	// console.log('getTooltipPlacements:', placements);
// 	// console.log('align:', align);
// 	// console.log('placement:', placement);

// 	if (!placement) return;
// 	const rect = domNode.getBoundingClientRect();
// 	const transformOrigin = {
// 		top: '50%',
// 		left: '50%',
// 	};

// 	if (placement.includes('top') || placement.includes('Bottom'))
// 	{
// 		transformOrigin.top = `${ rect.height - align.offset[1] }px`;
// 	}
// 	else if (placement.includes('Top') || placement.includes('bottom'))
// 	{
// 		transformOrigin.top = `${ -align.offset[1] }px`;
// 	}

// 	if (placement.includes('left') || placement.includes('Right'))
// 	{
// 		transformOrigin.left = `${ rect.width - align.offset[0] }px`;
// 	}
// 	else if (placement.includes('right') || placement.includes('Left'))
// 	{
// 		transformOrigin.left = `${ -align.offset[0] }px`;
// 	}

// 	domNode.style.transformOrigin = `${ transformOrigin.left } ${ transformOrigin.top }`;
// };

const Container = styled.div`
	word-wrap: break-word;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
	background-color: rgba(0, 0, 0, 0.75);
	color: #fff;
	min-height: 32px;
	min-width: 30px;
	text-align: left;
	text-decoration: none;
	overflow: hidden;
`;

type Trigger = 'click' | 'hover' | 'contextMenu';
type Props = {
	children: ReactElement;
	content?: ReactNode;
	style?: CSSProperties;
	placement?: 'top' | 'bottom' | 'bottomRight' | 'bottomLeft' | 'topLeft' | 'topRight';
	trigger?: Trigger;
	stretch?: true | 'width' | 'minWidth';
	visible?: boolean;
	disabled?: boolean;
	className?: string;
	zIndex?: number;
	color?: string;

	// visibleChange?: (visible: boolean) => void,
	// afterVisibleChange?: (visible: boolean) => void,
	// trigger?: 'click' | 'focus' | 'hover' | 'contextMenu',
	// trigger?: TriggerAction[],
};

export const Popup = (props: Props) =>
{
	const { children, content, stretch, placement, color, style, className, visible, disabled, trigger } = props;
	const ref = useRef<Component<TriggerProps>>(null);

	if (disabled) return children;

	return (
		<Trigger
			ref={ref}
			{...(stretch && { stretch: stretch === true ? 'width' : stretch })}
			getPopupContainer={getPopupContainer}
			builtinPlacements={getTooltipPlacements()}
			popupPlacement={placement || 'top'}
			defaultPopupVisible={false}
			alignPoint={trigger === 'contextMenu' || trigger === 'hover'}
			destroyPopupOnHide
			maskClosable
			mask={false}
			action={
				// (trigger === 'click' && ['click', 'focus']) ||
				(trigger === 'click' && ['click']) || [trigger || 'hover']
			}
			// {...state.trigger !== 'hover' && {
			// 	mouseEnterDelay: 0,
			// 	focusDelay: 0
			// }}
			// mouseEnterDelay={0}
			// focusDelay={0}
			// mouseLeaveDelay={0}
			// focusDelay={0}
			// blurDelay={0}

			popup={() =>
				visible !== false && (
					<Container
						onPointerDown={e =>
						{
							if (props.trigger !== 'click') return;
							e.preventDefault();
						}}
						onClick={() =>
						{
							if (props.trigger !== 'click') return;
							const popup = ref?.current as typeof ref.current & { triggerRef: RefObject<Element>; close: () => void; };
							const elem = popup?.triggerRef?.current;

							if (popup && elem)
							{
								elem?.querySelector?.('input')?.blur?.();
								popup?.close?.();
							}
						}}
						className={className}
						style={{
							background: color,
							...style,
							// ...(!content && { display: 'none' })
							// ...(visible === false && { display: 'none' }),
							// ...(visible === false && { opacity: 0, pointerEvents: 'none' }),
							// ...(disableHover === true && { pointerEvents: 'none' }),
						}}
					>
						{content}
					</Container>
				)
			}
			popupStyle={{
				zIndex: 1000,
				position: 'absolute',
				// ...(disableHover === true && { pointerEvents: 'none' }),
				...(trigger === 'hover' && { pointerEvents: 'none' }),
			}}
			popupMotion={{
				motionName: 'ant-zoom-big-fast',
				motionDeadline: 1000,
				// removeOnLeave: true,
				// motionLeaveImmediately: true,

				// motionName: 'ant-zoom-big-fast',
				// motionName: 'ant-zoom',
				// motionName: 'ant-zoom-down',
				// motionName: 'ant-slide-up',
			}}
		// maskMotion={{
		// 	motionName: 'ant-zoom-big-fast',
		// 	motionDeadline: 1000,
		// 	removeOnLeave: true,
		// 	motionLeaveImmediately: true,
		// }}

		// onPopupAlign={onPopupAlign}
		// className={''}
		// popupClassName={''}

		// builtinPlacements={placements}
		// popupPlacement={placement}
		// ref={domRef}
		// popupAlign={align}
		// getPopupContainer={getPopupContainer}
		// onPopupVisibleChange={onVisibleChange}
		// afterPopupVisibleChange={afterVisibleChange}
		// popupTransitionName={transitionName}
		// popupAnimation={animation}
		// popupMotion={motion}
		// defaultPopupVisible={defaultVisible}
		// destroyPopupOnHide={destroyTooltip}
		// autoDestroy={autoDestroy}
		// mouseLeaveDelay={mouseLeaveDelay}
		// popupStyle={overlayStyle}
		// mouseEnterDelay={mouseEnterDelay}
		// {...extraProps}
		>
			{children}
		</Trigger>
	);

	// return (
	// 	<Triggeri
	// 		// {...(visible !== undefined && { popupVisible: visible })}
	// 		popupVisible={visible}
	// 		disableHover={disableHover}
	// 		action={trigger || 'hover'}
	// 		stretch={stretch ? (typeof stretch === 'boolean' ? 'width' : stretch) : ''}
	// 		content={
	// 			// content &&
	// 			<Container
	// 				className={className}
	// 				style={{
	// 					background: color,
	// 					...style,
	// 					// ...(!content && { display: 'none' })
	// 					// ...(visible === false && { display: 'none' }),
	// 					...(visible === false && { opacity: 0, pointerEvents: 'none' }),
	// 				}}
	// 			>
	// 				{content}
	// 			</Container>
	// 		}
	// 		// popupMotion={{
	// 		// 	// motionName: 'ant-zoom-big-fast',
	// 		// 	motionName: 'ant-zoom-big',
	// 		// 	// motionName: 'ant-zoom',
	// 		// 	// motionName: 'ant-zoom-down',
	// 		// 	// motionName: 'ant-slide-up',
	// 		// 	motionDeadline: 1000,
	// 		// 	// removeOnLeave: true,
	// 		// }}
	// 		// maskMotion={{
	// 		//     motionName: 'ant-zoom-big-fast',
	// 		//     motionDeadline: 1000,
	// 		// }}

	// 		// autoDestroy
	// 		destroyPopupOnHide
	// 		placement={placement ?? 'top'}
	// 		// builtinPlacements={getTooltipPlacements()}
	// 		// onPopupAlign={onPopupAlign}
	// 		// className={''}
	// 		// popupClassName={''}
	// 		popupStyle={{
	// 			zIndex: 1000,
	// 			position: 'absolute',
	// 			// background: '#fff',
	// 		}}
	// 	// builtinPlacements={placements}
	// 	// popupPlacement={placement}
	// 	// ref={domRef}
	// 	// popupAlign={align}
	// 	// getPopupContainer={getPopupContainer}
	// 	// onPopupVisibleChange={onVisibleChange}
	// 	// afterPopupVisibleChange={afterVisibleChange}
	// 	// popupTransitionName={transitionName}
	// 	// popupAnimation={animation}
	// 	// popupMotion={motion}
	// 	// defaultPopupVisible={defaultVisible}
	// 	// destroyPopupOnHide={destroyTooltip}
	// 	// autoDestroy={autoDestroy}
	// 	// mouseLeaveDelay={mouseLeaveDelay}
	// 	// popupStyle={overlayStyle}
	// 	// mouseEnterDelay={mouseEnterDelay}
	// 	// {...extraProps}
	// 	>
	// 		{children}
	// 	</Triggeri>

	// 	// <RcTooltip
	// 	//     // title={props?.content}
	// 	//     // visible={props?.visible}
	// 	//     visible={true}
	// 	//     trigger={props?.trigger}
	// 	//     getTooltipContainer={(e) => document.body}
	// 	//     placement={props?.placement}
	// 	//     destroyTooltipOnHide
	// 	//     overlay={props?.content}
	// 	//     overlayStyle={{
	// 	//         zIndex: props?.zIndex || 1000,
	// 	//         background: props?.color || '#fff',
	// 	//         maxWidth: '350px',
	// 	//     }}
	// 	//     // overlayInnerStyle={{ ...props?.style, overflow: "hidden" }}
	// 	//     // color={props?.color}
	// 	//     // getPopupContainer={getPopupContainer}
	// 	// >
	// 	//     {props?.children}
	// 	// </RcTooltip>
	// );
}

// export const Tip = (props: Omit<Props, 'trigger' | 'disableHover'>) =>
// (
// 	Popup({
// 		...props,
// 		trigger: 'hover',
// 		stretch: 'minWidth',
// 	})
// );

export const Tip = styled(Popup).attrs(p => ({ trigger: 'hover', ...p }))`
	padding: 6px 8px;
	background: #555d;
	&,
	* {
		pointer-events: none !important;
	}
`;

// export const Tip: typeof Popup = (props: Props) => (
// 	<Popup
// 		style={{
// 			display: 'flex',
// 			alignItems: 'center',
// 			padding: '6px 8px',
// 			pointerEvents: 'none',
// 		}}
// 		{...props}
// 	/>
// );

// export const Popup = (props: Props) =>
// {
//     const { children, content, visible, style, color } = props;
//     const [state, set] = useStated<State>({
//         show: false,
//         hover: false,
//     });

//     const handle = useRef<HTMLDivElement>(null);
//     const popup = useRef<HTMLDivElement>(null);
//     const { show, hover } = state;
//     const { x, y, handleHeight, popupHeight } = state;

//     useMount(() =>
//     {
//         if (!handle?.current) return;
//         if (!popup?.current) return;

//         const rectHandle = handle.current.getBoundingClientRect();
//         const rectPopup = popup.current.getBoundingClientRect();

//         set({
//             x: rectHandle.x + (rectHandle.width / 2),
//             y: rectHandle.y,
//             handleHeight: rectHandle.height,
//             popupHeight: rectPopup.height / 0.8,
//             ...((hover || visible) && { show: true }),
//         });

//     }, [hover, visible]);

//     const top = (y ?? 0) + (handleHeight ?? 0);
//     // if (top < 0) top = 0;

//     let placementTop = true;
//     if (top + (popupHeight ?? 0) > window.innerHeight) placementTop = true;
//     if (top - (popupHeight ?? 0) < 0) placementTop = false;

//     const shouldHide = (show && !visible && !hover);

//     // console.log({ y, popupHeight, handleHeight, window: window.innerHeight });
//     console.log({ hover });

//     return (
//         <>
//             {
//                 cloneElement(children, {
//                     ref: handle,
//                     onPointerEnter: () => set({ hover: true }),
//                     onPointerLeave: () => set({ hover: false }),
//                 })
//             }
//             {
//                 visible !== false &&
//                 Boolean(visible || hover || show) &&
//                 <Portal>
//                     {/* <Mask /> */}
//                     <Container
//                         ref={popup}
//                         onAnimationStart={() =>
//                         {
//                             console.log('onAnimationStart');
//                         }}
//                         onAnimationIteration={() =>
//                         {
//                             console.log('onAnimationIteration');
//                             // console.log('onAnimationIteration', { state, popup });
//                             if (shouldHide) set({ show: false });
//                         }}
//                         // onAnimationEnd={() =>
//                         // {
//                         //     // console.log('onAnimationEnd', { state, popup });
//                         //     if (shouldHide) set({ show: false });
//                         // }}
//                         // onTransitionEnd={() =>
//                         // {
//                         //     // console.log('onTransitionEnd', { state, popup });
//                         //     if (shouldHide) set({ show: false });
//                         // }}
//                         top={placementTop}
//                         color={color}
//                         style={{
//                             left: x,
//                             ...(!shouldHide && { animation: 'none' }),
//                             ...(
//                                 placementTop ?
//                                     { top: top - (handleHeight ?? 0) + window.scrollY, transform: 'translateY(-100%)' } :
//                                     { top: top + window.scrollY }
//                             ),
//                         }}

//                     >
//                         <div
//                             role="tooltip"
//                             className="content"

//                             onPointerEnter={() =>
//                             {
//                                 if (show) set({ hover: true });
//                             }}
//                             onPointerLeave={() =>
//                             {
//                                 console.log("popup pointerLeave");
//                                 set({ hover: false });
//                             }}

//                             style={{
//                                 ...style,
//                                 backgroundColor: color,
//                                 opacity: ((hover && show) ? 1 : 0),
//                                 transform: `scale(${ (hover && show) ? 0.999 : 0.8 }) translateX(-50%)`,
//                                 pointerEvents: (!show) ? 'none' : 'auto',
//                             }}
//                         >
//                             {content}
//                         </div>
//                     </Container>
//                 </Portal>
//             }
//         </>
//     );
// };
