import _ from 'lodash';
import { styled } from 'styled';
import { useMount, useStated } from 'hooks/Hooks';

const TextInput = styled.input`
	position: absolute;
	padding: 0 12px;
	outline: 0;
	border: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: none;
	z-index: 1;
	transition: background 0.3s ease;

	&:focus,
	&:active {
		background: #fffa;
	}
`;

type Props =
	// HTMLAttributes<HTMLInputElement> &
	{
		value?: string;
		timeout?: number;
		onBounce?: (v: string) => void;
		onType?: (v: string) => void;

		onFocus?: () => void;
		onBlur?: () => void;
	};

export const DebounceInput = (props: Props) =>
{
	const timeout = props?.timeout ?? 100;
	const { value, onBounce, onType, onFocus, onBlur } = props;
	const [state, set] = useStated({ cached: undefined as string | void });

	const { cached } = state;
	const text = cached ?? value ?? '';

	const change = () =>
	{
		onBounce?.(text);
		set({ cached: undefined });
	};

	useMount(() =>
	{
		if (timeout === 0) return change();
		const debounce = _.debounce(() => change(), timeout);
		debounce();
		return () => debounce?.cancel?.();
	}, [cached, timeout]);

	return (
		<TextInput
			// {...props}
			type='text'
			style={{ ...(text && { background: '#fff' }) }}
			onDragStart={e => e.preventDefault()}
			// onDrag={(e) => e.preventDefault()}

			value={text}
			onChange={e =>
			{
				const v = e.target.value;
				set({ cached: v });
				onType?.(v);
			}}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	);
}
