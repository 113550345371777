import _ from 'lodash';
import dayjs from 'dayjs';
import {styled} from 'styled';
import { Assoc } from 'utils/Assoc';
import { usePost } from 'hooks/usePost';
import { lang, Lang } from 'utils/Language';
import { DatePicker, Select, Skeleton, Table } from 'App/MainComponents';
import { LocaleDate, SqlDate, ToCurrency } from 'hooks/Dates';
import { useMount, useStated } from 'hooks/Hooks';
import { OitisCard } from 'App/components/OitisCard';
import { Divider } from 'App/modern/Divider';
import { EmptyContent } from 'App/components/EmptyIcon';
import { openModal } from 'App/modals/ModalPopup';
import { Post, PostOptions } from 'utils/Api';
import { ModernButton } from 'App/modern/ModernButton';
import { Ikoni } from 'icons';
import { OitisCardHeader } from 'App/components/OitisCardHeader';
import { Badge } from 'App/components/Badge';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { Inspector } from 'react-inspector';
import { ReactNode } from 'react';
// import ReactJson from 'react-json-view';

const Inspect = styled.div`
	width: 100%;
	height: 100%;

	&,
	* {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
			sans-serif;
		font-size: 13px;
	}

	/* &, * { */
	/* font-size: 14px!important; */
	/* } */
`;

const Row = styled.div`
	display: flex;
	/* border-bottom: 1px solid #0001; */
	/* padding: 0 0 2px 8px; */
`;

const Key = styled.div`
	font-weight: bold;
`;

export const SuperLogs = () =>
{
	const [{ data }, set] = useStated({
		data: undefined as void | Assoc<any>,
		// search: '',
	});

	useMount(async () =>
	{
		const data = await Post({
			path: 'super_logs',
			body: Object.fromEntries(new URLSearchParams(window.location.search)),
		});
		set({ data });
	});

	const recur = (data: Assoc<any>, depth = 0) =>
	{
		const arr: ReactNode[] = [];
		Object.keys(data || {}).map(k =>
		{
			const v = data[k];
			if (!v) return;

			if (typeof v !== 'object')
			{
				return arr.push(
					<Row key={k}>
						<Key>{lang(k)}: </Key>
						<div style={{ padding: '0 0 2px 8px' }}>{v}</div>
					</Row>,
				);
			}

			return arr.push(
				<Row
					key={k}
					style={{ display: 'flex' }}
				>
					<Key>{lang(k)}: </Key>
					<div style={{ padding: '0 0 4px 8px' }}>
						{
							// depth > 2 ? <>[Object]</> :
							recur(v, depth + 1)
						}
					</div>
				</Row>,
			);
		});
		return arr;
	};

	return (
		<OitisCard>
			{/* <div>
			<Search
				value={search}
				onChange={e =>
				{
					const { value } = e.target;
					set({ search: value });
				}}
			/>
			</div> */}

			<div>
				Rows: {data?.data?.length} / {data?.count}
			</div>
			{/* <Code
				value={JSON.stringify(data?.data, null, '\t')}
			/>  */}
			<Inspect>
				{/* <Inspector table={false} data={data?.data} /> */}
				{recur(data?.data)}
			</Inspect>
		</OitisCard>
	);
}

// export const SuperLogs = () =>
// {
// 	const [state, set] = useStated({
// 		searchConds: {
// 			created: dayjs().subtract(6, 'month').startOf('day'),
// 		} as Assoc<any>,
// 		sorting: {
// 			sort: 'created',
// 			dir: 'desc' as 'asc' | 'desc' | undefined,
// 		},
// 	});

// 	const { searchConds, sorting } = state;

// 	const conds: PostOptions = {
// 		path: 'schedule',
// 		body: {
// 			// active: (showDeleted ? 'deleted' : '!deleted'),
// 			// status: '!cancelled',
// 			active: 'active',
// 			...searchConds,
// 			created: '>=' + SqlDate(searchConds?.created),
// 			...(searchConds?.online_booking !== undefined && {
// 				online_booking: (searchConds?.online_booking ? '1' : '0'),
// 			}),
// 		},
// 		header: {
// 			limit: 5000,
// 			sort: sorting.sort || 'created',
// 			dir: sorting.dir || 'desc',
// 		},
// 	};

// 	const [data] = usePost(conds);

// 	const generateCSV = () =>
// 	{
// 		const dataRows = _.reduce(data?.data, (r, row) =>
// 		{
// 			r.push({
// 				'Luotu': LocaleDate(row.created) || '',
// 				'Aloitusaika': LocaleDate(row.start) || '',
// 				'Päättymisaika': LocaleDate(row.ending) || '',
// 				'Toimipiste': row.office?.name || '',
// 				'Tuote': row.resource?.name || '',
// 				'Nettivaraus': row.online_booking ? 'Kyllä' : 'Ei',
// 				'Ohjehinta': Number(row.calcprice) || '',
// 				'Poikkeava hinta': Number(row.customprice) || '',
// 				'Hinta': Number(row.customprice ?? row.calcprice) || '',
// 				'Maksettu verkossa': Number(row.payment_amount) || '',
// 			});
// 			return r;
// 		}, [] as Assoc<any>[]);

// 		const csvRows = [];
// 		csvRows.push(Object.keys(dataRows?.[0] || {}).map(v => `"${ v }"`).join(','));
// 		for (const row of dataRows)
// 		{
// 			csvRows.push(Object.values(row || {}).map(v =>
// 			{
// 				if (typeof v === 'number') return Number(v).toFixed(2);
// 				return `"${ v }"`;
// 			}).join(','));
// 		}

// 		const csv = csvRows.join('\n');
// 		const blob = new Blob([csv], { type: "text/plain" });
// 		const url = URL.createObjectURL(blob);

// 		const link = document.createElement("a");
// 		link.href = url;
// 		link.download = `Raportti_${ dayjs().format('HH_mm DD_MM_YYYY') }.csv`;
// 		link.style.display = 'none';
// 		document.body.appendChild(link);

// 		link.click();
// 		URL.revokeObjectURL(url);
// 		link.remove();
// 	};

// 	return (
// 		<OitisCard>
// 			<OitisCardHeader>
// 				<h2 style={{ flex: 1 }}>
// 					<Lang>{'reports'}</Lang>
// 					{'\u2000'}
// 					<Badge
// 						count={data?.count}
// 						loading={data?.count === undefined}
// 					/>
// 				</h2>

// 				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
// 					<Select
// 						getPopupContainer={getPopupContainer}
// 						// suffixIcon={<Ikoni name="euro-sign" />}
// 						defaultValue={'Kaikki maksutavat'}
// 						style={{ width: '175px', margin: '0px 8px 8px 0px' }}
// 						onChange={(_value, option: Assoc<any>) =>
// 						{
// 							for (const k in option.data) searchConds[k] = option.data[k];
// 							set({ searchConds });
// 						}}
// 					>
// 						{
// 							_.map([
// 								// { value: 'Kaikki', data: { payment_type: undefined } },
// 								// { value: 'Kassamaksu', data: { payment_type: 'cashier' } },
// 								// { value: 'Verkkomaksu', data: { payment_type: 'epayment' } },

// 								{ value: 'Kaikki maksutavat', data: { payment_status: undefined } },
// 								{ value: 'Kassamaksu', data: { payment_status: 'none' } },
// 								{ value: 'Verkkomaksu', data: { payment_status: 'paid' } },
// 							], v =>
// 							(
// 								<Select.Option key={v.value} value={v.value} data={v.data}>
// 									{v?.value}
// 								</Select.Option>
// 							))
// 						}
// 					</Select>

// 					<Select
// 						getPopupContainer={getPopupContainer}
// 						// suffixIcon={<Ikoni name="calendar-check" />}
// 						defaultValue={'Kaikki varaustyypit'}
// 						style={{ width: '175px', margin: '0px 8px 8px 0px' }}
// 						onChange={(_value, option: Assoc<any>) =>
// 						{
// 							for (const k in option.data) searchConds[k] = option.data[k];
// 							set({ searchConds });
// 						}}
// 					>
// 						{
// 							_.map([
// 								{ value: 'Kaikki varaustyypit', data: { online_booking: undefined } },
// 								{ value: 'Nettivaraus', data: { online_booking: true } },
// 								{ value: 'Kassavaraus', data: { online_booking: false } },
// 							], (v, k) =>
// 							(
// 								<Select.Option key={k} value={v.value} data={v.data}>
// 									{v?.value}
// 								</Select.Option>
// 							))
// 						}
// 					</Select>

// 					{/* <DatePicker.RangePicker */}
// 					{/* <Tip content='Alku pvm.'> */}
// 					{/* <div> */}
// 					{/* <DatePicker
// 						value={dayjs(searchConds?.created)}
// 						format='DD.MM.YYYY'
// 						showToday={true}
// 						disabledDate={(d) => d.isAfter(new Date(), 'd')}
// 						style={{ margin: '0px 8px 8px 0px' }}
// 						onChange={(d) =>
// 						{
// 							if (!d)
// 							{
// 								searchConds.created = dayjs().subtract(6, 'month').startOf('day');
// 								set({ searchConds });
// 								return;
// 							}

// 							const created = dayjs(+d).startOf('day');
// 							// console.log("set date:", created.toLocaleString('fi'));
// 							searchConds.created = created;
// 							set({ searchConds });
// 						}}
// 					/> */}
// 					{/* </div> */}
// 					{/* </Tip> */}

// 					<DatePicker
// 						value={dayjs(searchConds?.created)}
// 						format='DD.MM.YYYY'
// 						showToday={true}
// 						disabledDate={(d) => d.isAfter(new Date(), 'd')}
// 						style={{ margin: '0 8px 8px 0' }}
// 						onChange={(d) =>
// 						{
// 							if (!d)
// 							{
// 								searchConds.created = dayjs().subtract(6, 'month').startOf('day');
// 								set({ searchConds });
// 								return;
// 							}

// 							const created = dayjs(+d).startOf('day');
// 							searchConds.created = created;
// 							set({ searchConds });
// 						}}
// 					/>
// 					<Select
// 						getPopupContainer={getPopupContainer}
// 						// suffixIcon={<Ikoni name="calendar" />}
// 						defaultValue={'6 kk'}
// 						style={{ width: '150px', margin: '0px 8px 8px 0px' }}
// 						onChange={(_value, option: Assoc<any>) =>
// 						{
// 							searchConds.created = option?.data?.[0];
// 							if (!searchConds.created)
// 							{
// 								searchConds.created = dayjs().subtract(6, 'month').startOf('day');
// 							}
// 							set({ searchConds });
// 						}}
// 					>
// 						{
// 							_.map([
// 								{ value: 'Kuluva kk', data: [dayjs().startOf('month'), dayjs()] },
// 								{ value: '1 kk', data: [dayjs().subtract(1, 'month'), dayjs()] },
// 								{ value: '6 kk', data: [dayjs().subtract(6, 'month'), dayjs()] },
// 								{ value: 'Vuosi', data: [dayjs().subtract(12, 'month'), dayjs()] },
// 								// { value: 'Aikaväli', data: [rangeStart, rangeEnd] },
// 							], v =>
// 							(
// 								<Select.Option key={v.value} value={v.value} data={v.data} style={{ padding: '5px 12px' }}>
// 									{v?.value}
// 								</Select.Option>
// 							))
// 						}
// 					</Select>

// 					<ModernButton
// 						onClick={() => generateCSV()}
// 					>
// 						<span>Lataa CSV</span>
// 						<Ikoni name="download" twoTone />
// 					</ModernButton>
// 				</div>

// 			</OitisCardHeader>

// 			<>
// 				{
// 					(
// 						data?.count === undefined &&
// 						<Skeleton active />
// 					)
// 					||
// 					(
// 						data?.count === 0 &&
// 						<EmptyContent />
// 					)
// 					||
// 					(
// 						data?.count > 0 &&
// 						<>
// 							<Table
// 								sticky
// 								components={{
// 									body: {
// 										row: ({ ...restProps }) => (
// 											<tr
// 												{...restProps}
// 												style={{ cursor: 'pointer', ...restProps?.style }}

// 												onClick={() =>
// 												{
// 													const id = restProps?.['data-row-key'];
// 													if (!id) return;

// 													openModal({
// 														table: 'schedule',
// 														data: async () => (await Post({ path: 'schedule', body: { id }, header: { limit: 1 } }))?.data?.[0],
// 													});
// 												}}

// 											/>
// 										),
// 									},
// 								}}
// 								showSorterTooltip={false}
// 								sortDirections={[null]}
// 								// onChange={(pagination, filters, sorter: any) =>
// 								// {
// 								// 	console.log(sorter);
// 								// 	// if (!sorter?.field && !sorter?.dir)
// 								// 	// {
// 								// 	// 	return set({
// 								// 	// 		sorting: {
// 								// 	// 			sort: 'created',
// 								// 	// 			dir: 'desc',
// 								// 	// 		}
// 								// 	// 	});
// 								// 	// }

// 								// 	sorting.sort = sorter?.field || 'created';
// 								// 	sorting.dir = sorter?.order === 'ascend' ? 'asc' : 'desc';
// 								// 	set({ sorting });
// 								// 	console.log(sorting);
// 								// }}
// 								pagination={{ position: ['bottomCenter'] }}
// 								dataSource={
// 									data?.data || []
// 								}
// 								rowKey={(e: Assoc<any>) =>
// 								{
// 									// console.log(e);
// 									return e?.id;
// 								}}
// 								// rowSelection={{
// 								// 	onSelect: (e) => {
// 								// 		console.log(e);
// 								// 	}
// 								// }}
// 								columns={
// 									_.map(
// 										[
// 											'created',
// 											// 'modified',
// 											'start',
// 											'ending',
// 											'office',
// 											'resource',
// 											'online_booking',
// 											'calcprice',
// 											'customprice',
// 											'payment_amount',
// 										],
// 										(k) => ({
// 											key: k,
// 											title: <Lang>{k}</Lang>,
// 											dataIndex: k,
// 											sorter: true,
// 											// ellipsis: true,
// 											ellipsis: {
// 												showTitle: false,
// 											},
// 											render: (_text: string, row: Assoc<any>) =>
// 											{
// 												const value = row?.[k];
// 												if (k.includes('price') || k === 'payment_amount') return value ? ToCurrency(value) : '';
// 												if (value !== undefined && _.isNumber(value)) return value;
// 												if (value !== undefined && _.isBoolean(value)) return value ? 'Kyllä' : 'Ei';
// 												if (value !== undefined && +new Date(value) > 0) return LocaleDate(value);
// 												return String(value?.name || value || '');
// 											},
// 										})
// 									)
// 								}
// 							/>
// 							<Divider />
// 						</>
// 					)
// 				}
// 			</>
// 		</OitisCard>
// 	);
// };
