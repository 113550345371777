import _ from 'lodash';
import dayjs from 'dayjs';
import { Button, Input, InputNumber, RangePicker, Select, TimePicker } from 'App/MainComponents';
import { Lang } from 'utils/Language';
import { styled } from 'styled';
import { getPopupContainer } from 'utils/GetPopupContainer';
import { DeleteIconButton } from '../DeleteIconButton';

const Grouped = styled.div`
	display: flex;

	> :not(:last-child),
	> :not(:last-child) * {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	> :not(:first-child),
	> :not(:first-child) * {
		border-left: 0 !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
`;

type Props = {
	cond?: any;
	value?: any;
	used?: any;
	onChange?: (value: any) => void;
	onDelete: () => void;
};

export const priceConditionTypes = [
	'min_duration',
	'max_duration',
	// 'exact_duration',
	// ...(['min_amount', 'max_amount']),
	'date_range',
	'time_range',
	// 'applicable_start',
	// 'applicable_ending',
	'repeat',
	'weekdays',
	// 'months',
	// 'days',
];

export const PriceConditionPicker = (props: Props) =>
{
	const { cond, value, used, onChange, onDelete } = props;

	return (
		<div
			style={{
				display: 'flex',
				marginBottom: '4px',
				// flexWrap: 'wrap',
			}}
		>
			<Select
				getPopupContainer={getPopupContainer}
				style={{ width: '100%', height: '100%' }}
				placeholder='Lisää ehto...'
				value={cond}
				onChange={e =>
				{
					onChange?.({
						cond: String(e),
						value: undefined,
					});
				}}
			>
				{_.map(priceConditionTypes, (v, k) => (
					<Select.Option
						key={k}
						value={v}
						style={{ padding: '5px 12px' }}
						disabled={_.find(used, f => f?.cond === v)}
					>
						<Lang>{v}</Lang>
					</Select.Option>
				))}
			</Select>

			<div style={{ width: '100%', paddingLeft: '8px' }}>
				{(cond === 'weekdays' && (
					<div className='ant-radio-group ant-radio-group-solid'>
						{_.map(_.range(7), v => (
							<label
								key={v}
								className={`ant-radio-button-wrapper ${ value?.[v] ? 'ant-radio-button-wrapper-checked' : '' }`}
								style={{ margin: 0 }}
								onClick={e =>
								{
									onChange?.({
										cond,
										value: { ...value, [v]: !value?.[v] },
									});
								}}
							>
								{_.upperFirst(dayjs().startOf('week').add(v, 'd').format('ddd'))}
							</label>
						))}
					</div>
				)) ||
					(cond === 'months' && (
						<div className='ant-radio-group ant-radio-group-solid'>
							{_.map(_.range(12), v => (
								<label
									key={v}
									className={`ant-radio-button-wrapper ${ value?.[v] ? 'ant-radio-button-wrapper-checked' : '' }`}
									onClick={e =>
									{
										onChange?.({
											cond,
											value: { ...value, [v]: !value?.[v] },
										});
									}}
								>
									{_.upperFirst(dayjs().startOf('year').add(v, 'month').format('MMM'))}
								</label>
							))}
						</div>
					)) ||
					(cond === 'days' &&
						_.map(_.range(31), v => (
							<Button
								style={{ marginRight: 0 }}
								type={value?.[v] && 'primary'}
								onClick={e =>
								{
									onChange?.({
										cond,
										value: { ...value, [v]: !value?.[v] },
									});
								}}
							>
								{dayjs().startOf('month').add(v, 'd').format('D')}
							</Button>
						))) ||
					(cond === 'date_range' && (
						<Grouped>
							<RangePicker
								style={{ flex: 1 }}
								format='DD.MM.YYYY'
								getPopupContainer={getPopupContainer}
								value={[dayjs(value?.start), dayjs(value?.ending)]}
								onChange={e =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											start: dayjs(e?.[0]).format('YYYY-MM-DD 00:00:00'),
											ending: dayjs(e?.[1]).format('YYYY-MM-DD 23:59:59'),
										},
									});
								}}
							/>
							<Select
								getPopupContainer={getPopupContainer}
								style={{ flex: 1 }}
								defaultValue={'free'}
								value={value?.type || 'free'}
								onChange={e =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											type: String(e),
										},
									});
								}}
							>
								<Select.Option value={'free'}>Saa ylittää aikavälit</Select.Option>
								{/* <Select.Option value={'inside'}>Aikavälin sisäpuolella</Select.Option> */}
								<Select.Option value={'before_end'}>Voimassa päättymiseen asti</Select.Option>
								<Select.Option value={'after_start'}>Voimassa alkupäivästä asti</Select.Option>
								<Select.Option value={'inside'}>Aikavälien sisällä</Select.Option>
								{/* <Select.Option value={'exact'}>Täsmälleen määrätty aikaväli</Select.Option> */}
							</Select>
						</Grouped>
					)) ||
					(cond === 'time_range' && (
						<Grouped>
							<TimePicker
								getPopupContainer={getPopupContainer}
								style={{ flex: 1, width: '100%' }}
								value={dayjs(value?.start, 'HH:mm:ss')?.isValid?.() ? dayjs(value?.start, 'HH:mm:ss') : null}
								format={'HH:mm'}
								minuteStep={15}
								showNow
								onSelect={e =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											start: dayjs(e).format('HH:mm:00'),
										},
									});
								}}
							/>
							<TimePicker
								getPopupContainer={getPopupContainer}
								style={{ flex: 1, width: '100%' }}
								value={dayjs(value?.ending, 'HH:mm:ss')?.isValid?.() ? dayjs(value?.ending, 'HH:mm:ss') : null}
								format={'HH:mm'}
								minuteStep={15}
								showNow
								onSelect={e =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											ending: dayjs(e).format('HH:mm:00'),
										},
									});
								}}
							/>
						</Grouped>
					)) ||
					((cond === 'min_duration' || cond === 'max_duration' || cond === 'exact_duration') && (
						<Grouped>
							<InputNumber
								formatter={value =>
									`${ Math.floor(value / 60 / 24) } vrk ${ Math.floor((value / 60) % 24) } h ${ Math.floor(
										value % 60,
									) } min`
								}
								parser={value =>
								{
									const matches = (value || '').match(/(.*?)vrk(.*?)h(.*?)min/);
									let val = Number(_.replace(matches?.[1] || '', /\s/g, '') || 0) * 60 * 24;
									val += Number(_.replace(matches?.[2] || '', /\s/g, '') || 0) * 60;
									val += Number(_.replace(matches?.[3] || '', /\s/g, '') || 0);
									console.log(value, val);
									return val;
								}}
								step={1}
								style={{ flex: 1, margin: 0, width: '100%' }}
								value={value?.time}
								onChange={time =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											time,
										},
									});
								}}
							/>
							<Select
								getPopupContainer={getPopupContainer}
								style={{ flex: 1 }}
								defaultValue={'remaining'}
								value={value?.type || 'remaining'}
								onChange={type =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											type: String(type),
										},
									});
								}}
							>
								<Select.Option value={'remaining'}>Jäljellä</Select.Option>
								<Select.Option value={'duration'}>Kokonaiskesto</Select.Option>
							</Select>
						</Grouped>
					)) ||
					(Boolean(cond === 'min_amount' || cond === 'max_amount') && (
						<div style={{ display: 'flex' }}>
							<Input
								type='number'
								onChange={e =>
								{
									onChange?.({
										cond,
										value: {
											...value,
											amount: e,
										},
									});
								}}
							/>
						</div>
					)) ||
					(cond === 'repeat' && (
						<div style={{ display: 'flex' }}>
							<InputNumber
								formatter={value =>
									`${ Math.floor(value / 60 / 24) } vrk ${ Math.floor((value / 60) % 24) } h ${ Math.floor(
										value % 60,
									) } min`
								}
								parser={value =>
								{
									const matches = (value || '').match(/(.*?)vrk(.*?)h(.*?)min/);
									let val = Number(_.replace(matches?.[1] || '', /\s/g, '') || 0) * 60 * 24;
									val += Number(_.replace(matches?.[2] || '', /\s/g, '') || 0) * 60;
									val += Number(_.replace(matches?.[3] || '', /\s/g, '') || 0);
									console.log(value, val);
									return val;
								}}
								step={1}
								style={{ width: '100%', margin: 0 }}
								value={value?.time}
								onChange={e =>
								{
									// this.setData(e);
									// console.log(e);
									onChange?.({
										cond,
										value: {
											...value,
											time: e,
										},
									});
								}}
							/>
						</div>
					)) || <Input />}
			</div>
			<div style={{ display: 'flex', alignItems: 'center', margin: '0 18px' }}>
				<DeleteIconButton
					onClick={e =>
					{
						onDelete?.();
					}}
				/>
			</div>
		</div>
	);
};