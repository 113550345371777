import _ from 'lodash';
import { Area } from '@antv/g2plot';
import { useRef } from 'react';
import { ToCurrency } from 'hooks/Dates';
import { useMount } from 'hooks/Hooks';
import { PostResponse } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

type Props = {
	schedules: PostResponse;
	offices: PostResponse;
	rangeStart: Date;
	rangeEnd: Date;
};

export const Myynti = (props: Props) =>
{
	const { schedules, offices, rangeStart, rangeEnd } = props;
	// const [data, setData] = useState<any[]>([]);

	// useMount(() =>
	// {
	//     if (!schedules || !offices) return;
	//     setData(getData());
	// }, [schedules, offices]);

	const getData = () =>
	{
		if (!schedules || !offices) return [];
		// console.log("Generate chart:", schedules, offices);

		const timeFrame = 1000 * 60 * 60 * 24;
		const keys: { [key: string]: number; } = {};
		let start = 0;
		let end = 0;

		const arr: Assoc<any> = {};

		_.map(schedules?.data, v =>
		{
			const date = new Date(v?.created);
			if (+date < +rangeStart) return;
			if (+date > +rangeEnd) return;
			const time = Math.floor(+date / timeFrame);
			const officeId = String(v?.office?.id);

			if (!start || time < start) start = time;
			if (!end || time > end) end = time;

			if (!arr[time]) arr[time] = {};
			if (!arr[time][officeId]) arr[time][officeId] = 0;
			arr[time][officeId] += v?.customprice ?? v?.calcprice ?? 0;

			const key = `${ time }_${ officeId }`;
			keys[key] = (keys[key] || 0) + 1;
		});

		const result = [];
		for (let time = start; time < end; time++)
		{
			for (const office of offices?.data || [])
			{
				const officeId = String(office?.id);
				result.push({
					office: String(office?.name),
					date: new Date(time * timeFrame).toLocaleDateString('fi'),
					value: Number(arr?.[time]?.[officeId] || 0),
				});
			}
		}

		// console.log('Result:', result);
		return result;
	};

	const ref = useRef<HTMLDivElement>(null);
	useMount(() =>
	{
		const container = ref?.current;
		if (!container) return;

		const chart = new Area(container, {
			// style: { height: '100%', maxHeight: 250 },
			height: 200,
			autoFit: true,
			renderer: 'svg', // Nopeampi jos on paljon dataa

			smooth: true,
			xField: 'date',
			yField: 'value',
			seriesField: 'office',
			appendPadding: 10,
			legend: { position: 'top' },
			// modes: {
			// 	default: ['drag-canvas', 'zoom-canvas', 'drag-node'], // Allow users to drag canvas, zoom canvas, and drag nodes
			// },
			// xAxis: {
			//     type: 'time',
			//     mask: 'DD.MM.YYYY',
			// },
			yAxis: {
				label: {
					formatter: v => ToCurrency(Number(v || 0)),
				},
			},
			interactions: [{ type: 'brush-x' }],
			tooltip: {
				// customContent: chartTooltip({
				// 	placeholder: 'Ei myyntejä',
				// 	formatValue: (v) => ToCurrency(v),
				// }),

				customItems: items =>
				{
					return _.map(
						_.filter(items, v => v.value > 0),
						v => ({
							...v,
							value: ToCurrency(Number(v.value)),
							name: v.name.substring(0, 25) + (v.name.length > 25 ? '...' : ''),
						}),
					);
				},
			},
			data: getData(),
		});

		chart.render();
		return () => chart.destroy();
	}, props);

	return <div ref={ref} />;
}
