import _ from 'lodash';
import { useStore } from 'store';
import { Assoc } from 'utils/Assoc';

import { Lang, lang } from 'utils/Language';
import { SystemIcon } from 'App/components/SystemIcon';
import { Ikoni } from 'icons';
import { Post, Put, PutResponse } from 'utils/Api';
import { confirmDelete, confirmModal } from 'utils/ApiFunctions';
import { FormItem } from './FormItem';
import { FormTable } from './FormTable';
import { hasPrivilege } from 'utils/UserUtils';
import { useMount, useStated } from 'hooks/Hooks';
import { Form, Tabs } from 'App/MainComponents';
import { Divider } from 'App/modern/Divider';
import { ModernButton } from 'App/modern/ModernButton';
import { ModalType } from 'App/modals/ModalPopup';
import { useCallback } from 'react';
import { Spinning } from 'App/modern/Loading';
import { CodeEditor } from 'App/components/CodeEditor';
import { colors } from 'App/modern/Colors';
import { errorNotification } from 'utils/ErrorNotification';
import { showNotify } from './showNotify';

type Props = {
	modal: ModalType;
};

export const EditModal = (props: Props) =>
{
	const [state, set] = useStated({
		loading: false as boolean,
		modified: {} as Assoc<any>,
		drag: false as boolean,
	});

	const { modal } = props;
	const { loading, modified } = state;
	const { table, onOk, onUnsaved } = modal || {};
	const logged = useStore(s => s?.logged);

	const [form] = Form.useForm();
	const formData = { ...modal?.data, ...form?.getFieldsValue() };
	const types = logged?.types?.[table];

	const setValues = (values: any) =>
	{
		form?.setFieldsValue(values);
	};

	const validate = async (): Promise<boolean> =>
	{
		try
		{
			return await form.validateFields();
		} catch (e)
		{
			console.error(e);
			const { errorFields } = (e || {}) as { errorFields: { name: string; errors: string[]; }[]; };

			showNotify({
				title: 'Tarkista tiedot',
				message: _.map(errorFields, (v, k) => <div key={k}>{v?.errors}</div>),
				icon: (
					<Ikoni
						name='exclamation-triangle'
						fontColor={colors.orange.normal}
						style={{ fontSize: '2rem', marginRight: '1.25rem' }}
					/>
				),
			});
		}
		return false;
	};

	const save = async (data: Assoc<any>): Promise<PutResponse | void> =>
	{
		if (!modal) return;
		if (!(await validate())) return;
		set({ loading: true });

		try
		{
			data = _.mapValues(data, (v, k) =>
			{
				if (types?.[k]?.type !== 'join' || types?.[k]?.flags?.cascade) return v;
				return _.map(v, v2 => (v2?.id ? { id: v2.id } : v2));
			});

			console.log('save', data);
			const res = await Put({ path: modal.table, body: data });

			showNotify({
				title: <>{lang('saved')}</>,
				message: <>{_.upperFirst(`${ lang(modal.table) } ${ lang('saved') }`.toLowerCase())}</>,
				icon: (
					<Ikoni
						name='check-circle'
						fontColor={colors.green.normal}
						style={{ fontSize: '2rem', marginRight: '1.25rem' }}
					/>
				),
			});

			if (res?.id) onOk?.();
			return res;
		} catch (e)
		{
			errorNotification(e);
		} finally
		{
			return void set({ loading: false });
		}
	};

	useMount(() =>
	{
		if (!form) return;
		const data = modal?.data;
		if (Object.keys(data || {})?.length === 0) return;

		if (types?.openings && !data?.openings?.length)
		{
			data.openings = _.map(_.range(7), v => ({
				closed: false,
				closetime: '23:59:59',
				opentime: '00:00:00',
				weekday: v,
				..._.find(formData?.openings, f => f?.weekday === v),
			}));
		}

		set({ loading: true });
		setValues(data);
		set({ loading: false });
	}, [modal?.data, types]);

	const onValuesChange = useCallback(
		_.debounce(() =>
		{
			set({ modified });
			onUnsaved?.();
		}, 100),
		[],
	);

	if (!table) return null;

	return (
		<Form
			form={form}
			spellCheck={false}
			// autoComplete="off"
			// autoCorrect="off"
			// autoCapitalize="off"
			// autoSave="false"

			labelCol={{ span: 5 }}
			layout='horizontal'
			onValuesChange={(changed, allValues) =>
			{
				Object.assign(modified, { ...modified, ...changed });
				onValuesChange();
			}}
		// onFinishFailed={async (e: any) =>
		// {
		// 	console.log('onFinishFailed');
		// }}
		// onFinish={async (values: any) =>
		// {
		// 	if (modal?.overrideSave) return;

		// 	if (await saveData(values))
		// 	{
		// 		onOk?.();
		// 	}
		// }}
		>
			<Tabs
				// animated={false}
				destroyInactiveTabPane={false}
				tabBarExtraContent={{
					left: (
						<span
							style={{
								fontSize: '16px',
								fontWeight: 500,
								marginRight: '25px',
								// cursor: 'move'
							}}
						// onPointerOver={() => { set({ drag: true }); }}
						// onPointerLeave={() => { set({ drag: false }); }}
						>
							<SystemIcon name={table} style={{ color: colors.blue.normal }} />
							{'\u2000'}
							<Lang>{table}</Lang>
						</span>
					),
				}}
				items={[
					{
						key: 'Tiedot',
						label: (
							<>
								<Ikoni name='pen-line' twoTone fontSize={15} bgOpacity={0.2} />
								{'\u2000'}
								Tiedot
							</>
						),
						children: (
							<>
								<br />
								{_.map(types, (v, k) =>
								{
									if (v?.flags?.cascade) return null;
									return (
										<FormItem
											key={k}
											table={table}
											fieldType={v}
											name={k}
											path={k}
											data={formData}
											form={form}
										/>
									);
								})}
							</>
						),
					},

					...(formData &&
						_.map(types, (v, k) =>
						{
							if (!v?.flags?.cascade) return null;
							if (!hasPrivilege(v?.join!, 'update')) return null;

							return {
								key: k,
								label: (
									<>
										{{
											pricefields: <Ikoni name='tags' fontSize={15} bgOpacity={0.2} />,
											openings: <Ikoni name='clock' fontSize={15} bgOpacity={0.2} />,
											holidays: <Ikoni name='champagne-glass' fontSize={15} bgOpacity={0.2} />,
										}?.[k] || <Ikoni name='pen-line' fontSize={15} bgOpacity={0.2} />}
										{'\u2000'}
										<Lang>{k}</Lang>
									</>
								),
								children: (k === 'openings' && (
									<Form.Item
										key={k}
										name={[k]}
										initialValue={_.map(_.range(7), v => ({
											closed: false,
											closetime: '23:59:59',
											opentime: '00:00:00',
											weekday: v,
											..._.find(formData?.[k], f => f?.weekday === v),
										}))}
									>
										<FormTable parent={table} fieldName={k} table={v?.join!} />
									</Form.Item>
								)) ||
									(v?.type === 'join' && (
										<>
											<Form.Item
												key={k}
												name={[k]}
												initialValue={formData?.[k] !== undefined ? formData?.[k] : v?.default}
											>
												<FormTable parent={table} fieldName={k} table={v?.join!} />
											</Form.Item>
										</>
									)) || (
										<>
											<br />

											{_.map(logged?.types?.[v?.join!], (v2, k2) =>
											{
												return (
													<FormItem
														key={k2}
														table={v?.join!}
														fieldType={v2}
														name={k2}
														path={[v?.join, k2]}
														data={formData?.[k]}
														form={form}
													/>
												);
											})}
										</>
									),
							};
						})),

					...(logged?.user?.super === true && ['company', 'office'].includes(table)
						? [
							{
								key: 'Tyylit',
								label: (
									<>
										<Ikoni name='star' twoTone fontSize={15} bgOpacity={0.2} />
										{'\u2000'}
										Tyylit
									</>
								),
								children: <CodeEditor />,
							},
						]
						: []),
				]}
			/>
			<Divider style={{ marginBottom: '16px', marginTop: '10px' }} />
			<div style={{ display: 'flex' }}>
				{loading && <Spinning />}

				<div style={{ flex: 1 }}>
					{Boolean(formData?.id && hasPrivilege(table, 'delete')) && (
						<ModernButton
							color='red'
							onClick={async () =>
							{
								if (await confirmDelete(table, formData)) onOk?.();
							}}
						>
							<Ikoni name='trash' twoTone bgOpacity={0.3} />
							{'\u2000'}
							<Lang>poista</Lang>
						</ModernButton>
					)}
				</div>
				<div>
					{Boolean(formData?.id && hasPrivilege(table, 'create')) && (
						<ModernButton
							color='blue'
							onClick={async () =>
							{
								if (
									!(await confirmModal({
										content: 'Tallennetaanko kopiona?',
										// icon: <Ikoni name='clone' />,
									}))
								)
									return;

								const formValues = form?.getFieldsValue();
								const values = {
									...((
										await Post({
											path: table,
											body: { id: formValues?.id },
											header: { limit: 1 },
										})
									)?.data?.[0] || {}),
									...formValues,
								};

								const recur = (values: Assoc<any>, key = '') =>
								{
									if (key === 'subresources') return values;
									if (key === 'office') return values;
									if (key === 'company') return values;
									if (key === 'category') return values;
									if (key === 'user') return values;
									if (values?.id) values.id = undefined;

									for (const k in values)
									{
										if (_.isObjectLike(values[k])) values[k] = recur(values[k], k);
									}
									return values;
								};

								console.log('Values:', values);

								set({ loading: true });
								const finalValues = recur(values);
								return save(finalValues);
							}}
						>
							<Ikoni name='clone' />
							{'\u2000'}
							Tallenna kopiona
						</ModernButton>
					)}

					<ModernButton
						color='blue'
						// disabled={!Object.keys(modified)?.length}
						onClick={async (): Promise<void> =>
						{
							if (!formData) return;
							set({ loading: true });

							if (modal?.overrideSave)
							{
								await modal?.overrideSave(formData, modified);
								return set({ loading: false });
							}

							await save(form.getFieldsValue());
						}}
					>
						<Ikoni name='check' />
						{'\u2000'}
						<Lang>save</Lang>
					</ModernButton>
				</div>
			</div>
		</Form>
	);
};
