import { HTMLAttributes } from 'react';
import { styled, css } from 'styled';
import { IconName } from 'assets/css/fa/v6.1.1/all';
import 'assets/css/fa/v6.1.1/css/all.min.css';

type IconProps = {
	fontSize?: number | string;
	fontColor?: string;
	fontWeight?: 100 | 300 | 400 | 900;
	bgOpacity?: number;
};

const IconContainer = styled.i<IconProps>`
	display: inline-flex;
	color: inherit;
	font-variant: normal;
	font-style: normal;
	text-align: center;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;

	/* vertical-align: baseline; */
	/* vertical-align: text-bottom; */
	vertical-align: baseline;

	font-size: 0.99em;
	line-height: 0.99em;
	/* line-height: normal; */

	&.fa-spin {
		animation-duration: 1.5s;
	}

	${ p =>
		p?.fontSize &&
		css`
			font-size: ${ p?.fontSize };
			line-height: ${ p?.fontSize };
		`}

	${ p =>
		p?.bgOpacity &&
		css`
			&:after {
				opacity: ${ p?.bgOpacity };
			}
		`}

	${ p =>
		p?.fontWeight &&
		css`
			font-weight: ${ p?.fontWeight };
		`}

	${ p =>
		p?.fontColor &&
		css`
			color: ${ p?.fontColor };
		`}
`;

type Props = HTMLAttributes<HTMLElement> &
	IconProps & {
		name: IconName;
		spin?: true;
		twoTone?: true;
		filled?: true;
	};

export const Ikoni = (props: Props) =>
{
	const { name, style, ...rest } = props;
	const { filled, twoTone, spin } = props;
	const { fontSize, fontWeight, bgOpacity, fontColor } = props;

	const iconType = `${ twoTone ? 'fad' : filled ? 'fas' : 'far' }`;
	const iconName = `fa-${ name }`;
	const iconSpin = spin ? 'fa-spin' : '';
	const iconSize = (fontSize && (typeof fontSize === 'number' ? `${ fontSize }px` : fontSize)) || '';

	const className = [iconType, iconName, iconSpin, props?.className || ''].join(' ');

	return (
		<IconContainer
			{...{
				...rest,
				fontColor,
				fontSize: iconSize,
				fontWeight: fontWeight,
				bgOpacity: bgOpacity || 0.5,
				className,
				style,
			}}
		/>
	);
};