import { styled, css, keyframes } from 'styled';
import { colors } from 'App/modern/Colors';
import { Ikoni } from 'icons';

export const DeleteIconButton = styled(Ikoni).attrs(({ name, ...rest }) => ({
	name: name || 'circle-xmark',
	role: 'button',
	twoTone: true,
	...rest,
}))`
	background: #fff;
	font-size: 17px;
	cursor: pointer;
	transition: opacity 200ms ease, color 200ms ease;
	color: #0004;
	
	${ p =>
		p?.fontColor &&
		css`
			color: ${ p?.fontColor }!important;
		`}

	&:hover, 
	&:active, 
	&:focus,
	&:focus-within, 
	&:focus-visible {
		color: ${ colors.red.active };
	}
`;
