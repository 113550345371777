import {styled} from 'styled';
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import { oneDark } from '@codemirror/theme-one-dark';
// import 'codemirror/theme/monokai.css';
// import 'codemirror/keymap/sublime';

const Container = styled.div`
	position: relative;

	& .cm-tooltip {
		/* position: absolute!important; */
		/* transform: translateX(-150%); */
	}
`;

export const CodeEditor = () =>
{
	const sample = `
		html {
			background-color: #e2e2e2;
			margin: 0;
			padding: 0;
		}

		body {
			background-color: #fff;
			border-top: solid 10px #000;
			color: #333;
			font-size: .85em;
			font-family: "Segoe UI","HelveticaNeue-Light", sans-serif;
			margin: 0;
			padding: 0;
		}

		a:link, a:visited, 
		a:active, a:hover {
			color: #333;
			outline: none;
			padding-left: 0;
			padding-right: 3px;
			text-decoration: none;
		}
	`;

	return (
		<Container>
			<CodeMirror
				value={sample}
				extensions={[css()]}
				theme={oneDark}
			// onChange={(value, viewUpdate) =>
			// {
			// 	console.log('value:', value);
			// }}
			/>
		</Container>
	);
}
