import { colors } from './modern/Colors';

export const OitisLogo = ({ style }: { style?: React.CSSProperties; }) =>
{
	const color = colors.blue.normal;

	return (
		<svg
			// alt="Oitis.fi"
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 363 112'
			style={style}
		>
			<path
				fill={color}
				d='M.42 12.81q0-1.76.39-2.75t1.3-1.41q.9-.43 2.51-.43 1.61 0 2.51.43.91.42 1.3 1.41t.39 2.75q0 1.76-.39 2.74-.39.98-1.3 1.41-.9.43-2.51.43-1.61 0-2.51-.43-.91-.43-1.3-1.41t-.39-2.74zm3.01 2.18H4.7q.63 0 .87-.16t.24-.58v-3.64H4.52q-.61 0-.85.16t-.24.58v3.64zm6.43 2.19zm.08-6.65l2.8-.35v7h-2.8v-6.65zm1.4-.94q-.59 0-.9-.13-.32-.12-.45-.44-.13-.31-.13-.9 0-.85.31-1.18.32-.32 1.17-.32.84 0 1.16.32.31.33.31 1.18 0 .57-.13.89-.14.31-.44.45-.31.13-.9.13zm6.85 2.39h-.97v3.2q.41.07.83.09l-.13 1.89q-.94.23-1.69.23-.93 0-1.37-.37-.44-.37-.44-1.16v-3.88h-.7v-1.8h.7V9.06l2.8-.7v1.82h.97v1.8zm.96 5.2zm.09-6.65l2.8-.35v7h-2.8v-6.65zm1.4-.94q-.59 0-.91-.13-.31-.12-.44-.44-.14-.31-.14-.9 0-.85.32-1.18.31-.32 1.17-.32.84 0 1.15.32.32.33.32 1.18 0 .57-.14.89-.13.31-.44.45-.31.13-.89.13zm2.54 2.74q0-1.21.76-1.79.76-.57 2.37-.57t3.02.31l-.32 1.93q-.67-.18-1.64-.18-.73 0-1.18.14v.5q1.31.14 2.06.41.76.26 1.09.72.34.47.34 1.19 0 1.25-.85 1.82-.84.58-2.68.58-.81 0-1.63-.13-.82-.12-1.43-.35l.35-2.01q.99.43 2.24.43.35 0 .99-.11v-.55q-1.39-.14-2.13-.38-.74-.25-1.05-.7-.31-.46-.31-1.26zm7.27 3.46q0-.56.13-.87.12-.32.45-.47.33-.16.95-.16.61 0 .93.16.33.15.45.47.13.31.13.87t-.13.87q-.12.31-.45.46-.32.14-.93.14-.62 0-.95-.14-.33-.15-.45-.46-.13-.31-.13-.87zm8.53-6.23l-1.15-.11v.73h.84v1.8h-.84v5.2h-2.8v-5.2h-.7v-1.8h.7q.04-.93.29-1.46.25-.53.79-.76.54-.23 1.48-.23.66 0 1.54.14l-.15 1.69zm.56 7.62zm.08-6.65l2.8-.35v7h-2.8v-6.65zm1.4-.94q-.59 0-.9-.13-.32-.12-.45-.44-.13-.31-.13-.9 0-.85.31-1.18.32-.32 1.17-.32.84 0 1.16.32.31.33.31 1.18 0 .57-.13.89-.14.31-.44.45-.31.13-.9.13z'
				transform='translate(126.197 -11.325) scale(5.57103)'
			></path>
			<g
				fill={color}
				stroke='none'
			>
				<path
					d='M36.35 9.685c.006-.053.016-.105.022-.158l-.238.238.216-.08zm.415-2.046c.196-.682.445-1.341.746-1.973l-.009-.003-6.193 6.193a45.86 45.86 0 012.331-1.091l3.125-3.126zm-.323-2.414c-.298-.12-.595-.236-.89-.349L24.546 15.881l.134.052a45.86 45.86 0 013.242-2.189l8.52-8.519zM23.455 15.477L34.459 4.472a50.5 50.5 0 00-.92-.318L22.534 15.159c.305.102.612.207.921.318zm-3.013-.962c.317.089.636.183.958.281L32.405 3.791a42.019 42.019 0 00-.958-.281L20.442 14.515zm-2.187-.549c.331.072.667.151 1.004.235L30.264 3.196a35.13 35.13 0 00-1.004-.235L18.255 13.966zm-2.32-.415c.352.049.708.106 1.069.17L28.009 2.716c-.36-.064-.717-.121-1.069-.17L15.935 13.551zm-2.51-.226c.381.013.771.04 1.166.074L25.596 2.394a21.598 21.598 0 00-1.166-.074L13.425 13.325z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
				<path
					d='M19.746 2.772c-.685.178-1.329.411-1.934.693l-4.489 4.489a9.26 9.26 0 011.838-2.651L4.156 16.309C-.865 21.33.636 31.778 7.055 43.989c4.743 9.024 12.17 19.008 21.639 28.478a148.178 148.178 0 004.315 4.135l-5.006 5.007 12.558 2.145 12.557 2.144-2.142-12.56-2.145-12.558-4.816 4.815A145.616 145.616 0 0139.7 61.46C27.678 49.438 18.947 36.586 14.824 25.926c-1.868-4.828-2.791-9.205-2.644-12.852L22.931 2.323c-.461.018-.91.054-1.347.106l-9.298 9.298c.078-.64.19-1.254.343-1.84l7.117-7.115zm79.031 72.927L87.771 86.705c.033.397.055.789.066 1.173l11.004-11.004a20.79 20.79 0 00-.064-1.175zm-11.316 8.582c.062.361.119.72.166 1.072l11.006-11.006a27.23 27.23 0 00-.168-1.072L87.461 84.281zm-.473-2.262c.082.339.16.674.23 1.007l11.006-11.005a36.023 36.023 0 00-.232-1.008L86.988 82.019zm10.694-12.191a46.046 46.046 0 00-.279-.961l-5.709 5.708a45.725 45.725 0 01-1.928 3.169l7.916-7.916zm-.639-2.097a44.085 44.085 0 00-.318-.923l-2.561 2.561a45.35 45.35 0 01-.932 2.173l3.811-3.811zm-.719-2.017a81.107 81.107 0 00-.348-.893l-.383.384a47.588 47.588 0 01-.562 1.801l1.293-1.292z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
				<path
					d='M55.797 96.183c-.797-.424-1.6-.858-2.414-1.322l-2.826-1.612 2.91-2.909a37.601 37.601 0 01-21.352-5.51l-9.263-1.581 3.112-3.112c-5.519-5.175-9.489-11.981-11.133-19.643a115.423 115.423 0 01-6.792-9.78A43.005 43.005 0 008 52.55c0 24.16 19.655 43.814 43.814 43.814 1.344 0 2.67-.063 3.983-.181zM38.019 10.961c-8.251 2.745-15.419 7.881-20.681 14.582.231.579.476 1.167.737 1.763a70.694 70.694 0 002.147 4.461c5.082-7.698 12.899-13.439 22.057-15.826a8.394 8.394 0 006.907 3.629h5.257a8.393 8.393 0 006.908-3.629c16.256 4.237 28.291 19.043 28.291 36.609 0 12.566-6.162 23.718-15.619 30.602 2.326 1.007 4.549 1.797 6.629 2.357 9.174-8.037 14.979-19.833 14.979-32.959 0-19.342-12.598-35.794-30.02-41.589C65.498 4.898 60.535 0 54.443 0h-5.257c-6.089 0-11.053 4.898-11.167 10.961zm11.168-4.973h5.257c1.352 0 2.578.523 3.502 1.373.002.004.004.01.004.014 0 .573-2.15 1.972-6.133 1.972-3.985 0-6.135-1.399-6.135-1.972l.003-.014a5.154 5.154 0 013.502-1.373zm-5.185 5.185c0-.485.072-.954.197-1.4.532.486 1.226.893 2.024 1.22v4.429a5.183 5.183 0 01-2.221-4.249zm3.682 4.96v-4.66c.569.147 1.167.264 1.781.347v4.538h-.278a5.181 5.181 0 01-1.503-.225zm3.24.224v-4.404a17.611 17.611 0 001.781 0v4.404h-1.781zm3.519 0h-.277V11.82a15.088 15.088 0 001.781-.347v4.66c-.476.145-.98.224-1.504.224zm4.989-6.585c.125.446.197.915.197 1.4a5.182 5.182 0 01-2.221 4.248v-4.428c.797-.327 1.49-.734 2.024-1.22z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
				<path
					d='M64.578 79.228l.625-.624.289.164c9.441-4.945 15.9-14.841 15.9-26.218 0-16.31-13.268-29.578-29.576-29.578-11.794 0-21.996 6.938-26.742 16.947a107.916 107.916 0 002.153 3.12c3.823-9.852 13.403-16.854 24.589-16.854 14.535 0 26.363 11.827 26.363 26.365 0 13.657-10.439 24.921-23.758 26.234l.543 3.175a29.382 29.382 0 009.614-2.731z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
				<path
					d='M51.814 29.035a2.045 2.045 0 00-2.044 2.045v5.258a2.044 2.044 0 004.089 0V31.08c0-1.13-.914-2.045-2.045-2.045zm2.045 44.984v-5.257c0-.936-.627-1.722-1.484-1.966l1.355 7.935c.082-.223.129-.461.129-.712zm14.168-19.425h5.258a2.045 2.045 0 100-4.089h-5.258a2.046 2.046 0 000 4.089zM37.648 52.55c0-1.13-.915-2.045-2.044-2.045H33.02a136.617 136.617 0 003.409 3.915 2.045 2.045 0 001.219-1.87zm2.995-19.353a.876.876 0 00-.321 1.197l2.569 4.449-1.222-4.498a2.884 2.884 0 01.02-1.016.876.876 0 00-1.046-.132zm21.908 38.822a.876.876 0 00.758-1.314l-2.629-4.553a.876.876 0 10-1.517.876l2.629 4.553c.161.28.456.438.759.438zm2.545-27.138a.876.876 0 001.198.321l4.555-2.628a.877.877 0 00-.877-1.518l-4.555 2.629a.875.875 0 00-.321 1.196zm-26.561 0a.875.875 0 00-.32-1.197l-4.554-2.629a.877.877 0 00-.876 1.518l4.553 2.629a.872.872 0 001.197-.321zm32.633 18.842a.877.877 0 00-.32-1.197l-4.555-2.629a.877.877 0 00-.877 1.518l4.555 2.628a.874.874 0 001.197-.32zm-9.377-30.205l-2.629 4.554a.877.877 0 001.518.876l2.629-4.553a.876.876 0 10-1.518-.877zm3.828 48.18L54.613 92.703c2.826 1.611 5.574 2.972 8.203 4.069 9.732 4.061 17.807 4.458 22.033.231l11.006-11.006a9.161 9.161 0 01-2.369 1.703l4.074-4.074c.316-.624.57-1.295.768-2.007l-6.855 6.855c-.594.165-1.219.285-1.867.371l9.102-9.102c.059-.441.1-.895.121-1.361L88.24 88.973c-2.336.113-4.977-.219-7.846-.96-4.464-1.153-9.482-3.297-14.775-6.315zM48.497 49.814l.057-.056.421-.354 3.335-2.802-5.295-13.446-.152-.386c-.479-.916-1.633-1.358-2.731-.993-1.1.364-1.761 1.409-1.598 2.429l.108.399 4.809 17.687a3.641 3.641 0 011.046-2.478z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
				<path
					d='M63.861 40.261a2.219 2.219 0 00-2.879-.195l-.195.165-11.109 9.331-.421.354-.057.055a3.657 3.657 0 00.047 5.173c.304.297.646.527 1.01.698l.27.114a3.657 3.657 0 003.892-.859c.02-.019.035-.039.055-.058l.346-.428 9.127-11.276.162-.2a2.22 2.22 0 00-.248-2.874zM52.428 54.396a1.944 1.944 0 11-1.225-3.69 1.944 1.944 0 011.225 3.69z'
					transform='translate(-.397 .444) scale(1.11111)'
				></path>
			</g>
		</svg>
	);
};

// export const LogoImage = styled.img`
// 	filter: contrast(0.2) sepia(1) saturate(40) hue-rotate(190deg);
// `;
