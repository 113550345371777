import _ from 'lodash';
import { createStore } from './BaseStore';
import { useStore } from 'store';
import { useMount, useStated } from './Hooks';
import { Post, PostOptions, PostResponse } from 'utils/Api';
import hashSum from 'hash-sum';

type PostStatus = 'loading' | 'error' | 'ready';
type CachedRequest = { time: number; plain: boolean; response: any; };

const cachedRequests: Record<string, CachedRequest> = {};
const postStore = createStore('postStore', {
	cacheKeys: {} as { [path: string]: number; },
});

const refreshCache = () =>
{
	const now = Date.now();
	const cacheTime = now - 1000 * 30;
	_.map(cachedRequests, (v, k) =>
	{
		if (v?.time > cacheTime) return;
		delete cachedRequests[k];
	});
};

export const refetch = async (path: string) =>
{
	refreshCache();

	const now = Date.now();
	const { cacheKeys } = postStore.getStore() || {};
	postStore.setStore({
		cacheKeys: {
			...cacheKeys,
			[path]: now,
		},
	});
};

export const makeHash = (data: unknown): string => hashSum(JSON.stringify(data));

export const usePost = <T = PostResponse>(conds: PostOptions, skip?: boolean): [result: T, status: PostStatus] =>
{
	const { path, body, header } = conds;

	const userKey = useStore(s => [
		s?.logged?.user?.id || '',
		s?.logged?.user?.company?.id || '',
		s?.logged?.user?.offices?.map?.((v: { id?: string; }) => v?.id) || '',
		s?.logged?.user?.privileges || '',
	]);

	const [cacheKey] = postStore.useStore(s => [s?.cacheKeys?.[path]]);
	const hash = makeHash([body || '', header || '', userKey || '']);
	const plainKey = `${ path || '' }_${ hash || '' }`;
	const key = `${ plainKey || '' }_${ cacheKey || 0 }`;

	type ResponseData = (Omit<CachedRequest, 'response'> & { response: T; }) | undefined;

	const [state, set] = useStated({
		data: cachedRequests?.[key]?.response as ResponseData,
		status: 'loading' as PostStatus,
	});

	const { data, status } = state;
	const result = cachedRequests?.[key] ?? data; // ?? cachedRequests?.[plainKey];

	useMount(() =>
	{
		if (skip) return set({ data: state.data || cachedRequests?.[key], status: 'ready' });

		if (cachedRequests?.[key]) return set({ data: cachedRequests[key], status: 'ready' });

		const controller = new AbortController();
		(async () =>
		{
			try
			{
				set({ status: 'loading' });
				const response = await Post<T>({ path, body, header, extra: { signal: controller.signal } });

				// cachedRequests[plainKey] = { time: Date.now(), plain: true, response };
				cachedRequests[key] = { time: Date.now(), plain: false, response };
				return set({ data: cachedRequests[key], status: 'ready' });
			}
			catch (e)
			{
				// setStatus('error');
			}
		})();

		return () =>
		{
			controller?.abort();
		};
	}, [key]);

	return [result?.response, status];
};
