import { colors } from 'App/modern/Colors';
import { styled, css, keyframes } from 'styled';

type Props = {
	color?: keyof typeof colors;
};

// export const ModernButton = styled.button.attrs({ type: 'button' }) <Props>`
// export const ModernButton = styled(Button)<Props>`
export const ModernButton = styled.button.attrs(p => ({ type: p?.type || 'button' })) <Props>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	outline: 0;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	color: #545454;
	font-size: 14px;
	font-weight: 400;
	height: 36px;
	padding: 0 15px;
	text-align: center;
	touch-action: manipulation;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
	white-space: nowrap;
	vertical-align: middle;
	box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
	text-shadow: 0px 0px 0.2px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	margin: 0 8px 8px 0;
	&:last-child {
		margin-right: 0;
	}
	&:only-child {
		margin: 0;
	}

	> * {
		margin: 0 0.2em;
	}
	> *:first-child {
		margin-left: 0;
	}
	> *:last-child {
		margin-right: 0;
	}

	&[disabled] {
		cursor: not-allowed;
		&,
		&:hover,
		&:focus,
		&:active {
			color: #00000040 !important;
			border-color: #d9d9d9 !important;
			background: #f5f5f5 !important;
			text-shadow: none !important;
			box-shadow: none !important;
			opacity: 0.8;
		}
	}

	&:not([disabled]) {
		${ p =>
	{
		const color = colors[p?.color || 'white'];
		if (!color) return '';

		const anim = keyframes`
				0% {
					box-shadow: 0 0 0 0 ${ color.normal }e0;
				}
				70% {
					box-shadow: 0 0 0 5px ${ color.normal }00;
				}
				to {
					box-shadow: 0 0 0 5px ${ color.normal }00;
				}
			`;

		return css`
				&:focus {
					animation: 1s ${ anim } normal;
				}
				&:active {
					animation: none !important;
				}

				color: ${ color.text };
				background: ${ color.bg };
				border-color: ${ color.border };
				&:hover {
					background: ${ color.hover };
					/* border-color: ${ color.border }; */
				}
				&:active {
					background: ${ color.active };
					/* border-color: ${ color.border }; */
				}
			`;
	} }
	}
`;
