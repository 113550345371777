import _ from 'lodash';
import { IconName } from 'assets/css/fa/v6.1.1/all';
import { ComponentProps, HTMLAttributes } from 'react';
import { Ikoni } from 'icons';
import { useStore } from 'store';
import { Assoc } from 'utils/Assoc';

const common: Partial<ComponentProps<typeof Ikoni>> = {
	twoTone: true,
	bgOpacity: 0.5,
};

const icons: Assoc<IconName> = {
	home: 'house',
	schedule: 'calendar',
	reservation: 'calendar',
	resource: 'cart-shopping',
	category: 'folder',
	customer: 'address-card',
	office: 'store',
	user: 'user',
	company: 'bank',
	companies: 'bank',
	user_level: 'user-lock',
	visitor: 'users',
	translation: 'font',
	reports: 'chart-line',
};

type Props = HTMLAttributes<Element> & { name: string; } & Omit<ComponentProps<typeof Ikoni>, 'name'>;

export const SystemIcon = (props: Props) =>
{
	const { name, ...rest } = props;
	const search = String(name || '').toLowerCase();
	const translations = useStore(s => s?.translations);

	let iconName = String(icons?.[search] || icons?.[search.slice(0, -1)] || icons?.[`${ search }s`] || '');
	if (!iconName)
	{
		const translated = String(_.find(translations?.data, f => f?.value?.toLowerCase?.() === search)?.name || '');
		if (translated) iconName = String(icons?.[translated] || icons?.[translated.slice(0, -1)] || '');
	}

	if (!iconName)
	{
		// console.log('Ei löytyny:', _.filter(translations?.data, f => f?.value?.toLowerCase?.() === search));

		return (
			<Ikoni name='circle-question' />
		);
	}

	return (
		<Ikoni
			name={iconName as IconName}
			{...{ ...common, ...rest }}
		/>
	);
};
