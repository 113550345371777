import _ from 'lodash';
import { Ikoni } from 'icons';
import { Loading } from 'App/modern/Loading';
import { CSSProperties, ReactNode } from 'react';
import { PostResponse } from 'utils/Api';

type Props = {
	schedules: PostResponse;
	rangeLabel: ReactNode;
	rangeStart: Date;
	rangeEnd: Date;
	spinning: boolean;
};

export const Tervetuloa = (props: Props) =>
{
	const { schedules, rangeStart, rangeEnd, rangeLabel, spinning } = props;

	const iconStyle: CSSProperties = { fontSize: '20px', minWidth: '24px' };

	return (
		<>
			<h1 className='oitis-mb-3'>Tervetuloa</h1>
			<p className='oitis-fs-sm oitis-text-uppercase'>{rangeLabel}</p>
			<ul className='oitis-list-group'>
				<li>
					<Ikoni
						name='clock'
						style={iconStyle}
					/>
					<span>
						{spinning ? (
							<Loading />
						) : (
							_.reduce(
								schedules?.data,
								(r, v) =>
								{
									if (+new Date(v?.start) > Date.now()) r++;
									return r;
								},
								0,
							)
						)}
						<> varausta tulossa </>
					</span>
				</li>
				<li>
					<Ikoni
						name='calendar-check'
						twoTone
						style={iconStyle}
					/>
					<span>
						{spinning ? (
							<Loading />
						) : (
							_.reduce(
								schedules?.data,
								(r, v) =>
								{
									if (+new Date(v?.created) < +rangeStart) return r;
									// if (v?.status === 'new') r++;
									r++;
									return r;
								},
								0,
							)
						)}
						<> varausta yhteensä </>
					</span>
				</li>
				<li>
					<Ikoni
						name='globe'
						style={iconStyle}
					/>
					<span>
						{spinning ? (
							<Loading />
						) : (
							_.reduce(
								schedules?.data,
								(r, v) =>
								{
									if (+new Date(v?.created) < +rangeStart) return r;
									if (v?.source === 'nettivaraus' || v?.online_booking === true) r++;
									return r;
								},
								0,
							)
						)}
						<> nettivarausta </>
					</span>
				</li>
				<li>
					<Ikoni
						name='user'
						filled
						style={iconStyle}
					/>
					<span>
						{spinning ? (
							<Loading />
						) : (
							_.reduce(
								schedules?.data,
								(r, v) =>
								{
									if (+new Date(v?.customer?.created) > +rangeStart) r++;
									return r;
								},
								0,
							)
						)}
						<> uutta asiakasta </>
					</span>
				</li>
			</ul>
		</>
	);
}
