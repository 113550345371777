import _ from 'lodash';
import { getStore } from 'store';

type PrivilegeLevel = 'create' | 'read' | 'update' | 'delete';

export const hasPrivilege = (table: string, level: PrivilegeLevel): boolean =>
{
	const { user } = getStore()?.logged || {};
	if (user?.super === true) return true;
	return Boolean(_.find(user?.privileges ?? [], { table, level }));
};
