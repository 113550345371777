import _ from 'lodash';
import { Ikoni } from 'icons';
import { Button, Input } from 'App/MainComponents';
import { Lang } from 'utils/Language';
import { Assoc } from 'utils/Assoc';

type Props = {
	value?: Assoc<string>;
	onChange?: (value: Assoc<string>) => void;
};

export const JsonTable = (props: Props) =>
{
	const { value, onChange } = props;

	const data = _.reduce(
		value,
		(r, v: string, k: string) =>
		{
			r.push({ key: k, value: v });
			return r;
		},
		[] as { key: string; value: string; }[],
	);

	const change = () =>
	{
		const changed = _.reduce(
			data,
			(r, v) =>
			{
				r[v.key] = v.value;
				return r;
			},
			{} as Assoc<string>,
		);

		onChange?.(changed);
	};

	return (
		<>
			{_.map(data, (v, k) =>
			{
				return (
					<div
						key={k}
						style={{ display: 'flex', marginBottom: 8, width: '100%' }}
					>
						<div style={{ marginBottom: 0, width: '100%', marginRight: '8px' }}>
							<Input.TextArea
								placeholder='Otsikko'
								autoSize={{ minRows: 1, maxRows: 5 }}
								value={v?.key}
								onChange={e =>
								{
									const dup = _.find(data, { key: e.target.value });
									if (dup) return;

									v.key = e.target.value;
									change();
								}}
							/>
						</div>

						<div style={{ marginBottom: 0, width: '100%', marginRight: '8px' }}>
							<Input.TextArea
								placeholder='Tieto'
								autoSize={{ minRows: 1, maxRows: 10 }}
								value={v?.value}
								onChange={e =>
								{
									v.value = e.target.value;
									change();
								}}
							/>
						</div>

						<Ikoni
							name='circle-xmark'
							filled
							style={{ alignItems: 'center' }}
							onClick={() =>
							{
								data.splice(k, 1);
								change();
							}}
						/>
					</div>
				);
			})}
			<Button
				block
				type='link'
				onClick={() =>
				{
					data.push({ key: '', value: '' });
					change();
				}}
			>
				<Ikoni name='plus' />
				{'\u2000'}
				<Lang>add new</Lang>
			</Button>
		</>
	);
}
