// type Color = {
// 	normal: string;
// 	hover: string;
// 	active: string;
// 	border: string;
// 	extralight: string;
// 	light: string;
// 	text: string;
// 	bg?: string;
// };

export const colors = {
	white: {
		normal: '#ffffff',
		hover: '#f8f8f8',
		active: '#e8e8e8',
		border: '#d9d9d9',
		extralight: '#cbe6ff',
		light: '#7ec1ff',
		text: '#555',
		bg: '#ffffff',
	},
	blue: {
		normal: '#038fde',
		hover: '#40a9ff',
		// active: '#096dd9',
		active: '#038fde',
		border: '#038fde',
		extralight: '#e6f7ff',
		light: '#91d5ff',
		text: '#ffffff',
		bg: '#038fde',
	},
	green: {
		normal: '#52c41a',
		hover: '#73d13d',
		active: '#389e0d',
		border: '#52c41a',
		extralight: '#f6ffed',
		light: '#b7eb8f',
		text: '#ffffff',
		bg: '#52c41a',
	},
	red: {
		normal: '#ff4d4f',
		hover: '#faefef',
		active: '#d9363e',
		border: '#f5222d',
		extralight: '#ffccc7',
		light: '#ff7875',
		text: '#d9363e',
		bg: '#ffffff',
	},
	orange: {
		normal: '#fa8c16',
		hover: '#ffc53d',
		active: '#d48806',
		border: '#faad14',
		extralight: '#fffbe6',
		light: '#ffe58f',
		text: '#ffffff',
		bg: '#fa8c16',
	},
} as const;

// export const colors = _colors as Record<keyof typeof _colors, Color>;
