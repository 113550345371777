import { styled, css, keyframes } from 'styled';
import { Dayjs } from 'dayjs';
import { Ikoni } from 'icons';
import { DeleteIconButton } from './components/DeleteIconButton';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, { PickerProps, RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';
import { Select as _Select } from 'antd';
import fiFI from 'antd/lib/locale/fi_FI';

export { fiFI };
export type { ModalFuncProps, FormInstance, ModalProps, FormItemProps } from 'antd';
export
{
	notification,
	message,
	List,
	Menu,
	Input,
	Spin,
	Tabs,
	Calendar,
	ConfigProvider,
	Checkbox,
	Result,
	Typography,
	AutoComplete,
	Row,
	Col,
	Upload,
	InputNumber,
	Pagination,
	Popconfirm,
	Skeleton,
	Switch,
	Radio,
	Table,
	Layout,
	Drawer,
	Tooltip,
	Empty,
	Button,
	Form,
	Modal,
	Dropdown,
} from 'antd';

// --- Custom components ---

export const Select = styled(_Select).attrs({
	suffixIcon: <Ikoni name='angle-down' />,
})`
	.ant-select-arrow {
		font-size: 16px;
		display: flex;
		align-items: center;
	}
`;

// --- Date pickers ---

const Picker = generatePicker<Dayjs>(dayjsGenerateConfig);

const common: PickerProps<Dayjs> & RangePickerProps<Dayjs> = {
	locale: fiFI.DatePicker,
	format: 'DD.MM.YYYY',

	suffixIcon: (
		<Ikoni
			name='calendar'
			twoTone
			fontSize={16}
		/>
	),
	clearIcon: (
		<DeleteIconButton
			fontSize={16}
			style={{ right: -2 }}
		/>
	),

	prevIcon: (
		<Ikoni
			name='angle-left'
			fontSize={16}
		/>
	),
	nextIcon: (
		<Ikoni
			name='angle-right'
			fontSize={16}
		/>
	),
	superPrevIcon: (
		<Ikoni
			name='angles-left'
			fontSize={16}
		/>
	),
	superNextIcon: (
		<Ikoni
			name='angles-right'
			fontSize={16}
		/>
	),
};

export const DatePicker = (props: PickerProps<Dayjs>) => (
	<Picker
		{...common}
		{...props}
	/>
);
export const TimePicker = (props: PickerProps<Dayjs>) => (
	<Picker.TimePicker
		{...common}
		{...props}
	/>
);
export const RangePicker = (props: RangePickerProps<Dayjs>) => (
	<Picker.RangePicker
		{...common}
		{...props}
	/>
);
