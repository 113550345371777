import { Avatar } from 'App/components/OitisCard';
import { ModernList, ModernListItem } from 'App/modern/ModernList';
import _ from 'lodash';
import { Picture } from './Picture';
import { usePost } from 'hooks/usePost';
import { useMount, useStated } from 'hooks/Hooks';
import { styled } from 'styled';
import { Popup } from 'App/popup/Popup';
import { DebounceInput } from 'App/modern/DebouncedInput';
import { Ikoni } from 'icons';
import { Virtual } from 'App/modern/Virtual';
import { CSSProperties } from 'react';
import { DeleteIconButton } from './DeleteIconButton';
import { colors } from 'App/modern/Colors';
import { PostOptions } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const Close = styled(DeleteIconButton)`
	position: absolute;
	font-size: 16px;
	opacity: 0;
	z-index: 2;

	&:hover,
	&:active {
		opacity: 1;
		color: ${ colors.red.normal };
	}
`;

const Item = styled(ModernListItem)`
	/* &:active, &:focus, &:focus-within, &:focus-visible {
		border: 1px solid ${ colors.blue.normal };
		border-radius: 6px;
	} */

	&:hover {
		${ Close } {
			opacity: 1;
		}
	}
`;

const ItemAvatar = styled(Avatar)`
	margin-right: 10px;
	padding: 0;
	min-width: 24px;
	min-height: 24px;
	max-height: 24px;
	max-width: 24px;

	/* 
		transition: transform 0.1s ease;
		&:hover {
			transform: scale(0.85);
		} 
	*/
`;

const IconContainer = styled.span`
	position: relative;
	display: flex;
	align-items: center;
	margin-left: auto;
	width: 12px;
	height: 100%;
`;

const ArrowDown = styled(Ikoni).attrs({ name: 'angle-down' })`
	font-size: 16px;
	position: absolute;
	background: #fff;
	color: rgba(0, 0, 0, 0.25);
	transition: opacity 0.3s, color 0.3s;
	opacity: 1;

	&:hover {
		color: #595959;
		opacity: 0;
		color: ${ colors.red.normal };
	}
`;

const Subtitle = styled.div`
	font-size: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 400;
`;

type OptionProps = {
	table: string;
	active?: boolean;
	data?: { id?: string; picture?: string; name?: string; address?: string; postal?: string; city?: string; };
	onClick?: () => void;
};

const Option = ({ table, data, active, onClick }: OptionProps) => (
	<Item
		hover
		onClick={onClick}
		{...(active && { className: 'active' })}
	>
		<ItemAvatar>
			<Picture
				table={table}
				src={data?.picture}
				size={64}
			/>
		</ItemAvatar>
		<div>
			{String(data?.name || '').trim()}
			{Boolean(data?.address) && (
				<Subtitle>
					{[String(data?.address || '').trim() + ',', String(data?.postal || '').trim(), String(data?.city || '').trim()].join(' ')}
				</Subtitle>
			)}
		</div>
	</Item>
);

type PopupProps = {
	value?: string | string[];
	search?: string;
	table: string;
	where?: Assoc<any>;
	minLength?: number;
	noEmptyContent?: boolean;
	onChange?: (v: any) => void;
	onDestroy?: () => void;
};

const PopupContent = (props: PopupProps) =>
{
	const [state, set] = useStated({
		data: [] as Assoc<any>[],
		offset: 0 as number,
	});

	const { value, search, table, where, minLength, noEmptyContent } = props;
	const { onChange, onDestroy } = props;
	const { data, offset } = state;
	const limit = 8;

	const conds: PostOptions = {
		path: table,
		body: {
			active: 'active',
			...(search && { name: `%${ search }%` }),
			...where,
		},
		header: {
			offset,
			limit,
			// sort: 'modified', dir: 'desc',
			sort: 'name',
			dir: 'asc',
		},
	};

	const skip = minLength ? (search?.length ?? 0) < minLength : undefined;
	const [res, status] = usePost(conds, skip);

	useMount(() => onDestroy);
	useMount(() => set({ offset: 0 }), search);
	useMount(() =>
	{
		const { data, offset } = state;

		if (status === 'ready' && !offset) return set({ data: res?.data || [] });
		if (res?.data?.length)
		{
			const newData = [...(data || [])];
			_.map(res?.data || [], v => (!_.find(data, f => f?.id === v?.id)) && newData.push(v));
			return set({ data: newData });
		}

		// return onDestroy;
	}, [res, status]);


	if (noEmptyContent && !data?.length) return null;

	return (
		<Virtual
			total={res?.count}
			status={status}
			height={200}
			onLoad={off =>
			{
				set({ offset: off });
				return true;
			}}
			items={_.map(data, (item, k) => (
				<Option
					key={item?.id || k}
					table={table}
					data={item}
					active={value === item?.id}
					onClick={() =>
					{
						console.log('Item:', item);
						onChange?.(item);
					}}
				/>
			))}
		/>
	);
};

type AutoFillProps = Readonly<{
	value?: any;
	selected?: any;
	table: string;
	onChange?: (value: any) => void;
	onType?: (value: string) => void;
	placeholder?: string;
	noEmptyContent?: boolean;
	style?: CSSProperties;
	allowClear?: boolean;
	multiple?: boolean;
	minLength?: number;
	where?: Assoc<any>;
}>;

export const AutoFill = (props: AutoFillProps) =>
{
	const [state, set] = useStated({
		input: undefined as string | undefined,
	});

	const { onChange, onType } = props;
	const { value, selected, placeholder, table } = props;
	const { style, allowClear, where, minLength, noEmptyContent, multiple } = props;
	const { input } = state;

	const content = PopupContent({
		table,
		search: state.input,
		value: value?.id ?? selected?.id,
		where,
		minLength,
		noEmptyContent,
		onDestroy: () => set({ input: undefined }),
		onChange: v =>
		{
			onChange?.(v);
			set({ input: undefined });
		},
	});

	return (
		<ModernList
			border
			style={{ minWidth: '200px', ...style }}
		>
			<Popup
				trigger={'click'}
				visible={noEmptyContent && (!content?.props?.total || !input?.length) ? false : undefined}
				stretch
				color='#fff'
				placement='bottom'
				content={content}
			>
				<Item>
					{!input && (
						<>
							{(Boolean(value?.name || value) && (
								<>
									<ItemAvatar>
										{
											// value?.picture !== undefined &&
											<Picture
												table={table}
												src={String(value?.picture)}
												size={64}
											/>
										}
									</ItemAvatar>
									<div>{String((value?.name ?? value) || '')}</div>
								</>
							)) || <span style={{ color: '#aaa' }}>{String(placeholder)}</span>}
						</>
					)}

					<IconContainer>
						<ArrowDown />
						{Boolean((value || input) && allowClear !== false) && (
							<Close
								onPointerDown={e =>
								{
									e.preventDefault();
									e.stopPropagation();
								}}
								onClick={e =>
								{
									e.preventDefault();
									e.stopPropagation();
									onChange?.(null);
									set({ input: undefined });
								}}
							/>
						)}
					</IconContainer>

					<DebounceInput
						timeout={100}
						value={typeof value === 'string' ? value : input}
						onBounce={v => set({ input: v })}
						onType={v => onType?.(v)}
					/>
				</Item>
			</Popup>
		</ModernList>
	);
}
