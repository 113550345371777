import _ from 'lodash';
import { styled } from 'styled';
import { Input, Radio, Switch } from 'App/MainComponents';
import { Avatar } from 'App/components/OitisCard';
import { ModernList, ModernListItem } from 'App/modern/ModernList';
import { ToCurrency } from 'hooks/Dates';
import { usePost } from 'hooks/usePost';
import { Picture } from './Picture';
import { Lang } from 'utils/Language';
import { Spinning } from 'App/modern/Loading';
import { PostOptions } from 'utils/Api';
import { Assoc } from 'utils/Assoc';

const Item = styled(ModernListItem)`
	height: 100%;
	max-height: 40px;

	> div:last-child {
		flex: 0 auto;
		display: flex;
		align-items: center;
	}
`;

const OrderLineItem = (props: { value: any; v: any; data: Assoc<any>; onChange?: (value: any) => void; }) =>
{
	// console.log("OrderLineItem", props)
	const { value, v, data, onChange } = props;
	const found = _.find(value || [], { resource: v?.id });

	const subresources = _.filter(data?.data, v2 => _.find(v2?.parents, f => f?.id === v?.id));

	const switchProps = {
		checked: found?.amount > 0,
		onChange: (e: any) =>
		{
			if (v?.option_group)
			{
				_.remove(value, (f: any) =>
				{
					const resource = _.find(data?.data, { id: v?.id });
					if (!resource?.option_group) return false;
					let parents = resource?.parents;
					parents = _.map(parents, (c: any) => _.find(data?.data, { id: c?.id }));

					for (const k in parents)
					{
						if (_.find(parents[k]?.subresources, { id: f?.resource })) return true;
					}
					return false;
				});
			}

			if (!e)
			{
				_.remove(value, (f: any) => _.find(v?.subresources, { id: f?.resource?.id || f?.resource }));
				_.remove(value, { resource: v?.id });
				onChange?.(value);
			} else
			{
				if (!found)
				{
					const newItem = { resource: v?.id, amount: v?.min_amount || 1 };
					onChange?.([...(value || []), newItem]);
				} else
				{
					found.amount = 1;
					onChange?.(value);
				}
			}
		},
	};

	return (
		<>
			<Item key={v?.id}>
				<div>
					{/* <Ikoni name="circle-Info" twoTone /> */}
					{/* <Ikoni name="arrow-right" /> */}
					<Avatar style={{ marginRight: '10px', padding: 0, maxHeight: '24px', maxWidth: '24px' }}>
						<Picture
							table={'resource'}
							src={v?.picture}
							size={64}
						/>
					</Avatar>
					<span>{v?.name}</span>
				</div>
				<div>
					<span style={{ marginRight: '8px' }}>{(v.base_price !== 0 && ToCurrency(v.base_price)) || 'Sisältyy hintaan'}</span>
					{Boolean(Number(v?.max_amount) > 1 && found?.amount >= v?.min_amount) && (
						<span style={{ marginRight: '8px' }}>
							<Input
								style={{ padding: '6px' }}
								type='number'
								defaultValue={v?.min_amount}
								min={Number(v?.min_amount || 1)}
								value={found?.amount}
								max={Number(v?.max_amount || 999)}
								onChange={e =>
								{
									const amount = Number(e.target.value);
									if (amount === 0)
									{
										_.remove(value, { resource: v?.id });
										onChange?.(value);
									} else
									{
										if (!found)
										{
											const newItem = { resource: v?.id, amount: v?.min_amount };
											onChange?.([...(value || []), newItem]);
										} else
										{
											found.amount = amount;
											onChange?.(value);
										}
									}
								}}
							/>
						</span>
					)}
					<span>{v?.option_group ? <Radio {...switchProps} /> : <Switch {...switchProps} />}</span>
				</div>
			</Item>

			{subresources?.length > 0 && (
				<Item style={{ padding: 0 }}>
					<ModernList style={{ flex: 1, padding: '0 0 0 20px' }}>
						{_.map(subresources, v => (
							<OrderLineItem
								key={v?.id}
								data={data}
								value={value}
								v={v}
								onChange={onChange}
							/>
						))}
					</ModernList>
				</Item>
			)}
		</>
	);
};

type Props = {
	value?: any;
	table: string;
	resource: any;
	onChange?: (value: any) => void;
	where?: Assoc<any>;
};

export const OrderLineList = (props: Props) =>
{
	const { value, table, resource, where, onChange } = props;

	const conds: PostOptions = {
		path: table,
		body: {
			active: 'active',
			...where,
		},
		header: {
			limit: 20,
			sort: 'parents',
			dir: 'asc',
		},
	};

	const [data, status] = usePost(conds);
	const list = _.filter(data?.data, v => _.find(v?.parents, f => f?.id === resource?.id));

	// if (data?.count === 0) return null;
	if (status === 'ready' && data?.count === 0)
	{
		return (
			<div style={{ paddingBottom: '4px' }}>
				<Lang>ei kohteita</Lang>
			</div>
		);
	}

	return (
		<ModernList border>
			{status === 'loading' && <Spinning />}
			{_.map(list, v => (
				<OrderLineItem
					key={v?.id}
					data={data}
					value={value}
					v={v}
					onChange={onChange}
				/>
			))}
		</ModernList>
	);
}
