import _ from 'lodash';
import { styled } from 'styled';
import { NavLink } from 'router';
import { Lang } from 'utils/Language';
import { Ikoni } from 'icons';
import { SystemIcon } from 'App/components/SystemIcon';
import { Fragment } from 'react';
import { colors } from 'App/modern/Colors';

const Container = styled.div`
	color: #545454;
	font-size: 14px;

	a {
		color: #595959;
		transition: color 0.3s;

		:hover {
			color: ${ colors.blue.normal };
		}
	}

	padding: 12px 24px;
`;

export const Breadcrumbs = () =>
{
	// const links = useStored(s => s?.logged?.links);
	// const title = links?.[table]?.title;
	const pathname = window.location.pathname.split('/');
	// console.log(path);

	return (
		<Container>
			<NavLink to='/'>
				<Ikoni
					name='house'
					twoTone
					fontColor={colors.blue.normal}
				/>{' '}
				Etusivu
			</NavLink>
			{pathname.map((v, k) =>
			{
				if (!v) return null;
				v = decodeURIComponent(v);
				return (
					<Fragment key={k}>
						<> / </>
						<NavLink to={`/${ v }`}>
							<SystemIcon
								name={v}
								twoTone
								fontColor={colors.blue.normal}
							/>
							{'\u2000'}
							<Lang>{v || 'not found'}</Lang>
						</NavLink>
					</Fragment>
				);
			})}
		</Container>
	);
}
