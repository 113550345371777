import _ from 'lodash';
import { Badge } from 'App/components/Badge';
import { Picture } from 'App/components/Picture';
import { Tabs } from 'App/MainComponents';
import { Tag } from 'App/modern/Tag';
import { openModal } from 'App/modals/ModalPopup';
import { Post, PostResponse } from 'utils/Api';
import { LocaleDate, ToDuration } from 'hooks/Dates';
import { styled } from 'styled';
import { Avatar } from 'App/components/OitisCard';
import { DesktopOnly } from 'App/modern/Visibility';
import { EmptyContent } from 'App/components/EmptyIcon';
import { Ikoni } from 'icons';
import { Assoc } from 'utils/Assoc';

const Item = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -8px;
	padding: 5px 8px;
	border-radius: 6px;
	/* overflow: hidden; */
	cursor: pointer;
	transition: background 150ms cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover {
		background: #f5f5f5;
	}
`;

const Ellipsis = styled.div`
	flex: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

type Props = {
	schedules: PostResponse;
};

export const AlkavatVaraukset = (props: Props) =>
{
	const { schedules } = props;
	const now = Date.now();

	const InfoItem = ({ schedule, time }: { schedule: Assoc<any>; time: Date; }) =>
	{
		const timeDiff = +time - now;

		return (
			<Item
				onClick={async () =>
				{
					openModal({
						table: 'schedule',
						data: async () =>
							(
								await Post({
									path: 'schedule',
									body: { id: schedule?.id },
									header: { limit: 1 },
								})
							)?.data?.[0],
					});
				}}
			>
				<Ellipsis>
					{(Boolean(Math.abs(timeDiff) < 1000 * 60) && <Tag color={'orange'}>Nyt</Tag>) || (
						<Tag
							color={
								(timeDiff < 0 && 'red') ||
								(timeDiff < 1000 * 60 * 60 && 'orange') ||
								(timeDiff < 1000 * 60 * 60 * 24 && 'green') ||
								'blue'
							}
						>
							{_.filter([ToDuration(timeDiff), timeDiff < 0 && 'myöhässä']).join(' ')}
						</Tag>
					)}

					<Avatar>
						<Picture
							src={schedule?.resource?.picture}
							table='resource'
							size={64}
						/>
					</Avatar>
					{'\u2000'}
					{schedule?.resource?.name}
				</Ellipsis>

				<DesktopOnly>
					<div>
						<Tag>{schedule?.customer?.name}</Tag>
						<Tag color='blue'>{schedule?.office?.name}</Tag>
						<Tag color='orange'>{LocaleDate(time)}</Tag>
					</div>
				</DesktopOnly>
			</Item>
		);
	};

	const startingSchedules = _.chain(schedules?.data)
		.filter(v =>
		{
			if (v?.status !== 'new') return false;
			if (+new Date(v?.start) < now - 1000 * 60 * 60) return false;
			return true;
		})
		.sortBy(v => +new Date(v?.start))
		.slice(0, 5)
		.map((schedule, key) => (
			<InfoItem
				key={schedule?.id || key}
				schedule={schedule}
				time={new Date(schedule?.start)}
			/>
		))
		.value();

	const endingSchedules = _.chain(schedules?.data)
		.filter(v =>
		{
			if (v?.status !== 'progress') return false;
			if (+new Date(v?.start) > now) return false;
			if (+new Date(v?.ending) < now - 1000 * 60 * 60) return false;
			return true;
		})
		.sortBy(v => +new Date(v?.start))
		.slice(0, 5)
		.map((schedule, key) => (
			<InfoItem
				key={schedule?.id || key}
				schedule={schedule}
				time={new Date(schedule?.ending)}
			/>
		))
		.value();

	return (
		<Tabs
			style={{ overflow: 'visible', marginTop: '-12px' }}
			items={[
				{
					key: 'starting',
					style: { paddingTop: '10px' },
					label: (
						<div style={{ display: 'inline-flex', alignItems: 'center' }}>
							<Ikoni
								name='calendar-clock'
								twoTone
								fontSize={19}
								bgOpacity={0.2}
							/>
							{'\u2000'}
							Alkavat
							{'\u2000'}
							<Badge count={startingSchedules?.length} />
						</div>
					),
					children: <>{startingSchedules?.length ? startingSchedules : <EmptyContent />}</>,
				},

				{
					key: 'ending',
					style: { paddingTop: '10px' },
					label: (
						<div style={{ display: 'inline-flex', alignItems: 'center' }}>
							<Ikoni
								name='calendar-check'
								twoTone
								fontSize={19}
								bgOpacity={0.2}
							/>
							{'\u2000'}
							Päättyvät
							{'\u2000'}
							<Badge count={endingSchedules?.length} />
						</div>
					),
					children: <>{endingSchedules?.length ? endingSchedules : <EmptyContent />}</>,
				},
			]}
		></Tabs>
	);
}
