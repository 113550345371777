import _ from 'lodash';

import { Col, Row, Switch } from 'App/MainComponents';
import { Lang } from 'utils/Language';
import { Post } from 'utils/Api';
import { Ikoni } from 'icons';
import { useMount, useStated } from 'hooks/Hooks';
import { usePost } from 'hooks/usePost';
import { Assoc } from 'utils/Assoc';

type Props = {
	value?: Assoc<any>[];
	onChange?: (value: Assoc<any>[]) => void;
};

export const PrivilegeTable = (props: Props) =>
{
	const { value, onChange } = props;
	// const { privileges } = state;
	const table = 'privilege';
	const levels = ['create', 'read', 'update', 'delete'];

	const [privileges, status] = usePost({
		header: { limit: 1000 },
		path: table,
		body: {},
	});

	return (
		<>
			<Row style={{ marginBottom: '5px', paddingBottom: '2px', marginTop: '9px', borderBottom: '1px solid #f8f8f8' }}>
				<Col style={{ width: '20%' }}>Taulu</Col>
				{_.map(levels, level => (
					<Col
						key={level}
						style={{ width: '20%' }}
					>
						<Lang>{level}</Lang>
					</Col>
				))}
			</Row>
			{_.map(
				_.groupBy(privileges?.data, o => o.table),
				(_v, k) => (
					<Row
						key={k}
						style={{ marginBottom: '3px' }}
					>
						<Col style={{ width: '20%' }}>
							<Lang>{k}</Lang>
						</Col>
						{_.map(levels, level => (
							<Col
								key={level}
								style={{ width: '20%' }}
							>
								<Switch
									checkedChildren={<Ikoni name='check' />}
									unCheckedChildren={<Ikoni name='close' />}
									checked={!!_.find(value, o => o?.table === k && o?.level === level)}
									onChange={e =>
									{
										const val = _.cloneDeep(value || []);
										if (!e) _.remove(val, { table: k, level });
										else
										{
											const f = _.find(val, o => o?.table === k && o?.level === level);
											if (!f) val.push(_.find(privileges?.data, o => o?.table === k && o?.level === level) as Assoc<any>);
										}
										onChange?.(val);
									}}
								/>
							</Col>
						))}
					</Row>
				),
			)}
		</>
	);
}
