import { styled, css } from 'styled';
import { colors } from './Colors';

export const Tag = styled.span<{ color?: keyof typeof colors; }>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	font-size: 12px;
	line-height: 20px;
	opacity: 1;
	padding: 0 7px;
	transition: all 0.3s;
	margin: 0 8px 6px 0;

	min-width: 40px;
	height: auto;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	& .anticon,
	& i {
		margin-left: 4px;
	}

	${ p =>
		p?.color &&
		colors?.[p.color] &&
		css`
			background: ${ colors?.[p.color]?.extralight };
			border-color: ${ colors?.[p.color]?.light };
			color: ${ colors?.[p.color]?.active };
		`}
`;
